/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Snooze from 'embercom/models/operator/visual-builder/step/snooze';

interface Arguments {
  step: Snooze;
}

export default class Settings extends Component<Arguments> {
  @service intl!: IntlService;

  @tracked durationType = 'custom';
  @tracked until = 'later_today';
  @tracked customDuration: number | undefined;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
    this._populateOptions();
  }

  _populateOptions() {
    if (this.args.step.durationType === 'custom') {
      this.durationType = 'custom';
    } else {
      this.durationType = 'until';
      this.until = this.args.step.durationType;
    }
    this.customDuration = this.args.step.customDuration;
  }
  get untilLabel() {
    if (this.durationType === 'custom') {
      return this.intl.t('operator.workflows.visual-builder.snooze.until-options.later-today.text');
    } else {
      return this.intl.t(
        `operator.workflows.visual-builder.snooze.until-options.${this.until.replace(
          '_',
          '-',
        )}.text`,
      );
    }
  }

  get untilGroupList() {
    return [
      {
        items: [
          {
            text: this.intl.t(
              'operator.workflows.visual-builder.snooze.until-options.later-today.text',
            ),
            value: 'later_today',
            description: this.intl.t(
              'operator.workflows.visual-builder.snooze.until-options.later-today.description',
            ),
            component: 'workflows/graph-editor/node-items/steps/select-items/duration-item',
          },
          {
            text: this.intl.t(
              'operator.workflows.visual-builder.snooze.until-options.tomorrow.text',
            ),
            value: 'tomorrow',
            description: this.intl.t(
              'operator.workflows.visual-builder.snooze.until-options.tomorrow.description',
            ),
            component: 'workflows/graph-editor/node-items/steps/select-items/duration-item',
          },
          {
            text: this.intl.t('operator.workflows.visual-builder.snooze.until-options.monday.text'),
            value: 'monday',
            description: this.intl.t(
              'operator.workflows.visual-builder.snooze.until-options.monday.description',
            ),
            component: 'workflows/graph-editor/node-items/steps/select-items/duration-item',
          },
          {
            text: this.intl.t(
              'operator.workflows.visual-builder.snooze.until-options.next-month.text',
            ),
            value: 'next_month',
            description: this.intl.t(
              'operator.workflows.visual-builder.snooze.until-options.next-month.description',
            ),
            component: 'workflows/graph-editor/node-items/steps/select-items/duration-item',
          },
        ],
      },
    ];
  }

  @action
  selectUntil() {
    this.durationType = 'until';
    this.args.step.durationType = this.until;
    this.args.step.customDuration = undefined;
  }

  @action
  updateCustomDuration(durationInSeconds: number) {
    this.customDuration = durationInSeconds;
    this.setCustomDuration();
  }

  @action
  setCustomDuration() {
    this.durationType = 'custom';
    this.args.step.durationType = 'custom';
    this.args.step.customDuration = this.customDuration;
  }

  @action
  updateUntil(selectedValue: string) {
    this.durationType = 'until';
    this.until = selectedValue;
    this.args.step.durationType = this.until;
    this.args.step.customDuration = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Snooze::Settings': typeof Settings;
    'workflows/graph-editor/node-items/steps/snooze/settings': typeof Settings;
  }
}
