/* import __COLOCATED_TEMPLATE__ from './selected-log-container.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SelectedLogContainer extends Component {
  @tracked openSectionId;
  @service intl;

  @action
  onOpenSectionChange(sectionId) {
    this.openSectionId = sectionId;
  }

  get requestBannerErrorText() {
    return this.intl.t('components.workflow-connector.builder.selected-log.banner-text-help');
  }

  get requestBannerText() {
    return this.requestSuccessful
      ? this.intl.t(
          'components.workflow-connector.builder.selected-log.request-banner-text-success',
        )
      : this.args.selectedAction.errorType;
  }

  get requestBannerType() {
    return this.requestSuccessful ? 'green' : 'red';
  }

  get responseBannerSuccessText() {
    return this.intl.t(
      'components.workflow-connector.builder.selected-log.response-banner-text-success',
      {
        httpStatus: this.args.selectedAction.httpStatus,
      },
    );
  }
  get responseBannerText() {
    if (this.requestSuccessful) {
      return this.responseSuccessful
        ? this.responseBannerSuccessText
        : this.args.selectedAction.errorType;
    }
    return this.requestBannerErrorText;
  }

  get responseFullErrorMessage() {
    return this.intl.t(
      'components.workflow-connector.builder.selected-log.response-banner-text-full-error',
      {
        errorMessage: this.errorMessage,
      },
    );
  }

  get responseBannerType() {
    if (this.requestSuccessful) {
      return this.responseSuccessful ? 'green' : 'red';
    }
    return 'gray';
  }

  get responseMappingBannerText() {
    if (this.requestSuccessful && this.responseSuccessful) {
      return this.responseMappingSuccessful
        ? this.intl.t(
            'components.workflow-connector.builder.selected-log.mapping-banner-text-success',
          )
        : this.args.selectedAction.errorType;
    }
    return this.intl.t('components.workflow-connector.builder.selected-log.banner-text-help');
  }

  get responseMappingBannerType() {
    if (this.requestSuccessful && this.responseSuccessful) {
      return this.responseMappingSuccessful ? 'green' : 'red';
    }
    return 'gray';
  }

  get mappingHelpMessage() {
    return this.intl.t('components.workflow-connector.builder.selected-log.mapping-help-message');
  }

  get finResponseBannerType() {
    if (!this.requestSuccessful) {
      return 'gray';
    }
    if (!this.responseSuccessful) {
      return 'red';
    }
    return this.bodyIsEmpty(this.args.selectedAction.responseBody) ? 'gray' : 'green';
  }

  get finResponseBannerText() {
    if (!this.requestSuccessful) {
      return this.requestBannerErrorText;
    }
    if (!this.responseSuccessful) {
      return this.args.selectedAction.errorType;
    }
    return this.bodyIsEmpty(this.args.selectedAction.responseBody)
      ? this.intl.t(
          'components.workflow-connector.builder.selected-log.fin-usage.response-banner-text-no-data',
        )
      : this.responseBannerSuccessText;
  }

  get finResponseNoDataText() {
    return this.intl.t(
      'components.workflow-connector.builder.selected-log.fin-usage.banner-text-help',
    );
  }

  get finResponseNoDataHelpMessage() {
    return this.intl.t(
      'components.workflow-connector.builder.selected-log.fin-usage.banner-help-message',
    );
  }

  get requestBody() {
    if (this.args.selectedAction.requestBody) {
      let json = JSON.parse(this.args.selectedAction.requestBody);
      return JSON.stringify(json, null, 2);
    }
    return null;
  }

  get responseBody() {
    if (this.args.selectedAction.responseBody) {
      let json = JSON.parse(this.args.selectedAction.responseBody);
      return JSON.stringify(json, null, 2);
    }
    return null;
  }

  get finResponseBody() {
    let body = this.args.selectedAction.responseBody;
    if (!this.bodyIsEmpty(body) && this.responseSuccessful) {
      let json = JSON.parse(body);
      return JSON.stringify(json, null, 2);
    }
    return null;
  }

  get rawResponseBody() {
    if (this.args.selectedAction.rawResponseBody) {
      let json = JSON.parse(this.args.selectedAction.rawResponseBody);
      return JSON.stringify(json, null, 2);
    }
    if (!this.bodyIsEmpty(this.args.selectedAction.responseBody) && !this.responseSuccessful) {
      let json = JSON.parse(this.args.selectedAction.responseBody);
      return JSON.stringify(json, null, 2);
    }
    return null;
  }

  get requestSuccessful() {
    return this.args.selectedAction.httpStatus > 0;
  }

  get responseSuccessful() {
    return this.args.selectedAction.httpStatus >= 200 && this.args.selectedAction.httpStatus < 300;
  }

  get responseMappingSuccessful() {
    return this.args.selectedAction.errorMessage === null;
  }

  get errorMessage() {
    return this.args.selectedAction.errorMessage;
  }

  get httpMethodString() {
    return this.args.selectedAction.httpMethod
      ? this.args.selectedAction.httpMethod.toUpperCase()
      : '';
  }

  get isCustomBot() {
    if (
      this.args.selectedAction.sourceType.includes(
        this.intl.t('components.workflow-connector.builder.selected-log.bot'),
      )
    ) {
      return true;
    }
    return false;
  }

  get isFinActionUsage() {
    return this.args.selectedActionUsage === 'fin';
  }

  bodyIsEmpty(body) {
    if (body === null || body === undefined) {
      return true;
    }

    let jsonBody = JSON.parse(body);

    return Array.isArray(jsonBody) ? jsonBody.length === 0 : Object.keys(jsonBody).length === 0;
  }
}
