/* import __COLOCATED_TEMPLATE__ from './reply-buttons.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { type StepArguments } from '.';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type ReplyButtons from 'embercom/models/operator/visual-builder/step/reply-buttons';
import type ReplyButtonsConfiguration from 'embercom/objects/visual-builder/configuration/step/reply-buttons';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import { type SyncHasMany } from '@ember-data/model';
import { type StepGroupType } from 'embercom/objects/visual-builder/configuration/inserter-menu';
import { schedule } from '@ember/runloop';

const DEFAULT_COMPONENT_TEMPLATE = 'workflows/graph-editor/popover-menu/popover-menu-item' as const;
const CATEGORY_CONFIG = {
  action: {
    iconBGClass: 'workflows__graph-editor__node-items__steps__action-icon-bg hover:text-white',
    iconColour: 'white',
  },
  message: {
    iconBGClass: 'workflows__graph-editor__node-items__steps__message-icon-bg',
    iconColour: 'black',
  },
  condition: {
    iconBGClass: 'workflows__graph-editor__node-items__steps__conditional-branches__if-branch-icon',
    iconColour: 'black',
  },
};

export interface ReplyButtonsArgs extends StepArguments {
  step: ReplyButtons;
  stepConfig: ReplyButtonsConfiguration;
  alternativeStepText?: string;
  alternativeStepIcon?: string;
  buttonLimit?: number;
  suffixMap?: Map<ConnectionPoint, string>;
  onChange?: (step: ReplyButtons) => void;
  onReorder?: (buttons: SyncHasMany<ConnectionPoint>) => void;
}

export default class ReplyButtonsNodeItem extends Component<ReplyButtonsArgs> {
  @service declare appService: any;
  @service declare attributeService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  @tracked buttons = this.args.step.outwardConnectionPoints;

  get replyButtons() {
    return this.buttons.filter((button) => button.type !== 'capture_composer_input');
  }

  get captureComposerInput() {
    return this.buttons.find((button) => button.type === 'capture_composer_input');
  }

  get isSafari() {
    return UserAgentDetector.isSafari();
  }

  get isSortingDisabled() {
    return this.args.readOnly || Number(this.buttons.length) <= 1;
  }

  get attributeName() {
    return (
      this.attributeService.allAttributes.find(
        // @ts-ignore, attribute service is not typed yet
        (attr) => attr.identifier === this.args.step.attributeIdentifier,
      )?.name || this.intl.t('operator.workflows.visual-builder.select-attribute')
    );
  }

  get stepInsertionOptions(): StepGroupType[] {
    let items = [];

    if (this.hasNotReachedButtonLimit) {
      items.push({
        text:
          this.args.alternativeStepText ||
          this.intl.t('operator.workflows.visual-builder.reply-button-menu.reply-button'),
        icon: this.args.alternativeStepIcon || 'button-pill',
        value: 'reply-button',
        onSelectItem: this.addReplyButton,
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.condition,
      });
    }

    if (this.canUseStartOverButton && !this.hasStartOverButton) {
      items.push({
        text: this.intl.t('operator.workflows.visual-builder.reply-button-menu.start-over'),
        icon: 'left-align',
        value: 'reply-button',
        onSelectItem: this.addStartOverButton,
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.action,
        tooltip: this.intl.t(
          'operator.workflows.visual-builder.reply-button-menu.start-over-tooltip',
        ),
      });
    }

    if (!this.args.step.shouldCollectAttribute) {
      items.push({
        text: this.intl.t(
          'operator.workflows.visual-builder.reply-button-menu.attribute-collection',
        ),
        icon: 'transfer',
        value: 'collect-attribute',
        onSelectItem: this.startCollectingAttribute,
        component: DEFAULT_COMPONENT_TEMPLATE,
        groupConfig: CATEGORY_CONFIG.action,
      });
    }

    return [{ items }];
  }

  get canUseStartOverButton() {
    return this.args.stepConfig.isStartOverButtonSupported;
  }

  get stepInsertionOptionsContainsSingleItem() {
    return this.stepInsertionOptions[0]?.items?.length === 1;
  }

  get hasStartOverButton() {
    return this.buttons.any((button) => button.isStartOver);
  }

  get stepInsertionOptionsContainsItems() {
    return this.stepInsertionOptions[0]?.items?.length;
  }

  get hasNotReachedButtonLimit() {
    return !this.args.buttonLimit || Number(this.buttons.length) < this.args.buttonLimit;
  }

  get shouldShowError() {
    return (
      this.args.editorState.shouldShowValidations &&
      (this.args.step.validations.attrs.supportedChannels.isInvalid ||
        this.args.step.validations.attrs.supportedContexts.isInvalid)
    );
  }

  @action onMouseEnter(index: number) {
    let replyButton = this.replyButtons[index];

    if (!replyButton.isConnected) {
      return;
    }

    let edge = this.args.editorState.layout.graph.edgeForDataObject(replyButton.edge!);

    if (!edge) {
      return;
    }
    this.args.editorState.layout.graph.state.setMouseAtEdge(edge);
  }

  @action onMouseLeave() {
    this.args.editorState.layout.graph.state.removeMouseFromEdge();
  }

  @action
  startCollectingAttribute() {
    this.args.step.shouldCollectAttribute = true;
  }

  @action
  disableComposer() {
    this.args.step.isComposerDisabled = true;
  }

  @action
  onSelectAttribute(attribute: any) {
    // attribute service is not typed yet
    this.args.step.attributeIdentifier = attribute.identifier;
  }

  @action
  addReplyButton() {
    let button = this.store.createRecord('operator/visual-builder/connection-point');
    this.buttons.addObject(button);

    this.args.editorState.logInteraction(`Added reply button`);
    this.args.editorState.focusObject(button);
    this.intercomEventService.trackAnalyticsEvent({ object: button, action: 'added' });
  }

  @action
  addStartOverButton() {
    this.buttons.addObject(
      this.store.createRecord('operator/visual-builder/connection-point', {
        label: this.intl.t('operator.workflows.visual-builder.reply-buttons.start-over-label'),
        isStartOver: true,
      }),
    );
    this.args.editorState.logInteraction(`Added start over button to reply buttons step`);
  }

  @action
  reorderControls(buttons: SyncHasMany<ConnectionPoint>, button: ConnectionPoint) {
    if (this.captureComposerInput) {
      buttons.addObject(this.captureComposerInput);
    }
    this.args.step.outwardConnectionPoints = buttons;
    this.intercomEventService.trackAnalyticsEvent({ object: button, action: 'reordered' });
    this.args.editorState.markAsEdited();

    this.args.editorState.logInteraction(`Reordered reply buttons`);

    if (this.args.onReorder) {
      this.args.onReorder(buttons);
    }
  }

  @action
  toggleComposerInput() {
    if (
      this.args.stepConfig.isDisableComposerSupported &&
      !this.args.step.isComposerDisabled &&
      this.captureComposerInput
    ) {
      this.args.step.isComposerDisabled = true;
      this.args.stepConfig.deleteButton(this.captureComposerInput);
    } else {
      this.args.step.isComposerDisabled = false;
      this.createCaptureComposerInput();

      this.args.editorState.logInteraction(`Toggled capture composer input on reply buttons step`);
    }
  }

  @action
  updateButton(button: any, value: any) {
    button.label = value;

    if (this.args.onChange) {
      this.args.onChange(this.args.step);
    }
  }

  createCaptureComposerInput() {
    // Safeguard making sure there's always only one capture composer input control
    if (this.captureComposerInput?.isConnected) {
      return;
    }

    let connectionPoint =
      this.captureComposerInput ||
      this.store.createRecord('operator/visual-builder/connection-point', {
        type: 'capture_composer_input',
      });

    this.buttons.addObject(connectionPoint);

    schedule('afterRender', () => {
      this.args.editorState.createEmptyGroupFromConnectionPoint(
        connectionPoint,
        'capture-composer',
      );
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ReplyButtons': typeof ReplyButtonsNodeItem;
    'workflows/graph-editor/node-items/steps/reply-buttons': typeof ReplyButtonsNodeItem;
  }
}
