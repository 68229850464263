/* import __COLOCATED_TEMPLATE__ from './filterable-list-component.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { copy } from 'ember-copy';
import { inject as service } from '@ember/service';
import storage from 'embercom/vendor/intercom/storage';

const FILTERS_STORAGE_KEY = 'rules-filters-storage';

export default class IndexComponent extends Component {
  @service appService;
  @service store;
  @tracked eventFilter = 'any';
  @tracked statusFilter = 'any';
  @tracked filterPredicateGroup;

  PREDICATE_TYPE_TO_ACTION_DATA_KEY = {
    rules_filters_manual_tag: 'tag_id',
    rules_filters_assign_to: 'assignee_id',
    rules_filters_mark_priority: 'priority',
    rules_filters_apply_conversation_sla: 'conversation_sla_id',
  };

  constructor(...args) {
    super(...args);
    this.filterPredicateGroup = this.store.createFragment('predicates/predicate-group');
    this.restoreFilters();
  }

  get app() {
    return this.appService.app;
  }

  get hasWorkflows() {
    return this.args.workflows.length > 0;
  }

  get hasActiveFilters() {
    return (
      this.filterPredicateGroup.predicates.length > 0 ||
      this.eventFilter !== 'any' ||
      this.statusFilter !== 'any'
    );
  }

  get hasFilteredWorkflows() {
    return this.filteredWorkflows.length > 0;
  }

  get noWorkflowsMatchFilters() {
    return this.hasWorkflows && this.filteredWorkflows.length === 0;
  }

  get filteredWorkflows() {
    let filtered = this.args.workflows;
    if (this.eventFilter !== 'any') {
      filtered = filtered.filter((wf) => wf.triggerTypes.includes(this.eventFilter));
    }
    if (this.statusFilter !== 'any') {
      filtered = filtered.filter((wf) => wf.status === this.statusFilter);
    }
    if (this.filterPredicateGroup) {
      filtered = filtered.filter((wf) =>
        this.filterPredicateGroup.predicates.every((predicate) =>
          this.workflowPredicateMatch(wf, predicate),
        ),
      );
    }
    return filtered;
  }

  workflowPredicateMatch(workflow, predicate) {
    return workflow.actions
      .map((a) => a)
      .some((action) => this.predicateActionMatch(action, predicate));
  }

  predicateActionMatch(action, predicate) {
    let actionDataKey = this.PREDICATE_TYPE_TO_ACTION_DATA_KEY[predicate.type];
    let dataPresent = actionDataKey in action.actionData;
    let data = action.actionData[actionDataKey];
    switch (predicate.comparison) {
      case 'eq':
        return dataPresent && predicate.value === data;
      case 'ne':
        return dataPresent && predicate.value !== data;
      case 'known':
      case 'true':
        return dataPresent;
      case 'unknown':
        return !dataPresent;
      default:
        return true;
    }
  }

  @action
  updateEventFilter(newFilter) {
    this.eventFilter = newFilter;
    this.storeFilters();
  }

  @action
  updateStatusFilter(newFilter) {
    this.statusFilter = newFilter;
    this.storeFilters();
  }

  @action
  clearFilters() {
    this.eventFilter = 'any';
    this.statusFilter = 'any';
    this.filterPredicateGroup = this.store.createFragment('predicates/predicate-group');
    storage.remove(FILTERS_STORAGE_KEY);
  }

  @action
  onPredicatesUpdate(predicates) {
    this.filterPredicateGroup.predicates = copy(predicates);
    this.storeFilters();
  }

  storeFilters() {
    storage.set(FILTERS_STORAGE_KEY, {
      eventFilter: this.eventFilter,
      statusFilter: this.statusFilter,
      predicates: this.filterPredicateGroup.toJSON().predicates,
    });
  }

  restoreFilters() {
    try {
      this.clearDeletedCachedData();

      if (storage.get(FILTERS_STORAGE_KEY)) {
        let { eventFilter, statusFilter, predicates } = storage.get(FILTERS_STORAGE_KEY);
        this.eventFilter = eventFilter || 'any';
        this.statusFilter = statusFilter || 'any';
        this.filterPredicateGroup.predicates = predicates || [];
      }
    } catch (error) {
      // If we can't restore the filters, remove what is probably broken data
      storage.remove(FILTERS_STORAGE_KEY);
    }
  }

  //Removes cache if there are filters associated with deleted models
  clearDeletedCachedData() {
    let { predicates } = storage.get(FILTERS_STORAGE_KEY);
    let hasDeletedPredicates = predicates.some(
      (predicate) => predicate.value && this.isPredicateModelDeleted(predicate),
    );
    if (hasDeletedPredicates) {
      storage.remove(FILTERS_STORAGE_KEY);
    }
  }

  isPredicateModelDeleted(predicate) {
    let model = null;
    if (predicate.type === 'rules_filters_assign_to') {
      model = this.store.peekRecord('admin', predicate.value);
    } else if (predicate.type === 'rules_filters_manual_tag') {
      model = this.store.peekRecord('tag', predicate.value);
    } else if (predicate.type === 'rules_filters_apply_conversation_sla') {
      let slas = this.store.peekRecord('inbox/conversation-sla').toArray();
      model = slas.findBy('id', predicate.value);
    }
    return !model;
  }
}
