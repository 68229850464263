/* import __COLOCATED_TEMPLATE__ from './status-filter.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class StatusFilter extends Component {
  @service intl;

  @tracked selectedValue = 'any';
  isOpen = false;

  constructor() {
    super(...arguments);
    this.selectedValue = this.args.selectedValue || 'any';
  }

  get availableItems() {
    return [
      {
        text: this.intl.t('workflow-connector.builder.logs.filter.status.any'),
        value: 'any',
      },
      {
        text: this.intl.t('workflow-connector.builder.logs.filter.status.success'),
        value: 'true',
      },
      {
        text: this.intl.t('workflow-connector.builder.logs.filter.status.failure'),
        value: 'false',
      },
    ];
  }

  get items() {
    return this.availableItems.map((item) => ({
      text: item.text,
      value: item.value,
      isSelected: this.selectedValue ? this.selectedValue === item.value : false,
      component: 'workflow-connector/builder/logs/filters/filter-option',
    }));
  }

  get details() {
    let details = '';
    this.availableItems.forEach((item) => {
      if (item.value === this.selectedValue) {
        details = item.text;
      }
    });
    return `is ${details}`;
  }

  @action resetSelectedValue(_element, [selectedValue]) {
    this.onSelectItem(selectedValue || 'any', true);
  }

  @action onSelectItem(selectedValue, forced = false) {
    if (this.selectedValue !== selectedValue || forced) {
      this.selectedValue = selectedValue;
    } else {
      this.selectedValue = 'any';
    }
  }

  @action onOpen() {
    if (this.isOpen) {
      return;
    }
    this.isOpen = true;
  }

  @action onClose() {
    this.isOpen = false;
    this.args.filterContent('success', this.selectedValue || 'any');
  }
}
