/* import __COLOCATED_TEMPLATE__ from './bulk-tagging.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { Promise as EmberPromise } from 'rsvp';
import { all } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

const CHECK_DEPENDENCIES_THRESHOLD = 25;

const TRANSLATION_KEYS = {
  title: {
    user: 'components.users-and-companies.bulk-tagging.tag-title-user',
    lead: 'components.users-and-companies.bulk-tagging.tag-title-lead',
    company: 'components.users-and-companies.bulk-tagging.tag-title-company',
  },
  saveLabel: {
    user: 'components.users-and-companies.bulk-tagging.tag-label-user',
    lead: 'components.users-and-companies.bulk-tagging.tag-label-lead',
    company: 'components.users-and-companies.bulk-tagging.tag-label-company',
  },
  confirmationTitle: {
    user: 'components.users-and-companies.bulk-tagging.confimation-title-user',
    lead: 'components.users-and-companies.bulk-tagging.confimation-title-lead',
    company: 'components.users-and-companies.bulk-tagging.confimation-title-company',
  },
  confirmationBody: {
    user: 'components.users-and-companies.bulk-tagging.confimation-body-user',
    lead: 'components.users-and-companies.bulk-tagging.confimation-body-lead',
    company: 'components.users-and-companies.bulk-tagging.confimation-body-company',
  },
  confirmButton: {
    user: 'components.users-and-companies.bulk-tagging.button-user',
    lead: 'components.users-and-companies.bulk-tagging.button-lead',
    company: 'components.users-and-companies.bulk-tagging.button-company',
  },
};

export default class BulkTagging extends Component {
  @service tagService;
  @service intercomConfirmService;
  @service intl;

  @tracked showDependenciesModal = false;
  @tracked _dependenciesValidated;
  @tracked _dependenciesRejected;
  @tracked tagsDependencies = null;

  // The common/tagging-component requires a taggable object (e.g. user, company, conversation)
  // on which it will call updateTaggings(), ideally it would take an action or function instead.
  get taggable() {
    let updateTaggings = this.args.updateTaggings;
    return {
      tags: [],
      taggings: [],
      type: this.args.type,
      updateTaggings(admin, addedTags = [], removedTags = [], initialTags = []) {
        return updateTaggings(admin, addedTags, removedTags, initialTags);
      },
    };
  }

  get title() {
    if (TRANSLATION_KEYS['title'][this.args.tagTypeLabel]) {
      return this.intl.t(TRANSLATION_KEYS['title'][this.args.tagTypeLabel], {
        count: this.args.count,
      });
    } else {
      return this.intl.t('components.users-and-companies.bulk-tagging.tag-title-default', {
        count: this.args.count,
      });
    }
  }

  get saveLabel() {
    if (TRANSLATION_KEYS['saveLabel'][this.args.tagTypeLabel]) {
      return this.intl.t(TRANSLATION_KEYS['saveLabel'][this.args.tagTypeLabel], {
        count: this.args.count,
      });
    } else {
      return this.intl.t('components.users-and-companies.bulk-tagging.tag-label-default');
    }
  }

  get confirmationTitle() {
    if (TRANSLATION_KEYS['confirmationTitle'][this.args.tagTypeLabel]) {
      return this.intl.t(TRANSLATION_KEYS['confirmationTitle'][this.args.tagTypeLabel], {
        count: this.args.count,
      });
    } else {
      return this.intl.t('components.users-and-companies.bulk-tagging.confimation-title-default');
    }
  }

  get confirmationBody() {
    if (TRANSLATION_KEYS['confirmationBody'][this.args.tagTypeLabel]) {
      return this.intl.t(TRANSLATION_KEYS['confirmationBody'][this.args.tagTypeLabel]);
    } else {
      return this.intl.t('components.users-and-companies.bulk-tagging.confimation-body-default');
    }
  }

  get confirmButton() {
    if (TRANSLATION_KEYS['confirmButton'][this.args.tagTypeLabel]) {
      return this.intl.t(TRANSLATION_KEYS['confirmButton'][this.args.tagTypeLabel], {
        count: this.args.count,
      });
    } else {
      return this.intl.t('components.users-and-companies.bulk-tagging.button-default');
    }
  }

  _closeDependenciesModal() {
    this.showDependenciesModal = false;
  }

  @action
  onValidated() {
    this._dependenciesValidated();
  }

  @action
  onRejected() {
    this._dependenciesRejected();
  }

  @task({ restartable: true })
  *validateTagDependencies(addedTags) {
    let nonNewTags = addedTags.filter((tag) => tag.id !== null);
    if (this.args.count < CHECK_DEPENDENCIES_THRESHOLD || nonNewTags.length === 0) {
      return true;
    }

    this.tagsDependencies = yield this.fetchAllDependencies.perform(nonNewTags);

    if (this.tagsDependencies.some((tagDependencies) => tagDependencies.hasDependencies)) {
      this.showDependenciesModal = true;

      yield new EmberPromise((resolve, reject) => {
        this._dependenciesValidated = () => {
          this._closeDependenciesModal();
          resolve();
        };
        this._dependenciesRejected = () => {
          this._closeDependenciesModal();
          reject();
        };
      });
    } else {
      let confirm = yield this.intercomConfirmService.confirm({
        title: this.confirmationTitle,
        body: this.confirmationBody,
        confirmButtonText: this.confirmButton,
      });

      if (!confirm) {
        throw new Error(this.intl.t('components.users-and-companies.bulk-tagging.error'));
      }
    }
  }

  @task({ restartable: true })
  *fetchAllDependencies(tags) {
    let dependencyTasks = tags.map((tag) => this.tagService.fetchTagDependencies.perform(tag));

    yield all(dependencyTasks);

    return tags.map((tag, index) => {
      let dependencies = dependencyTasks[index].value;
      return {
        tag,
        dependencies,
        hasDependencies: this.tagService.hasDependencies(dependencies),
      };
    });
  }
}
