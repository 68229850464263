/* import __COLOCATED_TEMPLATE__ from './message-template-editor.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { V1View } from '@intercom/interblocks.ts';
import type AttributePicker from 'embercom/components/common/attribute-picker';
import type FallbackEditor from 'embercom/components/whatsapp/fallback-editor';

interface Args {
  template: any;
  onChange: (template: any) => void;
  components?: any;
  attributeResolver: AttributeInfoResolver;
}

export default class WhatsappMessageTemplateEditor extends Component<Args> {
  readonly PARAMETERS_CUSTOM_CLASS = 'intercom-interblocks-template';
  readonly PARAMETER_NUMBER_ATTRIBUTE = 'data-template-identifier';
  readonly ATTRIBUTE_IDENTIFIER_KEY = 'attribute_identifier';
  readonly FALLBACK_KEY = 'fallback';
  readonly FALLBACK_CUSTOM_CLASS = 'requires-fallback';
  readonly SECTIONS = ['header', 'body', 'footer', 'buttons'];

  @tracked parametersMap: {
    [key: string]: any;
  } = {
    header: {},
    body: {},
    footer: {},
    buttons: {},
  };

  popover: any;
  @tracked selectedParamElement: any;
  @tracked selectedAttributeIdentifier = '';
  @tracked showAttributePicker = false;
  @tracked wrapperElement: Element | undefined;

  @action
  onInserted(elem: Element) {
    this.wrapperElement = elem;
    this.loadExistingParameters();
  }

  loadExistingParameters() {
    if (this.args.components && this.validInitialParameters) {
      this.componentsToParametersMap(this.args.components);
      this.replaceParametersInContent();
    }
  }

  componentsToParametersMap(components: any) {
    let updatedParametersMap: any = {
      header: {},
      body: {},
      footer: {},
      buttons: {},
    };

    for (let component of components) {
      let componentType = component.type.toLowerCase();
      component.parameters?.forEach((parameterDetail: any, i: number) => {
        let [attribute_identifier, fallback] = parameterDetail.text
          .replace(/{{|}}/g, '')
          .split('|')
          .map((item: string) => item.trim());

        if (this.isValidAttribute(attribute_identifier)) {
          let paramObj: any = {
            [this.ATTRIBUTE_IDENTIFIER_KEY]: attribute_identifier,
          };
          if (fallback) {
            paramObj[this.FALLBACK_KEY] = (fallback.match(/fallback:\s?"([^"]+)"/) || [])[1];
          }

          updatedParametersMap[componentType][`${i + 1}`] = paramObj;
        }
      });
    }

    this.parametersMap = updatedParametersMap;
  }

  replaceParametersInContent() {
    for (let section of this.SECTIONS) {
      let element = this.wrapperElement?.getElementsByClassName(section)?.[0];
      let parameters = this.parametersMap[section];

      if (element && parameters?.length) {
        this.updateSectionParameters(parameters, element);
      }
    }
  }

  updateSectionParameters(parameters: any, element: Element) {
    for (let [paramNumber, paramEntry] of Object.entries(parameters)) {
      if (paramEntry) {
        let attributeIdentifier = (paramEntry as any)[this.ATTRIBUTE_IDENTIFIER_KEY];
        if (attributeIdentifier) {
          let fallback = (paramEntry as any)[this.FALLBACK_KEY];
          this.updateAllParameterOccurrences(
            this.attributeName(attributeIdentifier as string),
            !fallback && this.requiresFallBack(attributeIdentifier as string),
            element,
            paramNumber,
          );
        }
      }
    }
  }

  get validInitialParameters() {
    let { template, components } = this.args;

    if (!template?.components?.length || !components?.length) {
      return false;
    }

    return this.args.template.components.every((templateComponent: any, i: number) => {
      let text = templateComponent.text || '';
      let parametersCountFromText = this._extractParametersFromText(text).length;
      let component = this.args.components[i];

      return component && parametersCountFromText === (component.parameters?.length || 0);
    });
  }

  _extractParametersFromText(text: string) {
    let regex = /\{\{(\d{1,2})\}\}/g;
    let matches = [...text.matchAll(regex)];

    let parameters = matches.map((match) => match[1]);
    return [...new Set(parameters)];
  }

  get templateContent() {
    // eslint-disable-next-line ember/no-side-effects
    let templateHtmlElement = document.createElement('div');
    if (!this.args.template) {
      return templateHtmlElement;
    }
    let view = new V1View(templateHtmlElement, true, this.args.attributeResolver);
    try {
      view.render(this.args.template.previewBlocks);
      this.renderParameters(templateHtmlElement);
    } catch (e) {
      console.error(e);
    }

    return templateHtmlElement;
  }

  get components() {
    return Object.entries(this.parametersMap).map(([type, parameters]) => {
      let sortedParameterKeys = Object.keys(parameters).sort();
      return {
        type,
        parameters: sortedParameterKeys.map((key: string) => {
          let text = parameters[key]?.[this.ATTRIBUTE_IDENTIFIER_KEY] || '';
          if (parameters[key]?.[this.FALLBACK_KEY]) {
            text += ` | fallback:"${parameters[key][this.FALLBACK_KEY]}"`;
          }
          text = `{{${text}}}`;
          return { type: 'text', text };
        }),
      };
    });
  }

  renderParameters(parentHtmlElement: any) {
    this.SECTIONS.forEach((section) => {
      let sectionElement = parentHtmlElement.getElementsByClassName(section)?.[0];

      if (sectionElement) {
        let paramsElements = sectionElement.getElementsByClassName(this.PARAMETERS_CUSTOM_CLASS);
        for (let paramElement of paramsElements) {
          let paramNumber = paramElement.getAttribute(this.PARAMETER_NUMBER_ATTRIBUTE);
          let paramEntry = this.parametersMap[section][paramNumber];
          if (paramEntry) {
            let attributeIdentifier = paramEntry[this.ATTRIBUTE_IDENTIFIER_KEY];
            if (attributeIdentifier) {
              paramElement.textContent = this.attributeName(attributeIdentifier);

              let fallback = paramEntry[this.FALLBACK_KEY];
              if (this.requiresFallBack(attributeIdentifier) && !fallback) {
                this.addRequiresFallbackStyle(paramElement);
              }
            }
          } else {
            this.addRequiresFallbackStyle(paramElement);
          }
        }
      }
    });
  }

  @action
  handleClick(popover: any, event: any) {
    event.stopPropagation();
    event.preventDefault();

    this.popover = popover;
    if (event.target.hasAttribute(this.PARAMETER_NUMBER_ATTRIBUTE)) {
      this.selectedParamElement = event.target;

      if (
        this.selectedParamSectionName &&
        this.parametersMap[this.selectedParamSectionName][this.selectedParamNumber]
      ) {
        let attributeIdentifier =
          this.parametersMap[this.selectedParamSectionName][this.selectedParamNumber][
            this.ATTRIBUTE_IDENTIFIER_KEY
          ];
        if (attributeIdentifier) {
          this.showAttributePicker = false;
          this.selectedAttributeIdentifier = attributeIdentifier;
        }
      } else {
        this.showAttributePicker = true;
      }
      popover.show();
    } else {
      popover.hide();
    }
  }

  @action
  closePopover(event?: any) {
    event?.preventDefault();
    event?.stopPropagation();
    this.popover?.hide();
  }

  @action
  insertAttribute(attributeIdentifier: string): void {
    this.selectedAttributeIdentifier = attributeIdentifier;
    this.updateAllParameterOccurrences(
      this.attributeName(attributeIdentifier),
      this.requiresFallBack(attributeIdentifier),
      this.selectedParamSectionElement,
      this.selectedParamNumber,
    );

    this.closePopover();
    this.updateAttributeIdentifier(attributeIdentifier);
  }

  updateAllParameterOccurrences(
    newText: string | number | null,
    requiresFallback: boolean,
    sectionElement: Element,
    paramNumber: string,
  ) {
    let paramsHtmlElements = sectionElement.querySelectorAll(
      `.${this.PARAMETERS_CUSTOM_CLASS}[${this.PARAMETER_NUMBER_ATTRIBUTE}="${paramNumber}"]`,
    );

    for (let element of paramsHtmlElements) {
      if (newText) {
        element.textContent = `${newText}`;
      }
      requiresFallback
        ? this.addRequiresFallbackStyle(element)
        : this.removeRequiresFallbackStyle(element);
    }
  }

  get selectedParamNumber() {
    return this.selectedParamElement.getAttribute(this.PARAMETER_NUMBER_ATTRIBUTE);
  }

  get selectedParamSectionElement() {
    return this.selectedParamElement?.parentElement?.parentElement;
  }

  get selectedParamSectionName() {
    for (let i = 0; i < this.SECTIONS.length; i++) {
      let section = this.SECTIONS[i];
      if (this.selectedParamSectionElement.classList.contains(section)) {
        return section;
      }
    }
    return null;
  }

  @action
  updateAttributeIdentifier(identifier: string) {
    if (this.selectedParamSectionName) {
      let attribute = { attribute_identifier: identifier };
      this.parametersMap[this.selectedParamSectionName][this.selectedParamNumber] = attribute;
      if (this.args.onChange) {
        this.args.onChange(this.components);
      }
    }
  }

  @action
  updateAttributeFallback(fallback: string) {
    if (this.selectedParamSectionName && fallback) {
      this.parametersMap[this.selectedParamSectionName][this.selectedParamNumber][
        this.FALLBACK_KEY
      ] = fallback;
      this.updateAllParameterOccurrences(
        null,
        false,
        this.selectedParamSectionElement,
        this.selectedParamNumber,
      );
      this.templateContent;
      if (this.args.onChange) {
        this.args.onChange(this.components);
      }
    }
  }

  @action
  resetAttribute() {
    if (this.selectedParamSectionName) {
      this.parametersMap[this.selectedParamSectionName][this.selectedParamNumber] = null;
      this.updateAllParameterOccurrences(
        this.selectedParamNumber,
        true,
        this.selectedParamSectionElement,
        this.selectedParamNumber,
      );
      if (this.args.onChange) {
        this.args.onChange(this.components);
      }
    }
    this.closePopover();
  }

  get selectedAttributeFallBack() {
    if (this.selectedParamSectionName) {
      return this.parametersMap[this.selectedParamSectionName][this.selectedParamNumber][
        this.FALLBACK_KEY
      ];
    }
  }

  addRequiresFallbackStyle(element: any) {
    element.classList.add(this.FALLBACK_CUSTOM_CLASS);
  }

  removeRequiresFallbackStyle(element: any) {
    element.classList.remove(this.FALLBACK_CUSTOM_CLASS);
  }

  isValidAttribute(attributeIdentifier: string) {
    return this.args.attributeResolver?.isValid(attributeIdentifier) || false;
  }

  attributeName(attributeIdentifier: string) {
    return this.args.attributeResolver?.humanReadable(attributeIdentifier);
  }

  requiresFallBack(attributeIdentifier: string) {
    return this.args.attributeResolver?.isFallbackAllowed(attributeIdentifier) || false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::AttributePicker': typeof AttributePicker;
    'Whatsapp::MessageTemplateEditor': typeof WhatsappMessageTemplateEditor;
    'Whatsapp::FallbackEditor': typeof FallbackEditor;
  }
}
