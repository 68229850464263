/* import __COLOCATED_TEMPLATE__ from './default.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';
import { type StepNames, type StepType } from 'embercom/objects/visual-builder/configuration-list';

interface DefaultSignature {
  Element: HTMLDivElement;
  Args: { onSelectStep: (stepName: StepNames) => void; stepTypes: StepType; readOnly: boolean };
}

const Default = templateOnlyComponent<DefaultSignature>();

export default Default;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::PlaceholderNodeContent::Default': typeof Default;
    'workflows/graph-editor/placeholder-node-content/default': typeof Default;
  }
}
