/* import __COLOCATED_TEMPLATE__ from './pre-handover.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import templateOnlyComponent from '@ember/component/template-only';
import type Fin from 'embercom/models/operator/visual-builder/step/fin';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    accordion: $TSFixMe;
    step: Fin;
    isReadOnly: boolean;
  };
}

const PreHandover = templateOnlyComponent<Signature>();
export default PreHandover;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Fin::Sections::PreHandover': typeof PreHandover;
  }
}
