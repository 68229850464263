/* import __COLOCATED_TEMPLATE__ from './request.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { htmlUnescape } from 'embercom/lib/html-unescape';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import {
  HTTP_METHODS,
  FIN_ACTIONS_SUPPORTED_HTTP_METHODS,
} from 'embercom/lib/custom-authentication/request-helper';
import { type TaskGenerator } from 'ember-concurrency';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import type Action from 'embercom/models/workflow-connector/action';
import type Token from 'embercom/models/custom-authentication/token';
import type ParamDescriptor from 'embercom/models/workflow-connector/param-descriptor';
import type IntercomAttribute from 'embercom/models/attribute';
//@ts-ignore
import { sanitizeUrl } from '@intercom/pulse/lib/sanitize';

interface Args {
  workflowAction: Action;
  nextSection: (sectionId: string) => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class Request extends Component<Signature> {
  @service declare store: Store;
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare attributeService: any;
  @service declare intl: any;

  @tracked selectedPreviewTab = 'headers';
  @tracked isBodyAvailable = ['post', 'put'].includes(this.args.workflowAction.httpMethod);
  @tracked customAuthenticationTokens: Token[] = [];
  @tracked selectedTokenId = this.args.workflowAction.tokenId || undefined;
  @tracked urlBlocks;
  @tracked bodyBlocks;
  @tracked showWriteActionConfirmation = ['post', 'put', 'delete'].includes(
    this.args.workflowAction.httpMethod,
  );

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    taskFor(this.loadCustomAuthenticationTokens).perform();
    this.urlBlocks = this.workflowActionURLToBlocks();
    this.bodyBlocks = this.workflowActionBodyToBlocks();
    if (this.args.workflowAction.httpMethod === null) {
      this.args.workflowAction.httpMethod = 'get';
    }
  }

  get app() {
    return this.appService.app;
  }

  get tokenList() {
    return [{ items: this.groupListItems }];
  }

  get groupListItems() {
    return this.customAuthenticationTokens.map((token) => {
      return {
        value: token.id,
        text: token.name,
      };
    });
  }

  workflowActionURLToBlocks() {
    return [
      {
        type: 'paragraph',
        text: this.args.workflowAction.url || '',
        modelKey: 'common/blocks/paragraph',
      },
    ];
  }

  get localAttributes() {
    let attributes = this.args.workflowAction.paramDescriptors.map((paramDescriptor) => {
      return {
        identifier: paramDescriptor.name,
        name: paramDescriptor.name,
        templatable_identifier: paramDescriptor.name,
        noFallback: true,
      };
    });

    return [
      {
        heading: this.intl.t('workflow-connector.builder.body.request.fin-data.label'),
        attributes,
      },
    ];
  }

  get isInvalid() {
    return Boolean(this.args.workflowAction.installUrlErrors);
  }

  workflowActionBodyToBlocks() {
    if (!this.args.workflowAction.body) {
      return [];
    }

    let bodyLines = this.args.workflowAction.body.split('\n');
    let bodyBlocks = bodyLines.map((line) => {
      return {
        type: 'paragraph',
        text: line,
        class: 'no-margin',
        modelKey: 'common/blocks/paragraph',
      };
    });
    return bodyBlocks;
  }

  get supportedHttpMethods() {
    if (
      this.args.workflowAction.usage === 'fin' &&
      !this.appService.app?.canUseFeature('answerbot-fin-actions-write-actions')
    ) {
      return HTTP_METHODS.map((method) =>
        FIN_ACTIONS_SUPPORTED_HTTP_METHODS.includes(method.value)
          ? method
          : {
              ...method,
              text: `${method.text} ${this.intl.t('workflow-connector.builder.body.request.method.not-supported')}`,
              isDisabled: true,
            },
      );
    }
    return HTTP_METHODS;
  }

  get selectedToken() {
    return this.customAuthenticationTokens?.find((token) => token.id === this.selectedTokenId);
  }

  get isAppPackageAction() {
    return this.args.workflowAction.appPackageCode !== null;
  }

  get unprotectedAttributes() {
    let attributes = this.args.workflowAction.usedAttributeArray;
    return this.attributeService.attributes.filter(
      (attr: IntercomAttribute) =>
        attributes.includes(attr.identifier) && !attr.preventUpdateFromMessenger,
    );
  }

  get isFinWriteAction() {
    return this.args.workflowAction.finWriteAction;
  }

  @action
  toggleFinWriteAction() {
    this.args.workflowAction.toggleFinWriteAction();
  }

  @action
  previousSection() {
    this.args.nextSection('general_section');
  }

  @action
  nextSection() {
    this.args.nextSection('testing_section');
  }

  @action
  setActionHttpMethod(httpMethod: string) {
    this.args.workflowAction.httpMethod = httpMethod;
    this.isBodyAvailable = ['post', 'put'].includes(httpMethod);
    if (!this.isBodyAvailable) {
      this.selectedPreviewTab = 'headers';
    }
    this.showWriteActionConfirmation = ['post', 'put', 'delete'].includes(
      this.args.workflowAction.httpMethod,
    );
  }

  @action
  changePreviewTab(selectedTab: string) {
    this.selectedPreviewTab = selectedTab;
  }

  @action
  onUrlComposerChange(newBlocksDoc: { blocks: { text: string }[] }) {
    let blocks = newBlocksDoc.blocks;
    let processedBlocks = sanitizeUrl(htmlUnescape(blocks[0].text));
    this.args.workflowAction.url = processedBlocks.toString();
  }

  @action
  onBodyComposerChange(newBlocksDoc: { blocks: { text: string }[] }) {
    let blocks = newBlocksDoc.blocks;
    let blockTexts = blocks.map((block) => htmlUnescape(block.text));
    let processedBlocks = blockTexts.join('\n');
    this.args.workflowAction.body = processedBlocks.toString();
  }

  @action
  updateWorkflowToken(tokenId: string) {
    this.args.workflowAction.tokenId = tokenId;
    this.selectedTokenId = tokenId;
  }

  @action
  clearSelectedToken() {
    this.args.workflowAction.tokenId = null;
    this.selectedTokenId = undefined;
  }

  @action
  updateParamDescriptor(
    index: string,
    name: string,
    description: string,
    type: string,
    required: boolean,
    defaultValue: string,
  ) {
    if (index) {
      let paramDescriptor = this.args.workflowAction.paramDescriptors.find((x) => x.name === index);
      if (paramDescriptor) {
        paramDescriptor.setProperties({
          name,
          description,
          type,
          required,
          defaultValue,
        });
      }
    } else {
      this.args.workflowAction.paramDescriptors.pushObject({
        name,
        description,
        type,
        required,
        defaultValue,
      } as ParamDescriptor);
    }
  }

  @action
  deleteParamDescriptor(index: string) {
    let paramDescriptor = this.args.workflowAction.paramDescriptors.find((x) => x.name === index);
    if (paramDescriptor) {
      this.args.workflowAction.paramDescriptors.removeObject(paramDescriptor);
    }
  }

  @task({ drop: true })
  *loadCustomAuthenticationTokens(): TaskGenerator<void> {
    this.customAuthenticationTokens = yield this.store.findAll('custom-authentication/token');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::Request': typeof Request;
    'workflow-connector/builder/body/sections/request': typeof Request;
  }
}
