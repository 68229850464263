/* import __COLOCATED_TEMPLATE__ from './vertical-navigation-search.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { empty, not, notEmpty, readOnly } from '@ember/object/computed';
import $ from 'jquery';
import EmberObject, { computed } from '@ember/object';
import { A } from '@ember/array';
import { schedule } from '@ember/runloop';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { ternary } from '@intercom/pulse/lib/computed-properties';
// eslint-disable-next-line @intercom/intercom/no-legacy-dropdown
import ComponentDropdownToggler from 'embercom/components/mixins/component-dropdown-toggler';
import KeyboardSelectableDropdown from 'embercom/components/mixins/keyboard-selectable-dropdown';

const SHORTCUT_GROUP = 'nav-search';

export default Component.extend(KeyboardSelectableDropdown, ComponentDropdownToggler, {
  appService: service(),
  app: readOnly('appService.app'),
  ariaRole: 'search',
  shortcuts: service(),
  lastSetSearchString: '',
  previousInternalSearchString: '',
  internalSearchString: '',
  classNames: ['nav-vertical__search', 'flex-none'],
  classNameBindings: ['updatedSearch:o__updated'],
  showSearchIcon: false,
  showLoadingIconInsteadOfSearchIcon: false,
  avoidCreateAdditionalSearchItem: false,

  init() {
    this._super(...arguments);
    this.refreshInternalSearchString();
    this.setupDefaultItems();
  },

  didInsertElement() {
    this._super(...arguments);
    this.shortcuts.register(SHORTCUT_GROUP, this, [
      { key: '/', modalActive: false, method: this.shortcutPressed },
    ]);
    if (isEmpty(this.searchString) && !this.preventFocusOnElementInsertion) {
      schedule('afterRender', () => this.focusOnInput());
    }
  },

  willDestroyElement() {
    this.shortcuts.unregister(SHORTCUT_GROUP);
    this._super(...arguments);
  },

  didUpdateAttrs() {
    this._super(...arguments);
    if (!this.isDestroying) {
      this.refreshInternalSearchString();
    }
  },

  shortcutPressed() {
    this.focusOnInput();
  },

  refreshInternalSearchString() {
    let searchString = this.searchString;
    let lastSetSearchString = this.lastSetSearchString;
    let internalSearchString = this.internalSearchString;
    if (searchString === undefined) {
      searchString = '';
    }
    if (searchString !== lastSetSearchString) {
      this.set('lastSetSearchString', searchString);
      if (searchString !== internalSearchString) {
        this.set('internalSearchString', searchString);
        this.set('previousInternalSearchString', searchString);
      }
    }
  },

  setupDefaultItems() {
    if (this.items === undefined) {
      this.set('items', A([]));
    }
  },

  focusOnInput() {
    if (this.isDestroying) {
      return;
    }
    let input = $('.nav-vertical__search__input', this.element);
    if (input === undefined || input.is(':focus')) {
      return;
    }
    schedule('afterRender', this, function () {
      input.focus();
    });
  },

  createSearchItem({
    isStandardSearch = true,
    isUserEmailSearch = false,
    isUserNameSearch = false,
  } = {}) {
    return EmberObject.create({
      name: this.internalSearchString,
      isHighlighted: this.selectedItemIsStandardSearch,
      isStandardSearch,
      isUserEmailSearch,
      isUserNameSearch,
    });
  },

  blurInput() {
    if (this.isDestroying) {
      return;
    }
    let input = $('.nav-vertical__search__input', this.element);
    if (input === undefined || !input.is(':focus')) {
      return;
    }
    schedule('afterRender', this, function () {
      input.blur();
    });
  },

  disabled: false,
  placeHolderText: 'Search…',
  preventDropdownToggleFromWithin: true,
  shouldFilterSelectableItems: true,
  searchString: '',
  showSuggestions: true,
  testBindingClass: '',
  textInputFocused: false,
  internalSearchStringEmpty: empty('internalSearchString'),
  internalSearchStringNotEmpty: not('internalSearchStringEmpty'),
  searchIconClass: ternary('showSearchIcon', 'u__padl__30', ''),

  textInputClasses: computed('testBindingClass', 'searchIconClass', function () {
    return `nav-vertical__search__input ${this.testBindingClass} ${this.searchIconClass}`;
  }),

  searchInputIconColor: ternary('shouldShowFullBacking', 'gray', 'white'),

  selectedItemIsStandardSearch: computed('currentHighlightedItem', function () {
    let currentHighlightedItem = this.currentHighlightedItem;
    return currentHighlightedItem === undefined || currentHighlightedItem.isStandardSearch;
  }),

  selectableItems: computed(
    'internalSearchString',
    'avoidCreateAdditionalSearchItem',
    'items.[]',
    'showSuggestions',
    'isOpen',
    function () {
      if (!this.showSuggestions || !this.isOpen) {
        return A([]);
      }
      let searchString = this.internalSearchString;
      let shouldFilterSelectableItems = this.shouldFilterSelectableItems;
      let selectableItems = this.items;
      if (shouldFilterSelectableItems) {
        selectableItems = selectableItems
          .filter(function (item) {
            return item.name.toLowerCase().includes(searchString.toLowerCase());
          })
          .slice(0, 10);
      }

      if (selectableItems.length > 0 && selectableItems[0].isStandardSearch) {
        selectableItems.shift();
      }

      if (this.internalSearchStringNotEmpty && !this.avoidCreateAdditionalSearchItem) {
        selectableItems.unshift(this.createSearchItem());
      }
      return selectableItems;
    },
  ),

  selectableItemsNotEmpty: notEmpty('selectableItems'),

  // We have to provide our own keyDown here
  keyDown(e) {
    let keyCode = e.which !== undefined ? e.which : e.keyCode;
    switch (keyCode) {
      case 40:
        this.send('onDown');
        e.preventDefault();
        break;
      case 38:
        this.send('onUp');
        e.preventDefault();
        break;
      case 13:
        this.send('onEnter');
        e.stopPropagation();
        break;
      case 27:
        this.send('onEsc');
        break;
      default:
        this._super(e);
    }
  },

  clearSearchString() {
    this.set('internalSearchString', '');
  },

  actions: {
    selectItem(item) {
      this.doSearch(item);
      this.send('close');
      this.blurInput();
    },
    textInputFocused() {
      this.set('textInputFocused', true);
      this.send('open');
    },
    textInputBlurred() {
      this.set('textInputFocused', false);
    },
    textInputKeyUp() {
      let previousInternalSearchString = this.previousInternalSearchString;
      let internalSearchString = this.internalSearchString;
      let onSearchStringChanged = this.onSearchStringChanged;

      if (previousInternalSearchString !== internalSearchString) {
        this.set('previousInternalSearchString', internalSearchString);
        if (onSearchStringChanged) {
          onSearchStringChanged(internalSearchString);
        }
      }
    },
    clearText() {
      if (!this.internalSearchStringEmpty) {
        this.clearSearchString();
        let resetSearch = this.resetSearch;
        if (typeof resetSearch === 'function') {
          resetSearch();
        }
        this.focusOnInput();
      }
    },
    close() {
      this._closeDropdown();
    },
    onEsc() {
      this.clearSearchString();
      this.send('close');
    },
    onEnter() {
      if (this.isOpen && !this.avoidCreateAdditionalSearchItem) {
        let item = this.highlightedItem;
        if (item === undefined) {
          item = this.createSearchItem();
        }
        this.resetHighlightedItem();
        this.send('selectItem', item);
      }
    },
  },
});
