/* import __COLOCATED_TEMPLATE__ from './end-answer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';
import type AnswerTerminal from 'embercom/models/operator/visual-builder/step/answer-terminal';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

interface Args {
  step: AnswerTerminal;
  editorState: EditorState;
  readOnly: boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const EndAnswer = templateOnlyComponent<Signature>();
export default EndAnswer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::AnswerTerminal::EndAnswer': typeof EndAnswer;
    'workflows/graph-editor/node-items/steps/answer-terminal/end-answer': typeof EndAnswer;
  }
}
