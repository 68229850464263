/* import __COLOCATED_TEMPLATE__ from './sortable-item-placeholder.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
}

const SortableItemPlaceholderComponent = templateOnlyComponent<Signature>();
export default SortableItemPlaceholderComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::SortableItemPlaceholder': typeof SortableItemPlaceholderComponent;
    'workflows/graph-editor/sortable-item-placeholder': typeof SortableItemPlaceholderComponent;
  }
}
