/* import __COLOCATED_TEMPLATE__ from './location-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { reads, notEmpty, alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';

export default Component.extend({
  classNames: ['profile__location__container'],
  appService: service(),
  countryName: reads('geoipData.country_name'),
  cityName: reads('geoipData.city_name'),
  hasCompany: notEmpty('model.companies'),
  company: alias('model.companies.firstObject'),

  countryPredicate: computed('countryName', function () {
    return {
      attribute: 'geoip_data.country_name',
      comparison: 'eq',
      value: this.countryName,
      type: 'string',
    };
  }),

  cityPredicate: computed('cityName', function () {
    return {
      attribute: 'geoip_data.city_name',
      comparison: 'eq',
      value: this.cityName,
      type: 'string',
    };
  }),

  countryFilterUrl: computed('model', 'countryPredicate', function () {
    return B64FilterParamEncoder.filterUrlFromPredicates(this.model, {
      predicates: [this.countryPredicate],
    });
  }),

  cityFilterUrl: computed('model', 'cityPredicate', function () {
    return B64FilterParamEncoder.filterUrlFromPredicates(this.model, {
      predicates: [this.cityPredicate],
    });
  }),
});
