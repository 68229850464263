/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import type Hangup from 'embercom/models/operator/visual-builder/step/hang-up';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

interface Arguments {
  step: Hangup;
  isReadOnly: boolean;
  editorState: EditorState;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class HangupEditor extends Component<Arguments> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Hangup::Editor': typeof HangupEditor;
    'workflows/graph-editor/node-items/steps/hangup/editor': typeof HangupEditor;
  }
}
