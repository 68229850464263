/* import __COLOCATED_TEMPLATE__ from './delay.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { pluralize } from 'ember-inflector';
import { inject as service } from '@ember/service';
import Delays, {
  DEFAULT_DELAY,
  MAX_DELAY_IN_SECONDS,
  MIN_DELAY_IN_SECONDS,
} from 'embercom/lib/workflows/helpers/delays';

export default class Delay extends Component {
  @service intl;
  @tracked workflow = this.args.workflow;
  @tracked delayValue;
  @tracked delayUnit;

  constructor() {
    super(...arguments);
    if (!this.workflow.data?.delay_in_seconds || this.workflow.hasChanges) {
      this.showOverlay = true;
    }
    this.workflow.set(
      'data.delay_in_seconds',
      this.workflow.data.delay_in_seconds || DEFAULT_DELAY,
    );
    this._setValueAndUnit();
  }

  get delays() {
    return new Delays();
  }

  get overlayComponent() {
    return 'workflows/editor/triggers/delay-overlay';
  }

  get displayMessage() {
    let duration = pluralize(this.delayValue, this.delayUnit);

    return `${this.args.durationLabel} ${duration}`;
  }

  get delayInSeconds() {
    return this.workflow.data.delay_in_seconds;
  }

  get invalidDelayMessage() {
    if (this.delayInSeconds > MAX_DELAY_IN_SECONDS) {
      return this.intl.t('settings.inbox-rules.triggers.delay.invalid-delay-max');
    }
    if (this.delayInSeconds < MIN_DELAY_IN_SECONDS) {
      return this.intl.t('settings.inbox-rules.triggers.delay.invalid-delay-min');
    }
    return '';
  }

  _saveDelayInSeconds(delayInSeconds) {
    this.workflow.data = {
      ...this.workflow.data,
      delay_in_seconds: delayInSeconds,
    };
    this._setValueAndUnit();
  }

  _setValueAndUnit() {
    this.delayValue = this.delays.getDelayValue(this.delayInSeconds);
    this.delayUnit = this.delays.getDelayUnit(this.delayInSeconds);
  }

  @action
  saveTriggerConfig(selectionValue) {
    this._saveDelayInSeconds(selectionValue);
  }

  @action
  saveDelay(value, unit) {
    let delayInSeconds = this.delays.getDelayInSeconds(value, unit);
    this._saveDelayInSeconds(delayInSeconds);
  }
}
