/* import __COLOCATED_TEMPLATE__ from './object-mapping-new.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import dataFormatMap from 'embercom/models/data/attributes/data-format-map';
import {
  CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAMES,
  REQUIRED_CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAMES,
} from 'embercom/models/custom-objects/constants/standard-attribute-names';
import {
  USER_OBJECT_TYPE_IDENTIFIER,
  USER_OBJECT_TYPE_NAME,
  CONVERSATION_OBJECT_TYPE_IDENTIFIER,
  CONVERSATION_OBJECT_TYPE_NAME,
} from 'embercom/models/custom-objects/constants/object-types';
import { REFERENCES_ONE } from 'embercom/models/objects/constants/reference-types';
import { isPresent } from '@ember/utils';

export default class ObjectMapping extends Component {
  @service store;
  @service appService;
  @service attributeService;
  @service intl;
  @service intercomEventService;
  @service customObjectsService;
  @tracked objectMapping = this.args.objectMapping;

  app = this.appService.app;

  @action addAttributeMapping() {
    let attributeMapping = this.store.createFragment(
      'workflow-connector/action-attribute-mapping',
      {
        objectMapping: this.objectMapping,
      },
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'new_attribute_mapping',
    });
    this.objectMapping.attributeMappings.pushObject(attributeMapping);
  }

  @action addReferenceMapping() {
    let referenceMapping = this.store.createFragment(
      'workflow-connector/action-reference-mapping',
      {
        objectMapping: this.objectMapping,
      },
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'new_reference_mapping',
    });
    this.objectMapping.referenceMappings.pushObject(referenceMapping);
  }

  @action deleteAttributeMapping(attributeMapping) {
    this.objectMapping.attributeMappings.removeObject(attributeMapping);
  }

  @action deleteReferenceMapping(referenceMapping) {
    this.objectMapping.referenceMappings.removeObject(referenceMapping);
  }

  @action onApiObjectTypeSelected(selectedApiObjectType) {
    this.objectMapping.responseObjectPath = selectedApiObjectType;
    this._addRequiredAttributeMappings();
  }

  _addRequiredAttributeMappings() {
    if (
      this.customObjectsService.customObjectTypes
        .mapBy('identifier')
        .includes(this.objectMapping.intercomObjectType)
    ) {
      REQUIRED_CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAMES.forEach((value) => {
        let attributeMapping = this.store.createFragment(
          'workflow-connector/action-attribute-mapping',
          {
            objectMapping: this.objectMapping,
            intercomAttributeIdentifier: value,
          },
        );
        this.objectMapping.attributeMappings.pushObject(attributeMapping);
      });
    }
  }

  get intercomAttributeMappingOptions() {
    let mappedIntercomAttributeIdentifiers = this.objectMapping.attributeMappings.map(
      (attributeMapping) => attributeMapping.intercomAttributeIdentifier,
    );
    return this._objectAttributeGroups().map((attributeGroup) =>
      this._attributeGroupDisplayDetails(attributeGroup, mappedIntercomAttributeIdentifiers),
    );
  }

  get intercomReferenceMappingOptions() {
    let mappedReferenceAttributeIdentifiers = this.objectMapping.referenceMappings.map(
      (referenceMapping) =>
        `${referenceMapping.intercomObjectType}-${referenceMapping.intercomAttributeIdentifier}`,
    );

    return this._referenceAttributes().map((attribute) =>
      this._referenceAttributeDisplayDetails(attribute, mappedReferenceAttributeIdentifiers),
    );
  }

  // Attribute Mapping helpers start here
  _objectAttributeGroups() {
    let objectAttributeGroups = [];

    if (this.objectMapping.intercomObjectType === USER_OBJECT_TYPE_IDENTIFIER) {
      objectAttributeGroups = this._userAttributeGroups();
    } else if (this.objectMapping.intercomObjectType === CONVERSATION_OBJECT_TYPE_IDENTIFIER) {
      objectAttributeGroups = this._conversationAttributeGroups();
    } else {
      objectAttributeGroups = this._customObjectAttributeGroups();
    }

    return objectAttributeGroups;
  }

  _userAttributeGroups() {
    let items = this.attributeService.nonArchivedAttributes
      .filterBy('isUser', true)
      .filterBy('isCustomData', true)
      .filter((attr) => attr.type !== 'relationship');

    return [
      {
        heading: `${USER_OBJECT_TYPE_NAME} attributes`,
        items,
      },
    ];
  }

  _conversationAttributeGroups() {
    let items = this.attributeService.nonArchivedConversationCustomAttributes.filter(
      (attr) => attr.type !== 'relationship',
    );

    return [
      {
        heading: `${CONVERSATION_OBJECT_TYPE_NAME} attributes`,
        items,
      },
    ];
  }

  _customObjectAttributeGroups() {
    let customObjectType = this.customObjectsService.objectTypes.findBy(
      'identifier',
      this.objectMapping.intercomObjectType,
    );
    let activeAttributes = customObjectType.attributeDescriptorsInclStandard.rejectBy('archived');
    let activeRelationshipAttributes = this._parseCustomObjectAttributes(
      activeAttributes.filterBy('isRelationshipDataType'),
      customObjectType.identifier,
    );
    let activeNonRelationshipAttributes = this._parseCustomObjectAttributes(
      activeAttributes.rejectBy('isRelationshipDataType'),
      customObjectType.identifier,
    );

    return [
      {
        heading: `${customObjectType.name} attributes`,
        items: activeNonRelationshipAttributes,
      },
      {
        heading: `References from ${customObjectType.name}`,
        items: activeRelationshipAttributes,
      },
    ];
  }

  _parseCustomObjectAttributes(attributes, customObjectIdentifier) {
    return attributes.map((attribute) => {
      return {
        identifier: attribute.id,
        name: attribute.name,
        type: attribute.dataType,
        objectTypeIdentifier: customObjectIdentifier,
        archived: attribute.archived,
        referencedObjectTypeNotSupported: this._referencedObjectTypeIsNotSupported(attribute),
        referenceTypeNotSupported: this._referenceTypeIsNotSupported(attribute, false),
        referencedObjectType: attribute.referencedObjectType,
        reference: attribute.reference,
      };
    });
  }

  _tooltipText(attribute, mappedIntercomAttributeIdentifiers) {
    let tooltipText = null;

    let isAlreadyUsed =
      mappedIntercomAttributeIdentifiers.includes(attribute.identifier) ||
      this._alreadyUsedStandardAttribute(mappedIntercomAttributeIdentifiers, attribute.name);

    if (isAlreadyUsed) {
      tooltipText = this.intl.t(
        'workflow-connector.builder.body.response.mapping.duplicate-attribute-mapping',
      );
    } else if (attribute.referencedObjectTypeNotSupported) {
      tooltipText = this.intl.t(
        'workflow-connector.builder.body.response.mapping.unsupported-referenced-object-type',
      );
    } else if (attribute.referenceTypeNotSupported) {
      tooltipText = this.intl.t(
        'workflow-connector.builder.body.response.mapping.invalid-reference-type',
      );
    }

    return tooltipText;
  }

  _alreadyUsedStandardAttribute(mappedIntercomAttributeIdentifiers, attributeName) {
    return (
      CUSTOM_OBJECT_STANDARD_ATTRIBUTE_NAMES.includes(attributeName) &&
      mappedIntercomAttributeIdentifiers.includes(attributeName)
    );
  }

  _attributeGroupDisplayDetails(attributeGroup, mappedIntercomAttributeIdentifiers) {
    let { heading, items } = attributeGroup;
    return {
      heading,
      items: items.map((attribute) =>
        this._attributeDisplayDetails(attribute, mappedIntercomAttributeIdentifiers),
      ),
    };
  }

  _attributeDisplayDetails(attribute, mappedIntercomAttributeIdentifiers) {
    let attributeHumanReadableDataType;
    if (
      this.objectMapping.intercomObjectType === USER_OBJECT_TYPE_IDENTIFIER ||
      this.objectMapping.intercomObjectType === CONVERSATION_OBJECT_TYPE_IDENTIFIER
    ) {
      if (attribute.hasOptions) {
        attributeHumanReadableDataType = this.intl.t('settings.data-format-map.list');
      } else {
        attributeHumanReadableDataType = this.intl.t(
          dataFormatMap[attribute.type] || 'settings.data-format-map.unknown',
        );
      }
    } else if (attribute.type === 'relationship' && isPresent(attribute.reference)) {
      if (attribute.referencedObjectType.identifier === this.objectMapping.intercomObjectType) {
        attributeHumanReadableDataType = `from ${attribute.objectTypeName}`;
      } else {
        attributeHumanReadableDataType = `to ${attribute.referencedObjectType?.name}`;
      }
    } else {
      attributeHumanReadableDataType = attribute.type || 'Unknown';
    }

    let tooltipText = this._tooltipText(attribute, mappedIntercomAttributeIdentifiers);

    return {
      text: attribute.name,
      value: attribute.identifier || attribute.name,
      count: attributeHumanReadableDataType,
      tooltipText,
      isDisabled: tooltipText !== null,
    };
  }

  // Reference Mapping helpers start here
  _referenceAttributes() {
    let peopleReferences = this.attributeService.userRelationshipAttributes
      .rejectBy('archived')
      .filterBy('referencedObjectTypeIdentifier', this.objectMapping.intercomObjectType);
    let conversationReferences = this.attributeService.conversationReferenceAttributes
      .rejectBy('archived')
      .filterBy('referencedObjectTypeIdentifier', this.objectMapping.intercomObjectType);

    return this._parseReferenceAttributesForMapping([
      ...peopleReferences,
      ...conversationReferences,
    ]);
  }

  _parseReferenceAttributesForMapping(attributes) {
    return attributes.map((attribute) => {
      return {
        identifier: attribute.identifier,
        name: attribute.name,
        type: attribute.type,
        objectTypeIdentifier: attribute.objectTypeIdentifier,
        objectTypeName: attribute.objectTypeName,
        archived: attribute.archived,
        referenceTypeNotSupported: this._referenceTypeIsNotSupported(attribute, true),
        referencedObjectType: attribute.referencedObjectType,
        reference: attribute.reference,
      };
    });
  }

  _referenceAttributeDisplayDetails(attribute, mappedReferenceAttributeIdentifiers) {
    let attributeHumanReadableDataType;
    if (attribute.type === 'relationship' && isPresent(attribute.reference)) {
      attributeHumanReadableDataType = `from ${attribute.objectTypeName}`;
    } else {
      attributeHumanReadableDataType = attribute.type || 'Unknown';
    }

    let tooltipText = this._tooltipTextForReferenceMapping(
      attribute,
      mappedReferenceAttributeIdentifiers,
    );

    return {
      text: attribute.name,
      value: attribute,
      count: attributeHumanReadableDataType,
      tooltipText,
      isDisabled: tooltipText !== null,
      component: 'workflow-connector/builder/body/sections/response/reference-attribute-option',
      componentAttrs: {
        icon: this._attributeGroupIcon(attribute),
        group: attribute.objectTypeName,
      },
    };
  }

  _attributeGroupIcon(attribute) {
    switch (attribute.objectTypeIdentifier) {
      case USER_OBJECT_TYPE_IDENTIFIER:
        return 'multiple-people';
      case CONVERSATION_OBJECT_TYPE_IDENTIFIER:
        return 'conversation';
      default:
        return 'intercom-data';
    }
  }

  _tooltipTextForReferenceMapping(attribute, mappedIntercomAttributeIdentifiers) {
    let tooltipText = null;

    let isAlreadyUsed = mappedIntercomAttributeIdentifiers.includes(
      `${attribute.objectTypeIdentifier}-${attribute.identifier}`,
    );

    if (isAlreadyUsed) {
      tooltipText = this.intl.t(
        'workflow-connector.builder.body.response.mapping.duplicate-attribute-mapping',
      );
    } else if (attribute.referenceTypeNotSupported) {
      tooltipText = this.intl.t(
        'workflow-connector.builder.body.response.mapping.invalid-reference-type',
      );
    }

    return tooltipText;
  }
  // Reference Mapping helpers end here

  _referencedObjectTypeIsNotSupported(attribute) {
    return (
      attribute.isRelationshipDataType &&
      !(
        attribute.hasReferenceToObjectType(USER_OBJECT_TYPE_IDENTIFIER) ||
        attribute.hasReferenceToObjectType(CONVERSATION_OBJECT_TYPE_IDENTIFIER)
      )
    );
  }

  _referenceTypeIsNotSupported(attribute, is_reference_mapping) {
    return (
      attribute.isRelationshipDataType && // no need for further checks if this is not a relationship attribute
      this.objectMapping.apiMappableObject.isArray &&
      attribute.reference.referenceType === REFERENCES_ONE &&
      is_reference_mapping
    );
  }

  get selectedResponseObjectPath() {
    // prevent dropdown component from throwing exceptions
    if (this.selectedApiObjectMissing) {
      return undefined;
    }

    return this.objectMapping.responseObjectPath;
  }

  get selectedApiObjectMissing() {
    // differentiate between new object mapping and existing that doesn't have a matching object
    if (this.objectMapping.responseObjectPath === undefined) {
      return false;
    }

    return !this.objectMapping.apiMappableObjectPresent;
  }

  get apiObjectDropdownLabel() {
    if (this.selectedApiObjectMissing) {
      return this.objectMapping.responseObjectPath;
    }
    return this.intl.t('workflow-connector.builder.body.response.mapping.dropdown.select-object');
  }

  get hasErrors() {
    return this.showApiObjectDropdownError || this.showIntercomObjectDropdownError;
  }

  get showApiObjectDropdownError() {
    return this.selectedApiObjectMissing;
  }

  get showIntercomObjectDropdownError() {
    // TODO intercom object missing
    return this.objectMapping.isInvalidArrayMapping;
  }

  get apiObjectMappingOptions() {
    let apiObjectMappingOptions = this.objectMapping.workflowAction.apiMappableObjects.map(
      (apiMappableObject) => {
        return {
          text: apiMappableObject.name,
          value: apiMappableObject.fullPath,
          tooltipText: apiMappableObject.name,
          count: apiMappableObject.isArray ? 'Array' : 'Object',
        };
      },
    );
    return apiObjectMappingOptions;
  }

  get disableAddNewMappingButton() {
    return (
      this.objectMapping.responseObjectPath === undefined ||
      this.objectMapping.intercomObjectType === undefined ||
      this.selectedApiObjectMissing
    );
  }
}
