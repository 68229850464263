/* import __COLOCATED_TEMPLATE__ from './first-group-preview.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';

export default class FirstGroupPreview extends Component {
  get previewSteps() {
    let steps = [];
    let firstGroupSteps = this.args.group.steps;
    for (let i = 0; i < firstGroupSteps.length; i++) {
      let step = firstGroupSteps.objectAt(i);
      steps.push(step);

      if (this._isFreeInput(step)) {
        break;
      }
    }
    return steps;
  }

  get showOperatorPart() {
    return this.previewSteps.some(
      (step) => step.serializedBlocks || this._isAttributeCollector(step),
    );
  }

  get groupEndStep() {
    return this.previewSteps.lastObject.isGroupEnding ? this.previewSteps.lastObject : undefined;
  }

  get showComposer() {
    return (
      this._isFreeInput(this.previewSteps.lastObject) ||
      this._isHandoverToResolutionBot(this.previewSteps.lastObject)
    );
  }

  _isAttributeCollector(step) {
    return step.type === 'operator/visual-builder/step/attribute-collector';
  }

  _isFreeInput(step) {
    return step.type === 'operator/visual-builder/step/free-input';
  }

  _isHandoverToResolutionBot(step) {
    return step.type === 'operator/visual-builder/step/answer-bot';
  }
}
