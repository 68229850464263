/* import __COLOCATED_TEMPLATE__ from './step-option-menu.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import { type PopoverArgs } from '@intercom/pulse/template-registry';

// NOTE: When using this component make sure that its parent is
// relatively positioned. Layout issues will occur if this isn't the case.
// Also add the workflows__graph-editor__hover-step-menu-parent class

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

interface Args {
  alignTop?: boolean;
  options: (PopoverArgs & { onSelectItem: (args?: Args['extras']) => void })[] | null;
  extras?: {
    button?: ConnectionPoint;
    branch?: ConnectionPoint;
  };
}

export default class StepOptionMenu extends Component<Signature> {
  get items() {
    // map over options to bind extras onSelectItem
    return (this.args.options || []).map((option) => {
      return {
        ...option,
        onSelectItem: option.onSelectItem.bind(option, this.args.extras),
      };
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::StepOptionMenu': typeof StepOptionMenu;
  }
}
