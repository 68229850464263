/* import __COLOCATED_TEMPLATE__ from './multi-trigger-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import { INACTIVITY_TRIGGERS_VALUES } from 'embercom/lib/workflows/constants/triggers';
import { paywallTeammateInactivity } from 'embercom/lib/workflows/helpers/paywall_trigger';
import Trigger from '../../../lib/workflows/constants/triggers';
import { getOwner } from '@ember/application';

export default class EditorComponent extends Component {
  @service appService;
  @service intl;

  get triggers() {
    return new Trigger(getOwner(this)).items;
  }

  get selectedTriggers() {
    return this.args.workflow.triggerTypes;
  }

  set selectedTriggers(newTriggers) {
    this.args.workflow.triggerTypes = newTriggers;
  }

  isInactivityTrigger(value) {
    return INACTIVITY_TRIGGERS_VALUES.includes(value);
  }

  get app() {
    return this.appService.app;
  }

  get teammatePaywall() {
    return this.args.teammateInactivityPaywall;
  }

  get triggerItems() {
    return this.triggers
      .map((item) => {
        let itemParams = {
          component: 'workflows/editor/trigger-select-item-component',
        };

        if (item.value === 'awaiting_first_reply' && this.teammatePaywall?.isActive) {
          itemParams = Object.assign(
            {},
            itemParams,
            paywallTeammateInactivity(this.teammatePaywall),
          );
        }

        return Object.assign({}, item, itemParams);
      })
      .compact();
  }

  get availableTriggerItems() {
    let triggers = this.triggerItems;

    triggers = triggers.filter((item) => !this.selectedTriggers.includes(item.value));
    triggers = triggers.map((item) => {
      if (this.isInactivityTrigger(item.value) && this.inactivityTriggerAlreadySelected) {
        item.isDisabled = true;
        item.tooltipText = this.intl.t(
          'settings.inbox-rules.triggers.delay.inactivity-trigger-already-selected',
        );
      }
      return item;
    });
    return triggers;
  }

  get triggerItemsGroupList() {
    return [{ items: this.availableTriggerItems }];
  }

  get selectedItems() {
    return this.triggerItems.filter((item) => this.selectedTriggers.includes(item.value));
  }

  get inactivityTriggerAlreadySelected() {
    return (
      this.selectedTriggers.includes('awaiting_first_reply') ||
      this.selectedTriggers.includes('awaiting_end_user_reply')
    );
  }

  get allTriggerItemsDisabled() {
    return this.availableTriggerItems.every((item) => item.isDisabled);
  }

  get canAddMoreEvents() {
    if (this.availableTriggerItems.length === 0 || this.allTriggerItemsDisabled) {
      return false;
    }
    return true;
  }

  findTrigger(triggerName) {
    return this.triggers.find((trigger) => trigger.value === triggerName);
  }

  hasCustomComponent(triggerName) {
    return this.findTrigger(triggerName).componentPath !== undefined;
  }

  componentTriggerPath(triggerName) {
    let trigger = this.findTrigger(triggerName);
    return `workflows/editor/triggers/${trigger.componentPath}`;
  }

  @action
  addTrigger(value) {
    if (this.teammatePaywall?.isActive && value === 'awaiting_first_reply') {
      return;
    }
    this.selectedTriggers.pushObject(value);
  }

  @action
  removeTrigger(value) {
    let newTriggerTypes = this.selectedTriggers.filter((trigger) => trigger !== value);
    // this.selectedTriggers = newTriggerTypes;
    this.args.workflow.triggerTypes = newTriggerTypes;
  }
}
