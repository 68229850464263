/* import __COLOCATED_TEMPLATE__ from './response-transformation.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Action from 'embercom/models/workflow-connector/action';
import type ResponseField from 'embercom/models/workflow-connector/response-field';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type ValueTransformation from 'embercom/models/workflow-connector/value-transformation';
import { type TaskGenerator } from 'ember-concurrency';
import { post } from 'embercom/lib/ajax';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  workflowAction: Action;
}

interface Signature {
  Args: Args;
}

export default class ResponseTransformation extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: any;
  @service declare notificationsService: $TSFixMe;

  @tracked selectedField: ResponseField | null = null;

  readonly CONFIGURABLE_DATA_TYPES: { text: string; value: string; isDisabled: boolean }[] = [
    {
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      text: 'String',
      value: 'string',
      isDisabled: false,
    },
    {
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      text: 'Integer',
      value: 'integer',
      isDisabled: false,
    },
    {
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      text: 'Decimal',
      value: 'decimal',
      isDisabled: false,
    },
    {
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      text: 'Boolean',
      value: 'boolean',
      isDisabled: false,
    },
    {
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      text: 'Datetime',
      value: 'datetime',
      isDisabled: false,
    },
  ];

  readonly UNKNOWN_DATA_TYPE: { text: string; value: string; isDisabled: boolean } = {
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    text: 'Unknown',
    value: 'unknown',
    isDisabled: true,
  };

  readonly MAPPABLE_FIELD_TYPES: string[] = ['string', 'integer', 'decimal', 'boolean'];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (!this.args.workflowAction.finResponseAttributes) {
      this.args.workflowAction.finResponseAttributes = this.args.workflowAction.responseFields
        .filter((field) => !field.redacted)
        .map((field) => field.responsePath);
    }
    this.fetchResponseFields();
  }

  get columns() {
    let columns = [
      {
        label: this.intl.t('workflow-connector.builder.body.response.fin.redaction.table.data'),
        valuePath: 'name',
        width: '45%',
      },
      {
        label: this.intl.t('workflow-connector.builder.body.response.fin.redaction.table.example'),
        valuePath: 'example',
        width: '30%',
      },
      {
        label: this.intl.t('workflow-connector.builder.body.response.fin.redaction.table.format'),
        valuePath: 'format',
        width: '15%',
      },
      {
        type: 'action',
        valuePath: 'edit',
        width: '5%',
      },
    ];

    if (!this.appService.app.canUseFeature('fin-actions-response-formatting')) {
      columns.splice(2, 2);
    }

    if (!this.disableIncludeCheckboxes) {
      columns.unshift({
        type: 'checkbox',
        valuePath: 'actions',
        width: '5%',
      });
    }

    return columns;
  }

  get showNoAttributesSelectedBanner() {
    return !this.args.workflowAction.responseAttributesSelectionValid;
  }

  get disableIncludeCheckboxes() {
    return (
      this.args.workflowAction.includeFullResponse ||
      (this.args.workflowAction.isTestResponse && this.args.workflowAction.actionNotTested)
    );
  }

  @action
  handleSelect(value: any) {
    let selectedField = this.args.workflowAction.responseFields.find(
      (field) => field.responsePath === value,
    )!;

    selectedField.redacted = !selectedField.redacted;

    if (this.args.workflowAction.finResponseAttributes.includes(value)) {
      this.args.workflowAction.finResponseAttributes.removeAt(
        this.args.workflowAction.finResponseAttributes.indexOf(value),
      );
    } else {
      this.args.workflowAction.finResponseAttributes.pushObject(value);
    }
  }

  @action
  onBulkSelect() {
    let hasAllSelected = this.args.workflowAction.responseFields.every((field) =>
      this.args.workflowAction.finResponseAttributes.includes(field.responsePath),
    );

    if (hasAllSelected) {
      this.resetBulkSelect();
    } else if (this.args.workflowAction.finResponseAttributes.length >= 0) {
      this.args.workflowAction.finResponseAttributes = this.args.workflowAction.responseFields.map(
        (field) => field.responsePath,
      );
      this.args.workflowAction.responseFields.forEach((field) => (field.redacted = false));
    }
  }

  @action
  resetBulkSelect() {
    this.args.workflowAction.finResponseAttributes = [];
    this.args.workflowAction.responseFields.forEach((field) => (field.redacted = true));
  }

  @action
  setSelectedField(field: ResponseField) {
    this.selectedField = this.store.createFragment('workflow-connector/response-field', {
      responsePath: field.responsePath,
      rawFieldName: field.rawFieldName,
      redacted: field.redacted,
      fieldType: field.fieldType,
      humanReadableFieldName: field.humanReadableFieldName,
      exampleValue: field.exampleValue,
      description: field.description,
      valuesMap: field.valuesMap,
      valueTransformations: this.valueTransformationsArrayFromValuesMap(field, field.valuesMap),
    });
  }

  @action
  toggleSelectedFieldRedaction() {
    this.selectedField!.redacted = !this.selectedField!.redacted;
  }

  get validTypesForSelectedField() {
    if (this.selectedField?.fieldType === 'unknown') {
      return this.CONFIGURABLE_DATA_TYPES.addObject(this.UNKNOWN_DATA_TYPE);
    }

    return this.CONFIGURABLE_DATA_TYPES;
  }

  @action
  setSelectedFieldType(selectedType: string) {
    if (selectedType === 'boolean') {
      this.selectedField!.valueTransformations = this.defaultBooleanValueTransformations(
        this.selectedField!,
      );
    } else if (this.selectedField!.fieldType === 'boolean') {
      this.selectedField!.valueTransformations = [];
    }

    this.selectedField!.fieldType = selectedType;
  }

  get selectedFieldType() {
    return this.selectedField?.fieldType || 'unknown';
  }

  get fieldTypeIsMappable() {
    return this.selectedField && this.MAPPABLE_FIELD_TYPES.includes(this.selectedField.fieldType);
  }

  @action
  addValueTransformation() {
    let valueMapping = this.store.createFragment('workflow-connector/value-transformation', {
      responseField: this.selectedField,
      rawValue: '',
      transformedValue: '',
    });
    this.selectedField?.valueTransformations.pushObject(valueMapping);
  }

  @action
  deleteValueTransformation(valueMapping: ValueTransformation) {
    this.selectedField?.valueTransformations.removeObject(valueMapping);
  }

  valueTransformationsArrayFromValuesMap(
    responseField: ResponseField,
    valuesMap: { [key: string]: any } | null,
  ): ValueTransformation[] {
    if (!valuesMap || Object.keys(valuesMap).length === 0) {
      return responseField.fieldType === 'boolean'
        ? this.defaultBooleanValueTransformations(responseField)
        : [];
    }

    return Object.entries(valuesMap).map(([key, value]) =>
      this.store.createFragment('workflow-connector/value-transformation', {
        responseField,
        responseValue: key,
        transformedValue: value,
      }),
    );
  }

  valueTransformationsArrayToValuesMap(valueTransformations: ValueTransformation[]): {
    [key: string]: any;
  } {
    return valueTransformations.reduce(
      (valuesMap, valueTransformation) => {
        valuesMap[valueTransformation.responseValue] = valueTransformation.transformedValue;
        return valuesMap;
      },
      {} as { [key: string]: any },
    );
  }

  defaultBooleanValueTransformations(responseField: ResponseField) {
    return [
      this.store.createFragment('workflow-connector/value-transformation', {
        responseField,
        responseValue: 'true',
        transformedValue: 'true',
      }),
      this.store.createFragment('workflow-connector/value-transformation', {
        responseField,
        responseValue: 'false',
        transformedValue: 'false',
      }),
    ];
  }

  @action
  onCancel() {
    this.selectedField = null;
  }

  @action
  onSave() {
    this.args.workflowAction.responseFields.forEach((field) => {
      if (field.responsePath === this.selectedField?.responsePath) {
        field.humanReadableFieldName = this.selectedField.humanReadableFieldName;
        field.fieldType = this.selectedField.fieldType;
        field.redacted = this.selectedField.redacted;
        field.valuesMap = this.valueTransformationsArrayToValuesMap(
          this.selectedField.valueTransformations,
        );

        if (
          field.redacted &&
          this.args.workflowAction.finResponseAttributes.includes(field.responsePath)
        ) {
          this.args.workflowAction.finResponseAttributes.removeAt(
            this.args.workflowAction.finResponseAttributes.indexOf(field.responsePath),
          );
        } else if (
          !field.redacted &&
          !this.args.workflowAction.finResponseAttributes.includes(field.responsePath)
        ) {
          this.args.workflowAction.finResponseAttributes.pushObject(field.responsePath);
        }
      }
    });
    this.selectedField = null;
  }

  get isFetchingResponseFields() {
    return taskFor(this.getResponseFields).isRunning;
  }

  @dropTask
  *getResponseFields(): TaskGenerator<void> {
    try {
      let response = yield post(
        `/ember/workflow_connector/actions/${this.args.workflowAction.id}/response_fields`,
        {
          app_id: this.appService.app.id,
          workflow_action: this.args.workflowAction,
        },
      );

      let responseFields = response.response_fields.map((responseField: ResponseField) => {
        return this.store.createFragment(
          'workflow-connector/response-field',
          (this.store.normalize('workflow-connector/response-field', responseField) as any).data
            .attributes,
        );
      });

      this.args.workflowAction.responseFields = responseFields;
    } catch (exception) {
      this.notificationsService.notifyError(
        this.intl.t('workflow-connector.builder.body.testing.error-message'),
      );
    }
  }

  private fetchResponseFields() {
    if (
      (this.args.workflowAction.isMockResponse && this.args.workflowAction.mockIsValid) ||
      (this.args.workflowAction.isTestResponse && this.args.workflowAction.testIsValid)
    ) {
      taskFor(this.getResponseFields).perform();
    } else {
      this.args.workflowAction.responseFields = [];
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::Response::ResponseTransformation': typeof ResponseTransformation;
    'workflow-connector/builder/body/sections/response/response-transformation': typeof ResponseTransformation;
  }
}
