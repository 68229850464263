/* import __COLOCATED_TEMPLATE__ from './add-tag-to-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';

export default class AttributeCollector extends Component {
  get allRuleActions() {
    return this.steps.mapBy('firstSimpleAction').reject((action) => !action);
  }

  get steps() {
    return this.group.steps;
  }

  get group() {
    return this.args.step.group;
  }

  get shouldShowError() {
    return this.args.editorState.shouldShowValidations && !this.args.step.validations.isValid;
  }
}
