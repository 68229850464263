/* import __COLOCATED_TEMPLATE__ from './finish-answer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
  Element: HTMLElement;
}

const FinishAnswer = templateOnlyComponent<Signature>();
export default FinishAnswer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::AnswerTerminal::FinishAnswer': typeof FinishAnswer;
  }
}
