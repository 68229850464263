/* import __COLOCATED_TEMPLATE__ from './popover-menu-opener.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';
import { type ButtonType } from '@intercom/pulse/template-registry';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Element: HTMLElement;
  Args: {
    label: string;
    subtle?: boolean;
    icon?: InterfaceIconName;
    type?: ButtonType;
    onClick?: () => void;
  };
}

const PopoverMenuOpener = templateOnlyComponent<Signature>();
export default PopoverMenuOpener;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::PopoverMenu::PopoverMenuOpener': typeof PopoverMenuOpener;
  }
}
