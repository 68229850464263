/* import __COLOCATED_TEMPLATE__ from './filter-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { isPresent } from '@ember/utils';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import PredicateGroup from 'predicates/models/predicate-group';
import Predicate from 'predicates/models/predicate';
import { copy } from 'ember-copy';

export default Component.extend({
  store: service(),
  appService: service(),
  app: readOnly('appService.app'),
  classNames: ['flex-auto', 'flex', 'flex-col'],
  didReceiveAttrs() {
    this._super(...arguments);
    let rawPredicates = this.legacySegment.predicates;
    // @pat - `anonymous` predicates in segments are deprecated in favour of `role` predicates
    // @pat - We cannot use legacySegment.hasAnonymousPredicate because that is clobbered between two mixins (predicate-manager and predicate-validator) and the results if gives back differ to one another
    if (isPresent(this.get('legacySegment.anonymousPredicate'))) {
      rawPredicates = PredicateGroup.migrateAnonymousToRolePredicate(rawPredicates);
    }
    let predicates = PredicateGroup.convertRawPredicates(this.store, rawPredicates);
    let segment = this.store.createFragment(`people/segment`, { predicates });
    this.set('segment', segment);
  },
  _updateLegacyNestedPredicates() {
    // @pat - see comment here https://github.com/intercom/embercom/blob/667e23b2a5f85ba79a4942b3d48225d5ed83e1f4/app/models/mixins/predicate-validator.js#L80.
    // Unfortunately we need to update `nestedPredicates` on the legacy segment model separately as the changes are not picked up automatically
    let nestedPredicates = this.get('segment.logicalPredicate')
      ? this._flattenAndSerializePredicates(this.get('segment.basePredicates'))
      : [];
    this.set('legacySegment.nestedPredicates', nestedPredicates);
  },
  _flattenAndSerializePredicates(predicates) {
    return Array.from(Predicate.walkPredicates(predicates)).map((pred) => pred.serialize());
  },
  actions: {
    updateSegmentPredicates(predicates, meta) {
      this.setProperties({
        'segment.predicates': copy(predicates),
        'legacySegment.predicates': predicates.serialize(),
      });
      this._updateLegacyNestedPredicates();
      this.onUpdate(meta.action);
    },
  },
});
