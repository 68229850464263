/* import __COLOCATED_TEMPLATE__ from './notify-slack-channel.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type NotifySlackChannel from 'embercom/models/operator/visual-builder/step/notify-slack-channel';

interface Arguments {
  step: NotifySlackChannel;
  readOnly: boolean;
  editorState: any;
}

export default class NotifySlackChannelNodeItem extends Component<Arguments> {
  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
  }

  get titleTranslationKey() {
    if (this.args.step.action.actionData?.slack_channel_type === 'private') {
      return 'operator.workflows.visual-builder.notify-slack-channel.title.private';
    }
    return 'operator.workflows.visual-builder.notify-slack-channel.title.public';
  }

  get tooltipTranslationKey() {
    if (this.args.step.action.actionData?.slack_channel_type === 'private') {
      return 'operator.workflows.visual-builder.notify-slack-channel.tooltip.private';
    }
    return 'operator.workflows.visual-builder.notify-slack-channel.tooltip.public';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::NotifySlackChannel': typeof NotifySlackChannelNodeItem;
    'workflows/graph-editor/node-items/steps/notify-slack-channel': typeof NotifySlackChannelNodeItem;
  }
}
