/* import __COLOCATED_TEMPLATE__ from './hang-up.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type Hangup from 'embercom/models/operator/visual-builder/step/hang-up';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

interface Arguments {
  step: Hangup;
  editorState: EditorState;
  readOnly: boolean;
}

export default class HangupTerminal extends Component<Arguments> {
  get shouldShowError() {
    return this.args.editorState.shouldShowValidations && !this.args.step.validations.isValid;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::HangUp': typeof HangupTerminal;
    'workflows/graph-editor/node-items/steps/hang-up': typeof HangupTerminal;
  }
}
