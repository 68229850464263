/* import __COLOCATED_TEMPLATE__ from './personalized-video-player.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import redirect from 'embercom/lib/redirect';
import { getVideoProjectByKey } from 'embercom/lib/video/video-projects';

export default class PersonalisedVideoPlayer extends Component {
  get project() {
    return getVideoProjectByKey(this.args.videoKey);
  }

  @action
  ctaClickHandler() {
    redirect(this.project.ctaUrl);
  }
}
