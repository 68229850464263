/* import __COLOCATED_TEMPLATE__ from './trigger-bots.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type ContentDependency from 'embercom/models/matching-system/content-dependency';

interface Args {
  linkedWorkflows: ContentDependency[];
  liveState: number;
  readOnly: boolean;
}

export default class TriggerBots extends Component<Args> {
  @service declare contentEditorService: $TSFixMe;
  @tracked allowManualTrigger: boolean;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.allowManualTrigger = this.botData.allowManualTrigger;
  }

  get botData() {
    return this.contentEditorService.activeObject.botData;
  }

  @action
  toggleAllowManualTrigger() {
    this.allowManualTrigger = !this.allowManualTrigger;
    this.botData.allowManualTrigger = this.allowManualTrigger;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::TriggerInfoNodeContent': typeof TriggerBots;
    'workflows/graph-editor/trigger-info-node-content': typeof TriggerBots;
  }
}
