/* import __COLOCATED_TEMPLATE__ from './phone-reply-buttons.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import type ChatMessage from 'embercom/models/operator/visual-builder/step/chat-message';
import type IntlService from 'ember-intl/services/intl';
import { type ReplyButtonsArgs } from './reply-buttons';
import type ReplyButtons from 'embercom/models/operator/visual-builder/step/reply-buttons';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { type SyncHasMany } from '@ember-data/model';

export interface PhoneReplyButtonsArgs extends ReplyButtonsArgs {
  step: ReplyButtons & ChatMessage;
  editorState: EditorState;
}

interface Signature {
  Element: HTMLDivElement;
  Args: PhoneReplyButtonsArgs;
}

export default class PhoneReplyButtonsNodeItem extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: any;

  @tracked hasExistingBlocksOrHasBeenFocused = false;

  constructor(owner: unknown, args: PhoneReplyButtonsArgs) {
    super(owner, args);

    this.hasExistingBlocksOrHasBeenFocused = !this._hasNoOrEmptyBlocks();
    this.onReplyButtonChange(this.args.step);
  }

  get suffixMap() {
    let suffixMap = new Map<ConnectionPoint, string>();
    this.args.step.outwardConnectionPoints.map((button, index) => {
      let number = (index + 1) % 10;
      let suffixText = `${this.intl.t(
        'operator.workflows.visual-builder.reply-option-suffix',
      )} ${number}`;
      suffixMap.set(button, suffixText);
    });

    return suffixMap;
  }

  @action
  onFocusIntro() {
    this.hasExistingBlocksOrHasBeenFocused = true;
  }

  @action
  onReplyButtonChange(_step: ReplyButtons) {
    this._maybeGenerateQuickReplyIntro();
  }

  @action
  onReplyButtonReorder(_buttons: SyncHasMany<ConnectionPoint>) {
    this._maybeGenerateQuickReplyIntro();
  }

  private _maybeGenerateQuickReplyIntro() {
    if (!this.hasExistingBlocksOrHasBeenFocused) {
      let blocksForQuickReplyIntro = this._generateTextForQuickReplies().map((text) =>
        this.store.createFragment('common/blocks/paragraph', {
          type: 'paragraph',
          text,
        }),
      );

      if (blocksForQuickReplyIntro.length > 0) {
        this.args.step.blocks = blocksForQuickReplyIntro as any;
        this.args.step.notifyPropertyChange('blocks');
        this.args.editorState.focusObject(this.args.step);
      }
    }
  }

  private _hasNoOrEmptyBlocks() {
    return (
      this.args.step.blocks.length === 0 ||
      (this.args.step.blocks.length === 1 &&
        this.args.step.blocks.firstObject.type === 'paragraph' &&
        this.args.step.blocks.firstObject.text === '')
    );
  }

  private _generateTextForQuickReplies() {
    return this.args.step.outwardConnectionPoints
      .filter((button) => button.type !== 'capture_composer_input')
      .filter((button) => !!button.label)
      .map((button, i) => {
        return `${button.label} press ${(i + 1) % 10}.`;
      });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::PhoneReplyButtons': typeof PhoneReplyButtonsNodeItem;
  }
}
