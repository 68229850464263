/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import type PhoneCallCsatRequest from 'embercom/models/operator/visual-builder/step/phone-call-csat-request';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import templateOnlyComponent from '@ember/component/template-only';

interface Arguments {
  step: PhoneCallCsatRequest;
  isReadOnly: boolean;
  editorState: EditorState;
}

const PhoneCallCsatRequestEditor = templateOnlyComponent<Arguments>();
export default PhoneCallCsatRequestEditor;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::PhoneCallCsatRequest::Editor': typeof PhoneCallCsatRequestEditor;
    'workflows/graph-editor/node-items/steps/phone-call-csat-request/editor': typeof PhoneCallCsatRequestEditor;
  }
}
