/* import __COLOCATED_TEMPLATE__ from './action-builder-banner.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Signature {
  Element: HTMLDivElement;
}

export default class ActionBuilderBanner extends Component<Signature> {
  @service declare intl: any;

  @action
  openMessenger() {
    showNewMessageInIntercomWidget(
      this.intl.t('workflow-connector.builder.body.request.banner.get-help-message'),
    );
  }

  @action
  openSecurityArticle() {
    safeWindowOpen(
      'https://www.intercom.com/help/en/articles/9916507-data-and-actions-faqs#h_25283beae0',
      '_blank',
    );
  }

  @action
  openFinActionsArticle() {
    safeWindowOpen(
      'https://www.intercom.com/help/en/articles/9916497-add-actions-for-fin-ai-agent-beta#h_3a6e7bba4e',
      '_blank',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::ActionBuilderBanner': typeof ActionBuilderBanner;
    'workflow-connector/builder/body/sections/action-builder-banner': typeof ActionBuilderBanner;
  }
}
