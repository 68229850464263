/* import __COLOCATED_TEMPLATE__ from './custom-action-tab-content.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  label: string;
  isValid: boolean;
}

interface Signature {
  Args: Args;
  Element: Element;
}

const CustomActionTabContent = templateOnlyComponent<Signature>();
export default CustomActionTabContent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::CustomActionTabContnet': typeof CustomActionTabContent;
    'workflow-connector/custom-action-tab-content': typeof CustomActionTabContent;
  }
}
