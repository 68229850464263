/* import __COLOCATED_TEMPLATE__ from './phone-hold-and-assign.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { type ComponentLike } from '@glint/template';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import type PhoneHoldAndAssign from 'embercom/models/operator/visual-builder/step/phone-hold-and-assign';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type { Yieldable } from 'ember-concurrency';

interface Arguments {
  step: PhoneHoldAndAssign;
  editorState: EditorState;
  readOnly: boolean;
  connectionPointComponent: ComponentLike<{ connectionPoint: ConnectionPoint }>;
  updateConnectionPoints: (node: Node) => Generator<Yieldable<void>, void, unknown>;
}

export default class PhoneHoldAndAssignNodeItem extends Component<Arguments> {
  get fallbackBranch() {
    return this.args.step.outwardConnectionPoints.firstObject;
  }

  get shouldShowOutputError() {
    return (
      this.args.editorState.shouldShowValidations &&
      !this.args.step.validations.attrs.outwardConnectionPoints.isValid
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::PhoneHoldAndAssign': typeof PhoneHoldAndAssignNodeItem;
    'workflows/graph-editor/node-items/steps/phone-hold-and-assign': typeof PhoneHoldAndAssignNodeItem;
  }
}
