/* import __COLOCATED_TEMPLATE__ from './inactivity-duration.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Delays from 'embercom/lib/workflows/helpers/delays';

export default class InactivityDuration extends Component {
  @tracked durationValue = this.delays.getDelayValue(this.inactivityDuration);
  @tracked durationUnit = this.delays.getDelayUnit(this.inactivityDuration);

  get inactivityDuration() {
    return this.args.customBot.inactivityDuration;
  }

  get isInvalidDuration() {
    return !this.args.customBot.validations.attrs.inactivityDuration.isValid;
  }

  get delays() {
    return new Delays();
  }

  @action
  setDurationValue(event) {
    if (event.target.value === '') {
      return;
    }

    this.args.customBot.inactivityDuration = this.delays.getDelayInSeconds(
      Number(event.target.value),
      this.durationUnit,
    );
  }

  @action
  setDurationUnit(unit) {
    this.durationUnit = unit;
    this.args.customBot.inactivityDuration = this.delays.getDelayInSeconds(
      this.durationValue,
      unit,
    );
  }
}
