/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import type HoldAndAssign from 'embercom/models/operator/visual-builder/step/hold-and-assign';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type { Block } from '@intercom/interblocks.ts';

interface Arguments {
  step: HoldAndAssign;
  isReadOnly: boolean;
  editorState: EditorState;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class HoldAndAssignEditor extends Component<Arguments> {
  @service declare store: Store;
  @tracked selectedTab = 'answer';
  @tracked blocks: Array<Block>;
  @tracked csatEnabled: boolean;

  teamAssignmentAction: any;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);

    this.teamAssignmentAction = this.store.createFragment('rules/rule-action', {
      type: 'assign-conversation',
      actionData: {
        skip_assigned: false,
        assignee_id: this.args.step.teamId,
      },
    });

    this.blocks = this.args.step.blocks;
    this.csatEnabled = this.args.step.csatEnabled || false;
  }

  @action
  onChange() {
    this.args.step.teamId = this.teamAssignmentAction.actionData.assignee_id;
  }

  @action onChangeCsatFlag() {
    this.csatEnabled = !this.csatEnabled;
    this.args.step.csatEnabled = this.csatEnabled;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::HoldAndAssign::Editor': typeof HoldAndAssignEditor;
    'workflows/graph-editor/node-items/steps/hold-and-assign/editor': typeof HoldAndAssignEditor;
  }
}
