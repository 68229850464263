/* import __COLOCATED_TEMPLATE__ from './apply-conversation-sla.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Arguments {
  action: any;
  actionEditorContext: any;
}

export default class ApplyConversationSlaAction extends Component<Arguments> {
  @service declare appService: any;

  get actionSlaName() {
    let slaId = this.args.action.actionData.conversation_sla_id;
    let sla = this.args.actionEditorContext?.conversationSlas?.find(
      (sla: any) => !sla.archived && sla.id === slaId,
    );
    return sla ? sla.name : '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ApplyRules::Actions::ApplyConversationSla': typeof ApplyConversationSlaAction;
    'workflows/graph-editor/node-items/steps/apply-rules/actions/apply-conversation-sla': typeof ApplyConversationSlaAction;
  }
}
