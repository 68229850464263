/* import __COLOCATED_TEMPLATE__ from './webhook-received-trigger-settings.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/application';
import type Store from '@ember-data/store';
// @ts-ignore
import type CustomBotConfig from 'embercom/objects/operator/configuration/custom-bot';
import type IntlService from 'embercom/services/intl';
// @ts-ignore
import type InboundTrigger from 'embercom/models/operator/workflow-connector/inbound-trigger';
// @ts-ignore
import PredicateGroup from 'predicates/models/predicate-group';
import AudienceHeaderConfiguration from 'embercom/objects/content-editor/panels/audience/standard-audience-header-configuration';
import StandardAudienceEditorConfiguration from 'embercom/objects/audience-editor/standard-audience-editor-configuration';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';
import ComponentConfiguration from 'embercom/objects/content-editor/component-configuration';
import { TRIGGER_PREDICATE_PREFIX } from 'embercom/lib/workflows/constants/webhook_received';
import type ClipboardService from 'embercom/services/clipboard-service';

interface Args {
  infoPanelTriggerData: CustomBotConfig;
  readOnly: boolean;
  editorConfiguration: EditorConfig;
}

export default class WebhookReceivedTriggerSettings extends Component<Args> {
  @service declare appService: any;
  @service declare contentEditorService: any;
  @service declare intl: IntlService;
  @service declare attributeService: any;
  @service declare clipboardService: ClipboardService;
  @service declare store: Store;
  @tracked openSectionId?: string = this.initialOpenSectionId;
  @tracked transitionToMapping = false;

  availableTriggers: any = [];

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.availableTriggers = this.store.peekAll('workflow-connector/inbound-trigger');
  }

  get audienceEditorConfiguration() {
    let audienceEditorConfig: any = new StandardAudienceEditorConfiguration({
      showMatchBehavior: false,
      allowedUserRoles: this.customBot.allowedUserRoles,
      showClientPredicates: false,
      showAudiencePreview: false,
      container: this.container,
      editorConfiguration: this.args.editorConfiguration,
    });

    audienceEditorConfig.audienceRulesEditor = ComponentConfiguration.create({
      component: 'content-editor/audience-wrappers/content/workflows/audience-rules-editor',
      config: audienceEditorConfig.inputs,
    } as any);
    return audienceEditorConfig;
  }

  get selectedTriggerUUID() {
    return (
      this.customBot?.triggerPredicateGroup?.predicates?.firstObject?.predicates?.firstObject
        ?.value || this.availableTriggers.firstObject?.uuid
    );
  }

  get initialOpenSectionId() {
    return this.args.readOnly ? undefined : 'webhooks_url';
  }

  get app() {
    return this.appService.app;
  }

  get triggerItems() {
    return this.availableTriggers.map((trigger: InboundTrigger) => {
      return {
        text: trigger.name,
        value: trigger.uuid,
      };
    });
  }

  @action
  setTrigger(uuid: string) {
    this.customBot.triggerPredicateGroup = this.store.createFragment('predicates/predicate-group', {
      predicates: PredicateGroup.convertRawPredicates(this.store, [
        {
          type: 'and',
          predicates: [
            {
              attribute: `${TRIGGER_PREDICATE_PREFIX}.uuid`,
              type: 'string',
              comparison: 'eq',
              value: uuid,
            },
            {
              type: 'or',
              predicates: [
                {
                  attribute: `${TRIGGER_PREDICATE_PREFIX}.target_context`,
                  type: 'string',
                  comparison: 'eq',
                  value: 'customer_ticket',
                },
                {
                  attribute: `${TRIGGER_PREDICATE_PREFIX}.target_context`,
                  type: 'string',
                  comparison: 'eq',
                  value: 'back_office_ticket',
                },
              ],
            },
          ],
        },
      ]),
    });
  }

  get container() {
    return getOwner(this);
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get customBot() {
    return this.contentEditorService.activeObject;
  }

  get audienceHeaderConfig() {
    return AudienceHeaderConfiguration.create(
      {
        title: this.customBot.isPhoneCallTrigger
          ? this.intl.t(
              'operator.workflows.visual-builder.trigger-info-node-content.phone-bot-audience-header-title',
            )
          : this.intl.t(
              'operator.workflows.visual-builder.trigger-info-node-content.audience-header-title',
            ),
        hideMatchBehavior: true,
        showChannelTargetingSummary:
          this.customBot.canUseOmnichannel && !this.customBot.isPhoneCallTrigger,
      } as never,
      { container: this.container } as never,
    );
  }

  @action onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::TriggerInfoNodeContent::WebhookReceivedTriggerSettings': typeof WebhookReceivedTriggerSettings;
    'workflows/graph-editor/trigger-info-node-content/webhook-received-trigger-settings': typeof WebhookReceivedTriggerSettings;
  }
}
