/* import __COLOCATED_TEMPLATE__ from './set-language-override.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Arguments {
  action: $TSFixMe;
  truncateText: boolean;
}

interface Signature {
  Args: Arguments;
}

const SetLanguageOverrideAction = templateOnlyComponent<Signature>();
export default SetLanguageOverrideAction;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ApplyRules::Actions::SetLanguageOverrideAction': typeof SetLanguageOverrideAction;
    'workflows/graph-editor/node-items/steps/apply-rules/actions/set-language-override': typeof SetLanguageOverrideAction;
  }
}
