/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import type ForwardCall from 'embercom/models/operator/visual-builder/step/forward-call';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Arguments {
  step: ForwardCall;
  isReadOnly: boolean;
  editorState: EditorState;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class ForwardCallEditor extends Component<Arguments> {
  @service declare store: Store;

  @tracked phoneNumber?: string;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
    this.phoneNumber = this.args.step.phoneNumber;
  }

  @action
  updatePhoneNumber() {
    this.args.step.phoneNumber = this.phoneNumber!;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ForwardCall::Editor': typeof ForwardCallEditor;
    'workflows/graph-editor/node-items/steps/forward-call/editor': typeof ForwardCallEditor;
  }
}
