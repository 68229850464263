/* import __COLOCATED_TEMPLATE__ from './resolved.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
}

const Resolved = templateOnlyComponent<Signature>();
export default Resolved;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::Markers::Resolved': typeof Resolved;
  }
}
