/* import __COLOCATED_TEMPLATE__ from './audio-snippet-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import type { Block } from '@intercom/interblocks.ts';

interface Arguments {
  step: any;
  readOnly: boolean;
}

export default class AudioSnippetEditor extends Component<Arguments> {
  @service attributeService: any;
  @service intl!: IntlService;
  @service appService: any;
  @tracked selectedTTSMode: string;
  @tracked selectedLocale: string;
  @tracked blocks: Array<Block>;
  @service store: any;

  ttsModes = [
    {
      text: this.intl.t(
        'operator.workflows.visual-builder.hold-and-assign-editor.exit-tab.audio-snippet-editor.tts-mode',
      ),
      value: 'Text to Speech',
    },
    {
      text: this.intl.t(
        'operator.workflows.visual-builder.hold-and-assign-editor.exit-tab.audio-snippet-editor.audio-snippet-mode',
      ),
      value: 'Audio Snippets',
    },
  ];

  locales = [
    {
      text: this.intl.t(
        'operator.workflows.visual-builder.hold-and-assign-editor.exit-tab.audio-snippet-editor.usa-english',
      ),
      value: 'en-US',
    },
    {
      text: this.intl.t(
        'operator.workflows.visual-builder.hold-and-assign-editor.exit-tab.audio-snippet-editor.uk-english',
      ),
      value: 'en-GB',
    },
    {
      text: this.intl.t(
        'operator.workflows.visual-builder.hold-and-assign-editor.exit-tab.audio-snippet-editor.french',
      ),
      value: 'fr-FR',
    },
    {
      text: this.intl.t(
        'operator.workflows.visual-builder.hold-and-assign-editor.exit-tab.audio-snippet-editor.german',
      ),
      value: 'de-DE',
    },
    {
      text: this.intl.t(
        'operator.workflows.visual-builder.hold-and-assign-editor.exit-tab.audio-snippet-editor.spanish',
      ),
      value: 'es-ES',
    },
    {
      text: this.intl.t(
        'operator.workflows.visual-builder.hold-and-assign-editor.exit-tab.audio-snippet-editor.portuguese',
      ),
      value: 'pt-PT',
    },
    {
      text: this.intl.t(
        'operator.workflows.visual-builder.hold-and-assign-editor.exit-tab.audio-snippet-editor.br-portuguese',
      ),
      value: 'pt-BR',
    },
  ];

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);

    this.selectedTTSMode = this.args.step.ttsMode || this.ttsModes[0].value;
    this.selectedLocale = this.args.step.ttsLanguage || this.locales[0].value;
    this.blocks = this.args.step.blocks || [];
  }

  get app() {
    return this.appService.app;
  }

  get canClickToEdit() {
    return !this.args.readOnly && !this.isEditing;
  }

  get isEditing() {
    return true;
  }

  @action
  updateTTSMode(ttsMode: string) {
    this.blocks = new Array<Block>();
    this.args.step.blocks = new Array<Block>();
    this.selectedTTSMode = ttsMode;
    this.args.step.ttsMode = ttsMode;
  }

  @action
  updateLocale(locale: string) {
    this.selectedLocale = locale;
    this.args.step.ttsLanguage = locale;
  }

  @action
  updateBlocks(blocksDoc: Array<Block>) {
    this.blocks = blocksDoc;
    this.args.step.blocks = blocksDoc;
    this.args.step.notifyPropertyChange('blocks');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::AudioSnippetEditor': typeof AudioSnippetEditor;
    'workflows/graph-editor/node-items/steps/audio-snippet-editor': typeof AudioSnippetEditor;
  }
}
