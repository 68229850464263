/* import __COLOCATED_TEMPLATE__ from './note.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import type IntlService from 'embercom/services/intl';
import type Note from 'embercom/models/operator/visual-builder/step/note';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

interface Arguments {
  step: Note;
  readOnly: boolean;
  editorState: EditorState;
  placeholder?: string;
}

export default class NoteNodeItem extends Component<Arguments> {
  @service intl!: IntlService;
  @service attributeService: any;
  @service declare appService: any;
  @service declare contentEditorService: any;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);

    if (this.args.editorState.focusableObject === this.args.step) {
      this.startEditing();
    }
  }

  get app() {
    return this.appService.app;
  }

  get inserters() {
    let inserters = [
      {
        name: 'emoji',
        icon: 'lwr-happy',
        descriptor: this.intl.t(
          'operator.workflows.visual-builder.chat-message-inserter.add-emoji',
        ),
        hasPopoverContentComponent: true,
      },
    ];
    if (this.app.teammateConversationGifsEnabled) {
      inserters.insertAt(1, {
        name: 'gif',
        icon: 'gif',
        descriptor: this.intl.t('operator.workflows.visual-builder.chat-message-inserter.add-gif'),
        hasPopoverContentComponent: true,
      });
    }
    if (this.app.teammateConversationAttachmentsEnabled) {
      inserters.push({
        name: 'image',
        icon: 'picture',
        descriptor: this.intl.t(
          'operator.workflows.visual-builder.chat-message-inserter.add-image',
        ),
        hasPopoverContentComponent: false,
      });
      inserters.push({
        name: 'attachment',
        icon: 'attachment',
        descriptor: this.intl.t(
          'operator.workflows.visual-builder.chat-message-inserter.add-attachment',
        ),
        hasPopoverContentComponent: false,
      });
    }
    return inserters;
  }

  get canClickToEdit() {
    return !this.args.readOnly && !this.isEditing;
  }

  get isEditing() {
    return this.args.editorState.activeComposerStep === this.args.step;
  }

  get shouldShowError() {
    return (
      this.args.editorState.shouldShowValidations &&
      // Chat message validations live under the 'blocks' key. If a step uses a chat message editor in its template,
      // they must import the chat message blocks validations and add them to their model validations.
      !this.args.step.validations.attrs.blocks.isValid
    );
  }

  get isPlaceholderChatMessage() {
    return (
      // in edit mode, show the placeholder whenever the first block is an empty paragraph
      (!this.args.readOnly && this._startsWithEmptyParagraph()) ||
      // in read only mode, only show the placeholder when a step has one single empty text block
      (this.args.readOnly &&
        this.args.step.serializedBlocks.length === 1 &&
        this._startsWithEmptyParagraph())
    );
  }

  get placeholderBlock() {
    let placeholderText = this.args.placeholder
      ? this.args.placeholder
      : this.intl.t('operator.workflows.visual-builder.note-placeholder');

    return { text: placeholderText, type: 'paragraph' };
  }

  @action
  startEditing() {
    this.args.editorState.activeComposerStep = this.args.step;
  }

  _startsWithEmptyParagraph() {
    return (
      this.args.step.serializedBlocks.length &&
      this.args.step.serializedBlocks.firstObject.type === 'paragraph' &&
      !this.args.step.serializedBlocks.firstObject.text
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Note': typeof NoteNodeItem;
    'workflows/graph-editor/node-items/steps/note': typeof NoteNodeItem;
  }
}
