/* import __COLOCATED_TEMPLATE__ from './discovery-banner.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import templateOnlyComponent from '@ember/component/template-only';

const DiscoveryBanner = templateOnlyComponent();
export default DiscoveryBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Setup::DiscoveryBanner': typeof DiscoveryBanner;
    'workflow-connector/setup/discovery-banner': typeof DiscoveryBanner;
  }
}
