/* import __COLOCATED_TEMPLATE__ from './custom-object-selector-v2.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class CustomObjectSelectorV2 extends Component {
  @service customObjectsService;

  get step() {
    return this.args.step;
  }

  get selectedCustomObjectTypeIdentifier() {
    return this.step.objectTypeForButtonGeneration;
  }

  get dropdownItemsForAttributes() {
    let selectedObjectType;
    if (this.selectedCustomObjectType) {
      selectedObjectType = this.selectedCustomObjectType;
    } else {
      return [];
    }
    let dropdownItems = selectedObjectType.filteredAttributeDescriptors
      .reject(
        (attributeDescriptor) =>
          attributeDescriptor.isRelationshipDataType || attributeDescriptor.archived,
      )
      .map((attributeDescriptor) => {
        return {
          text: attributeDescriptor.name,
          value: attributeDescriptor.id,
          componentAttrs: {
            customObjectType: selectedObjectType.name,
          },
          component: 'operator-flows/editor/custom-object-selector/attribute-descriptor-option',
        };
      });
    return dropdownItems;
  }

  get selectedAttributeDescriptorLabel() {
    if (this.step.objectTypeDisplayAttributeId) {
      let selectedAttributeDescriptor = this.dropdownItemsForAttributes.findBy(
        'value',
        this.step.objectTypeDisplayAttributeId,
      );
      return selectedAttributeDescriptor ? selectedAttributeDescriptor.text : undefined;
    }
  }

  get selectedObjectTypeLabel() {
    if (this.selectedCustomObjectType) {
      return this.selectedCustomObjectType.name;
    }
  }

  get selectedCustomObjectType() {
    if (this.selectedCustomObjectTypeIdentifier) {
      return this.customObjectsService.findCustomObjectTypeByIdentifier(
        this.selectedCustomObjectTypeIdentifier,
      );
    }
  }
}
