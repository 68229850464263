/* import __COLOCATED_TEMPLATE__ from './fake-edge.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { captureException } from 'embercom/lib/sentry';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { action } from '@ember/object';

interface Signature {
  Element: HTMLElement;
  Blocks: {
    default: [];
  };
  Args: {
    connectionPoint: ConnectionPoint;
    editorState: EditorState;
  };
}

export default class FakeEdge extends Component<Signature> {
  get graph() {
    return this.args.editorState.layout.graph;
  }

  get isMouseAtConnectedEdge() {
    let edge = this.edge;

    if (!edge) {
      return false;
    }

    return edge.isMouseAtEdge;
  }

  get isSelected() {
    let edge = this.edge;

    if (!edge) {
      return false;
    }

    return edge.isSelected || edge.predecessor?.isSelected || edge.successor?.isSelected;
  }

  @action onMouseEnter() {
    let edge = this.edge;

    if (!edge) {
      return;
    }

    this.graph.state.setMouseAtEdge(edge);
  }

  @action onMouseLeave() {
    let edge = this.edge;

    if (!edge) {
      return;
    }

    this.graph.state.removeMouseFromEdge();
  }

  private get edge() {
    if (!this.args.connectionPoint.isConnected) {
      return null;
    }

    let edge = this.graph.edgeForDataObject(this.args.connectionPoint.edge!);

    try {
      if (!edge) {
        throw new Error('No Edge found');
      }
      return edge;
    } catch (e) {
      captureException(e, {
        extra: {
          edgeDataObjects: this.graph.edges.map((e) => e.dataObject?.serialize()),
          edgeModel: this.args.connectionPoint.edge?.serialize(),
          edgeIsSelected: edge?.isSelected,
          predecessorIsSelected: edge?.predecessor?.isSelected,
          sucessorIsSelected: edge?.successor?.isSelected,
          workflow:
            this.args.connectionPoint.edge?.fromGroup?.workflow?.serialize({ sanitize: true }) ||
            this.args.connectionPoint.edge?.toGroup?.workflow?.serialize({ sanitize: true }),
        },
        tags: {
          component: 'visual-builder',
        },
      });
      return null;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::FakeEdge': typeof FakeEdge;
    'workflows/graph-editor/fake-edge': typeof FakeEdge;
  }
}
