/* import __COLOCATED_TEMPLATE__ from './else-branch.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { type default as GraphEditorNode } from 'graph-editor/models/graph-editor/node';

import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type Group from 'embercom/models/operator/visual-builder/group';
import type ConditionalBranchesConfiguration from 'embercom/objects/visual-builder/configuration/step/conditional-branches';

type ElseBranchArgs = {
  branch: ConnectionPoint;
  editorState: EditorState;
  readOnly: boolean;
  index: number;
  updateConnectionPoints: (node: GraphEditorNode<Group>) => void;
  connectionPointComponent: Component;
  stepConfig: ConditionalBranchesConfiguration;
};

export default class ElseBranch extends Component<ElseBranchArgs> {
  get graph() {
    return this.args.editorState.layout.graph;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ConditionalBranches::ElseBranch': typeof ElseBranch;
    'workflows/graph-editor/node-items/steps/conditional-branches/else-branch': typeof ElseBranch;
  }
}
