/* import __COLOCATED_TEMPLATE__ from './set-ticket-state.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ListItemComponent extends Component {
  @service intl;

  getTicketStateName(ticketStateId) {
    let ticketStates = {
      0: this.intl.t('inbox.ticket-state.submitted'),
      32: this.intl.t('inbox.ticket-state.in_progress'),
      64: this.intl.t('inbox.ticket-state.waiting_on_customer'),
      96: this.intl.t('inbox.ticket-state.resolved'),
    };

    return ticketStates[ticketStateId];
  }

  get description() {
    let ticketStateId = this.args.action.actionData.ticket_state_id;
    let ticketStateName = this.getTicketStateName(ticketStateId);

    return this.intl.t('settings.inbox-rules.actions.set-ticket-state.description', {
      ticketStateName,
    });
  }
}
