/* import __COLOCATED_TEMPLATE__ from './pre-handover-messages-viewer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    preHandoverAnswerEnabled: boolean;
  };
}

const PreHandoverMessagesViewer = templateOnlyComponent<Signature>();
export default PreHandoverMessagesViewer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Fin::PreHandoverMessagesViewer': typeof PreHandoverMessagesViewer;
  }
}
