/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import type Callback from 'embercom/models/operator/visual-builder/step/callback';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Arguments {
  step: Callback;
  isReadOnly: boolean;
  editorState: EditorState;
}

export default class CallbackEditor extends Component<Arguments> {
  @service declare store: Store;

  teamAssignmentAction: any;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);

    this.teamAssignmentAction = this.store.createFragment('rules/rule-action', {
      type: 'assign-conversation',
      actionData: {
        skip_assigned: false,
        assignee_id: this.args.step.teamId,
      },
    });
  }

  @action
  onChange() {
    this.args.step.teamId = this.teamAssignmentAction.actionData.assignee_id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Callback::Editor': typeof CallbackEditor;
    'workflows/graph-editor/node-items/steps/callback/editor': typeof CallbackEditor;
  }
}
