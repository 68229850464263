/* import __COLOCATED_TEMPLATE__ from './step.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type default as StepModel } from 'embercom/models/operator/visual-builder/step';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';
import type { Signature as OutwardConnectorSignature } from './outward-connector';
import { type ComponentLike } from '@glint/template';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type PathConfig from 'embercom/objects/visual-builder/configuration/path';
import { type Yieldable } from 'ember-concurrency';

interface Args {
  step: StepModel;
  editorConfiguration: EditorConfig;
  isSortingDisabled?: boolean;
  sortableGroup: $TSFixMe;
  readOnly: boolean;
  stepIndex: number;
  connectionPointComponent: ComponentLike<OutwardConnectorSignature>;
  editorState: EditorState;
  pathConfig: PathConfig;
  updateConnectionPoints: (node: Node) => Generator<Yieldable<void>, void, unknown>;
}

interface Signature {
  Args: Args;
}

export default class Step extends Component<Signature> {
  @service declare appService: any;

  get stepConfig() {
    return this.args.editorConfiguration.generateStepConfig({
      step: this.args.step,
      pathConfig: this.args.pathConfig,
    });
  }

  get requiredBillingFeature() {
    return (this.args.step.constructor as typeof StepModel).requiredBillingFeature || '';
  }

  get isPaywalled() {
    return (
      this.args.editorConfiguration.areStepPaywallsActive &&
      this.requiredBillingFeature &&
      !this.appService.app.canUseFeature(this.requiredBillingFeature)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::Node::Step': typeof Step;
    'workflows/graph-editor/node/step': typeof Step;
  }
}
