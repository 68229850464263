/* import __COLOCATED_TEMPLATE__ from './note-composer-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { BROWSER_SUPPORTS_TOUCH } from 'embercom/lib/screen-awareness';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { schedule } from '@ember/runloop';

export default Component.extend({
  appService: service(),
  admin: readOnly('appService.app.currentAdmin'),

  classNames: ['profile__notes-composer'],
  classNameBindings: ['isSaving:a__note-composer-saving'],
  isSaving: false,
  focused: false,
  noteHasValue: false,
  focusIn() {
    schedule('afterRender', () => {
      this.set('focused', true);
    });
  },
  focusOut() {
    schedule('afterRender', () => {
      this.set('focused', false);
    });
  },
  isTouch: BROWSER_SUPPORTS_TOUCH,
  actions: {
    save() {
      this.noteComposerTextarea.send('save');
    },
    cancel() {
      this.noteComposerTextarea.send('cancel');
    },
  },
});
