/* import __COLOCATED_TEMPLATE__ from './send-ticket.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';
import type AnswerTerminal from 'embercom/models/operator/visual-builder/step/answer-terminal';

interface Args {
  step: AnswerTerminal;
  readOnly: boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const SendTicket = templateOnlyComponent<Signature>();
export default SendTicket;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::AnswerTerminal::SendTicket': typeof SendTicket;
  }
}
