/* import __COLOCATED_TEMPLATE__ from './integration-action.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';

export default class IntegrationAction extends Component {
  @service store;
  @service router;
  @service appService;
  @service attributeService;
  @service intl;

  @tracked attributeNamesAndIcons = {};

  constructor() {
    super(...arguments);
    this.getAttributeNamesAndIcons();
  }

  get app() {
    return this.appService.app;
  }

  get fallbackBranch() {
    return this.args.step.outwardConnectionPoints.firstObject;
  }

  getAttributeNamesAndIcons() {
    this.allAttributes.forEach(
      (attribute) =>
        (this.attributeNamesAndIcons[attribute.identifier] = {
          name: attribute.name,
          icon: this.icon(attribute),
        }),
    );
    this.resolver.eventAttributes.forEach(
      (attribute) =>
        (this.attributeNamesAndIcons[attribute.templatable_identifier] = {
          name: attribute.name,
          icon: this.icon(attribute),
        }),
    );
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.allAttributes,
      includeAppAttributes: true,
      selectedEvents: this.selectedEvents,
    });
  }

  get allAttributes() {
    return [...this.attributeService.allAttributes, ...this.resolver.manualAppAttributes];
  }

  get attributes() {
    return this.attributeService.userEditorMetadataAndConversationAttributes;
  }

  get requestAttributes() {
    return this.args.step.action.requestAttributeIdentifiersArray
      .filter((identifier) => identifier in this.attributeNamesAndIcons)
      .map((identifier) => this.attributeNamesAndIcons[identifier]);
  }

  get responseAttributes() {
    return this.args.step.action.responseAttributeIdentifiersArray
      .filter((identifier) => identifier in this.attributeNamesAndIcons)
      .map((identifier) => this.attributeNamesAndIcons[identifier]);
  }

  icon(attributeOrDescriptor) {
    let type = attributeOrDescriptor.dataType
      ? attributeOrDescriptor.dataType
      : attributeOrDescriptor.type;

    switch (type) {
      case 'string':
        return 'recipient-data';
      case 'integer':
        return 'count';
      case 'decimal':
        return 'decimal';
      case 'boolean':
        return 'boolean';
      case 'datetime':
        return 'calendar';
      case 'list':
        return 'list';
      case 'relationship':
        return 'transfer';
      default:
        return 'insert-data';
    }
  }

  get shouldShowOutputError() {
    return (
      this.args.editorState.shouldShowValidations &&
      !this.args.step.validations.attrs.outwardConnectionPoints.isValid
    );
  }
}
