/* import __COLOCATED_TEMPLATE__ from './user-map-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import MapComponent from 'embercom/components/map-component';

export default MapComponent.extend({
  inUserList: true,
  elementID: 'MapBox',
  classNames: ['pl_map'],
});
