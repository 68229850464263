/* import __COLOCATED_TEMPLATE__ from './apply-conversation-sla.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ListItemComponent extends Component {
  @service appService;
  @service store;
  @service intl;
  @tracked action = this.args.action;
  @tracked actionContext = this.args.actionContext;

  get description() {
    return this.intl.t('settings.inbox-rules.index.actions.apply-conversation-sla.description', {
      slaName: this.sla(),
    });
  }

  sla() {
    let slaId = this.args.action.actionData.conversation_sla_id;
    let sla;
    if (slaId) {
      sla = this.args.actionContext.conversationSlas.findBy('id', slaId);
    }
    if (sla) {
      return sla.name;
    }
  }
}
