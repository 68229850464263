/* import __COLOCATED_TEMPLATE__ from './wait.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Wait from 'embercom/models/operator/visual-builder/step/wait';

interface Arguments {
  step: Wait;
  readOnly: boolean;
  editorState: any;
}

export default class WaitComponent extends Component<Arguments> {
  @service intl!: IntlService;

  get title() {
    if (this.args.step.durationType === 'custom') {
      let formatKey = this.determineCustomFormatKey();
      return this.intl.t(
        `operator.workflows.visual-builder.wait.duration-formatting.formats.${formatKey}`,
        {
          days: this.args.step.days,
          hours: this.args.step.hours,
          minutes: this.args.step.minutes,
          seconds: this.args.step.seconds,
          htmlSafe: true,
        },
      );
    } else {
      return this.intl.t(
        `operator.workflows.visual-builder.wait.duration-formatting.formats.${this.args.step.durationType}`,
        {
          htmlSafe: true,
        },
      );
    }
  }

  determineCustomFormatKey() {
    let formatKey = '';

    if (this.args.step.days > 0) {
      formatKey += 'd';
    }
    if (this.args.step.hours > 0) {
      formatKey += 'h';
    }
    if (this.args.step.minutes > 0) {
      formatKey += 'm';
    }
    if (this.args.step.seconds > 0) {
      formatKey += 's';
    }

    if (formatKey === '') {
      formatKey = 'empty';
    }

    return formatKey;
  }

  get hint() {
    if (this.args.step.interruptMode === 'admin_comments') {
      return this.intl.t('operator.workflows.visual-builder.wait.hint.admin-comments', {
        htmlSafe: true,
      });
    } else if (this.args.step.interruptMode === 'admin_and_user_comments') {
      return this.intl.t('operator.workflows.visual-builder.wait.hint.admin-and-user-comments', {
        htmlSafe: true,
      });
    } else {
      throw new Error(`Unknown interruptMode: ${this.args.step.interruptMode}`);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Wait': typeof WaitComponent;
    'workflows/graph-editor/node-items/steps/wait': typeof WaitComponent;
  }
}
