/* import __COLOCATED_TEMPLATE__ from './remove-tag-from-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  action: $TSFixMe;
  truncateText: boolean;
}

const RemoveTagFromConversationAction = templateOnlyComponent<Signature>();
export default RemoveTagFromConversationAction;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ApplyRules::Actions::RemoveTagFromConversation': typeof RemoveTagFromConversationAction;
    'workflows/graph-editor/node-items/steps/apply-rules/actions/remove-tag-from-conversation': typeof RemoveTagFromConversationAction;
  }
}
