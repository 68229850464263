/* import __COLOCATED_TEMPLATE__ from './read-only.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';

interface ReadOnlySignature {
  Element: HTMLDivElement;
  Args: {};
}

const ReadOnly = templateOnlyComponent<ReadOnlySignature>();

export default ReadOnly;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::PlaceholderNodeContent::ReadOnly': typeof ReadOnly;
    'workflows/graph-editor/placeholder-node-content/read-only': typeof ReadOnly;
  }
}
