/* import __COLOCATED_TEMPLATE__ from './sidebar-header-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { notEmpty, lt, and } from '@ember/object/computed';
import { computed } from '@ember/object';
import { A } from '@ember/array';
import Component from '@ember/component';
import AvatarAndMapSearch from 'embercom/components/mixins/avatar-and-map-search';
import { inject as service } from '@ember/service';

export default Component.extend(AvatarAndMapSearch, {
  store: service(),
  classNames: ['pl_profile_sidebar_header'],
  isLoading: true,
  init() {
    this._super(...arguments);
    this.set('users', A());
  },
  didInsertElement() {
    this._super(...arguments);
    this.loadPage();
  },
  predicate: computed('userOrCompany', function () {
    return {
      attribute: 'company.remote_company_id',
      comparison: 'eq',
      type: 'string',
      value: `${this.get('userOrCompany.remote_company_id')}`,
    };
  }),
  hasMappableUsers: notEmpty('mappableUsers'),
  hasFewEnoughUsersToDisplayMap: lt('users.length', 7),
  shouldShowMap: and('hasMappableUsers', 'hasFewEnoughUsersToDisplayMap'),

  mappableUsers: computed('users.[]', function () {
    return this.users.filter(this.userHasLatitudeAndLongtitude);
  }),
  userHasLatitudeAndLongtitude(user) {
    return (
      typeof user.get === 'function' &&
      user.get('geoip_data') &&
      user.get('geoip_data.latitude') &&
      user.get('geoip_data.longitude')
    );
  },
  loadPage() {
    let app = this.app;
    let predicates = [this.predicate];
    this.avatarAndMapSearch(app, predicates, 1).then((response) => {
      this.set('isLoading', false);
      if (response.users) {
        this.store.pushPayload({ users: response.users });
        let userModels = response.users
          .map((user) => this.store.peekRecord('user', user.id))
          .compact();
        this.users.pushObjects(userModels);
      }
    });
  },
});
