/* import __COLOCATED_TEMPLATE__ from './branch-side-sheet-header.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';

interface BranchSideSheetHeaderArgs {
  branch: ConnectionPoint;
}

export default class BranchSideSheetHeader extends Component<BranchSideSheetHeaderArgs> {
  @action
  onFocusOut() {
    this.args.branch.label = this.args.branch.label.trim();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ConditionalBranches::BranchSideSheetHeader': typeof BranchSideSheetHeader;
    'workflows/graph-editor/node-items/steps/conditional-branches/branch-side-sheet-header': typeof BranchSideSheetHeader;
  }
}
