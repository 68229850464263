/* import __COLOCATED_TEMPLATE__ from './user-profile-note-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { schedule } from '@ember/runloop';
import $ from 'jquery';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  appService: service(),
  isEditing: false,
  attributeBindings: ['data-test-user-profile-note'],
  'data-test-user-profile-note': true,

  focusIn() {
    this.set('focused', true);
  },
  focusOut() {
    this.set('focused', false);
  },

  actions: {
    clickNote() {
      this.set('isEditing', true);
      schedule('afterRender', this, () => {
        $('textarea', this.element).focus();
      });
    },
    cancelEditing() {
      this.noteComposerTextareaComponent.send('cancel');
    },
    save() {
      this.noteComposerTextareaComponent.send('save');
    },
  },
});
