/* import __COLOCATED_TEMPLATE__ from './show-on-show-until.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { dasherize } from '@ember/string';
import toSentence from 'embercom/lib/to-sentence';
import { preferredDevices as preferredDevicesMap } from 'embercom/models/data/outbound/constants';

type ShowOnShowUntilArgs = {
  customBot: any;
  readOnly: boolean;
};

export default class ShowOnShowUntil extends Component<ShowOnShowUntilArgs> {
  @service declare contentEditorService: any;
  @service declare intl: IntlService;

  get showUntilLabel() {
    return this.intl.t(
      'operator.workflows.visual-builder.trigger-info-node-content.show-until-label',
      {
        object: this.contentEditorService.configRulesetLink.humanReadableObjectName,
      },
    );
  }

  get showUntilValue() {
    let showUntilValue = this.contentEditorService.activeObject?.showUntil;

    if (!showUntilValue) {
      return;
    }

    return this.intl.t(
      `operator.workflows.visual-builder.trigger-info-node-content.show-until-value.${dasherize(
        showUntilValue,
      )}`,
    );
  }

  get preferredDevices() {
    if (!this.args.customBot?.preferredDevices) {
      return;
    }

    let selected = this.args.customBot?.preferredDevices;

    let devicesMap = Object.entries(preferredDevicesMap).reduce(
      (currentMap, [key, value]) => {
        return { ...currentMap, [value]: key };
      },
      {} as Record<number, string>,
    );

    return toSentence(
      selected.map((v: number) => {
        return this.intl.t(
          `operator.workflows.visual-builder.trigger-info-node-content.show-first-on-value.${devicesMap[v]}`,
        );
      }),
      'and',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::TriggerInfoNode::ShowOnShowUntil': typeof ShowOnShowUntil;
    'workflows/graph-editor/trigger-info-node/show-on-show-until': typeof ShowOnShowUntil;
  }
}
