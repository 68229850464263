/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import type WorkflowConnector from 'embercom/models/operator/visual-builder/step/workflow-connector';
import type WorkflowConnectorConfiguration from 'embercom/objects/visual-builder/configuration/step/workflow-connector';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { post } from 'embercom/lib/ajax';
import { dropTask } from 'ember-concurrency-decorators';
import _ from 'underscore';
import type JiraWorkflowActionParam from 'embercom/models/workflow-connector/jira-workflow-action-param';
import type WorkflowActionParam from 'embercom/models/workflow-connector/workflow-action-param';

interface Arguments {
  step: WorkflowConnector;
  hideDrawer: () => void;
  stepConfig: WorkflowConnectorConfiguration;
}

export default class Settings extends Component<Arguments> {
  @service store: any;
  @service declare appService: any;
  @service notificationsService: any;
  @service intl: any;

  @tracked declare errorFields: any;
  @tracked declare showErrorBanner: boolean;

  @tracked declare workflowActionParams: any[];

  @dropTask
  *saveCustomActionAndCloseDrawer() {
    let action_name = this.args.step.action!.name;
    this.showErrorBanner = false;
    this.errorFields = {};
    let requestBody = '';
    this.workflowActionParams = this.store.peekAll('workflow-connector/jira-workflow-action-param');

    try {
      if (action_name.includes('GitHub')) {
        this.workflowActionParams = this.store.peekAll('workflow-connector/workflow-action-param');
        requestBody = JSON.stringify(this.gitHubWorkflowActionJson);
      } else if (action_name === 'Jira Create Issue') {
        requestBody = this.jiraCreateIssueWorkflowActionJson;
      } else if (action_name === 'Jira Create Comment') {
        requestBody = JSON.stringify(this.jiraCreateCommentWorkflowActionJson);
      } else {
        requestBody = '';
      }

      if (!_.isEmpty(this.errorFields)) {
        return;
      }

      yield post(
        `/ember/workflow_connector/insertable_actions/${this.args.step.action?.id}/update_from_workflow`,
        {
          app_id: this.appService.app.id,
          body: requestBody,
        },
      ).then((response: any) => {
        let serialize = this.store.serializerFor('workflow-connector/insertable-action');
        let model = this.store.modelFor('workflow-connector/insertable-action');
        let data = serialize.normalize(model, response);
        this.args.step.set('action', this.store.push(data));
      });
      this.args.hideDrawer();
    } catch (error) {
      this.notificationsService.notifyError(error);
    }
  }

  get gitHubWorkflowActionJson() {
    return {
      Origin: 'Web',
      ...this.flattenMappingFields(),
    };
  }

  get jiraCreateCommentWorkflowActionJson() {
    return {
      body: this.workflowActionParams.find((param: any) => param.key === 'comment')
        ?.constructTextAreaField,
    };
  }

  get jiraCreateIssueWorkflowActionJson() {
    let jiraCreateIssueWorkflowActionFields = JSON.stringify({
      fields: {
        ...this.jiraIssueFields,
      },
    });
    return jiraCreateIssueWorkflowActionFields
      .split('"%float%')
      .join('')
      .split('%float%"')
      .join('');
  }

  updateErrorFields(field: JiraWorkflowActionParam) {
    if (!field) {
      return;
    }

    if (field.required && field.isEmptyFieldValue) {
      this.showErrorBanner = true;
      if (!field.humanName) {
        this.errorFields[field.key] = this.intl.t(field.displayedTitle);
      } else if (field.key === field.humanName.toLowerCase()) {
        this.errorFields[field.key] = field.humanName;
      } else {
        this.errorFields[field.key] = `${field.humanName} (${field.key})`;
      }
    }
  }

  get jiraIssueFields() {
    let fields: { [key: string]: any } = {};
    this.workflowActionParams.forEach((field: JiraWorkflowActionParam) => {
      this.updateErrorFields(field);

      if (field.isEmptyFieldValue || field.key === 'customFields') {
        return;
      }
      fields[field.key.toLowerCase()] = field.constractedValue;
    });
    return fields;
  }

  flattenMappingFields() {
    return this.workflowActionParams.reduce((arc: any, param: WorkflowActionParam) => {
      if (param.isArray) {
        if (param.value && _.isString(param.value)) {
          return {
            ...arc,
            [param.key]: param.value?.split(',').map((s: string) => s.trim()),
          };
        } else {
          return { ...arc };
        }
      } else {
        return {
          ...arc,
          [param.key]: param.value,
        };
      }
    }, {});
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::CustomAction::Settings': typeof Settings;
    'workflows/graph-editor/node-items/steps/custom-action/settings': typeof Settings;
  }
}
