/* import __COLOCATED_TEMPLATE__ from './messenger-app.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class MessengerApps extends Component {
  @service store;

  @action
  addMessengerApp(cardJSON, _app, cardCreationParams) {
    this.args.step.cardCreationParams = cardCreationParams;
    this.args.step.previewCanvas = cardJSON.canvas;
    this.args.step.previewMessengerCardUri = cardJSON.uri;
  }

  @action
  removeStep() {
    if (!this.args.step.cardCreationParams?.messenger_app_id) {
      this.args.editorState.deleteStep(this.args.step);
    }
  }

  get messengerApp() {
    return this.store.peekRecord(
      'messenger-app',
      this.args.step.cardCreationParams.messenger_app_id,
    );
  }

  get shouldShowError() {
    return this.args.editorState.shouldShowValidations && !this.args.step.validations.isValid;
  }
}
