/* import __COLOCATED_TEMPLATE__ from './data-field.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DataField extends Component {
  @service attributeService;

  get pathConfig() {
    return this.args.step.stepConfig?.pathConfig;
  }

  get attributeGroupList() {
    if (this.pathConfig) {
      return this.pathConfig.collectableAttributesGroupList;
    }
    return this.attributeService.botAutoMessageCollectableAttributeGroupList;
  }

  @action selectAttribute(attribute) {
    let attributeLabel = attribute.get('name');
    let attributeIdentifier = attribute.get('identifier');
    let attributeDataType = attribute.get('type');
    let isIdentifyingAttribute =
      this.attributeService.userIdentifyingAndNonEditableAttributes.includes(attributeIdentifier);

    this.args.step.attributeCollectionOverwritable = !isIdentifyingAttribute;
    this.args.step.attributeCollectionDisabled = isIdentifyingAttribute;
    this.args.step.attributeIdentifier = attributeIdentifier;
    this.args.step.attributeLabel = attributeLabel;
    this.args.step.attributeDataType = attributeDataType;
  }

  @action createLocalVariable(name, type) {
    let attribute = this.args.editorState.createLocalVariable(name, type);
    this.selectAttribute(attribute);
  }

  @action deleteLocalVariable(attribute) {
    this.args.editorState.deleteLocalVariable(attribute);
  }
}
