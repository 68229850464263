/* import __COLOCATED_TEMPLATE__ from './links.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { tagFilterUrl } from 'embercom/vendor/intercom/tag-filter-url';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';

export default Component.extend({
  tag: readOnly('row'),
  usersUrl: computed('tag', function () {
    return tagFilterUrl(this.tag, 'user');
  }),
  companiesUrl: computed('tag', function () {
    return tagFilterUrl(this.tag, 'company');
  }),
  conversationsUrl: computed('tag', function () {
    return tagFilterUrl(this.tag, 'comment');
  }),
});
