/* import __COLOCATED_TEMPLATE__ from './created-by.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed, default as EmberObject } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  intl: service(),
  appService: service(),
  store: service(),
  app: readOnly('appService.app'),
  unknownTeammate: computed('intl.locale', 'app', function () {
    return EmberObject.create({
      displayAs: this.intl.t('components.visibility.tags.table-cells.created-by.unknown-teammate', {
        appName: this.get('app.name'),
      }),
      avatarData: {
        isTeammate: true,
      },
    });
  }),
  teammate: computed('app', 'row.created_by', 'unknownTeammate', function () {
    // when admins are deleted, their tags remain so to avoid unnecessary network requests
    // for deleted admins we are fetching directly from the store here.
    let id = this.row.belongsTo('createdBy').id();
    if (id) {
      let admin = this.store.peekRecord('admin', id);
      if (admin) {
        return admin;
      }
    }
    return this.unknownTeammate;
  }),
  displayName: computed('intl.locale', 'teammate', function () {
    return this.get('teammate.id') === this.get('app.currentAdmin.id')
      ? this.intl.t('components.visibility.tags.table-cells.created-by.you')
      : this.get('teammate.displayAs');
  }),
});
