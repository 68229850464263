/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { TrackedMap, tracked } from 'tracked-built-ins';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { task } from 'ember-concurrency-decorators';

import BrowserLocales from 'embercom/lib/browser-locales';

export default class WhatsappEditorComponent extends Component {
  @service attributeService;
  @service appService;
  @service contentEditorService;
  @service notificationsService;
  @service store;
  @service intl;

  @tracked blocksDoc;
  @tracked whatsappContent;
  @tracked whatsappIntegrations = [];
  @tracked templatesByWaba = new TrackedMap(); // Different message templates exist for each WhatsApp Business Account

  constructor() {
    super(...arguments);

    this.fetchData.perform();
    this.blocksDoc = new BlocksDocument(this.serializedBlocks);
    this.whatsappContent = this.localizedWhatsappMessageContent;
  }

  get integrationsItems() {
    let items = this.whatsappIntegrations.map((integration) => {
      return { text: integration.displayString, value: integration.id };
    });
    let defaultLabel = this.intl.t('outbound.whatsapp.editor.integration-select-label');
    return [{ text: defaultLabel, value: null }, ...items];
  }

  get objectIntegration() {
    return this.whatsappIntegrations.find((integration) => {
      return integration.id === this.args.whatsapp.whatsappIntegrationId?.toString();
    });
  }

  get templatesItems() {
    let names =
      this.templatesByWaba.get(this.objectIntegration?.whatsappAccountId)?.map((template) => {
        return template.name;
      }) || [];
    let items = [...new Set(names)].map((name) => {
      return { text: name, value: name };
    });
    let defaultLabel = this.intl.t('outbound.whatsapp.editor.template-select-label');
    return [{ text: defaultLabel, value: null }, ...items];
  }

  get languagesItems() {
    let templates = this.templatesByWaba.get(this.objectIntegration?.whatsappAccountId) || [];
    let languages = templates
      .filter((template) => template.name === this.args.whatsapp.templateName)
      .map((template) => template.language);
    let uniqueLanguages = [...new Set(languages)];
    let items = uniqueLanguages.map((language) => {
      return { text: this.languageNameFromCode(language), value: language };
    });
    let defaultLabel = this.intl.t('outbound.whatsapp.editor.language-select-label');
    return [{ text: defaultLabel, value: null }, ...items];
  }

  get initialSelectedIntegration() {
    if (this.findInItems(this.integrationsItems, this.args.whatsapp.whatsappIntegrationId)) {
      return this.args.whatsapp.whatsappIntegrationId?.toString();
    }
    return null;
  }

  get selectedTemplate() {
    if (this.findInItems(this.templatesItems, this.args.whatsapp.templateName)) {
      return this.args.whatsapp.templateName;
    }
    return null;
  }

  get selectedLanguage() {
    if (this.findInItems(this.languagesItems, this.args.whatsapp.locale)) {
      return this.args.whatsapp.locale;
    }
    return null;
  }

  @task({ drop: true })
  *fetchData() {
    this.whatsappIntegrations = yield this.store.findAll('whatsapp/integration', {
      no_external_updates: true,
    });
    for (let whatsappIntegration of this.whatsappIntegrations.toArray()) {
      if (!this.templatesByWaba.has(whatsappIntegration.whatsappAccountId)) {
        try {
          let templates = yield this.fetchTemplates.perform(whatsappIntegration.id);
          this.templatesByWaba.set(whatsappIntegration.whatsappAccountId, templates);
        } catch (error) {
          this.notifyResponseError(
            error,
            this.intl.t('outbound.whatsapp.editor.notification.template-fetch-error'),
          );
        }
      }
    }
    this.selectedMessageTemplate?.updateParameters(this.localizedWhatsappMessageContent.components);
  }

  @task({ drop: true })
  *fetchTemplates(whatsappIntegrationId) {
    let templates = yield this.store.query('whatsapp/message-template', {
      whatsapp_integration_id: whatsappIntegrationId,
    });
    return templates.filter(
      (template) => !template.unsupportedHeader && !template.unsupportedButtons,
    );
  }

  templatesByIntegration(whatsappIntegration) {
    return this.templatesByWaba
      .get(whatsappIntegration.whatsappAccountId)
      .filter((template) => template.name === name);
  }

  findInItems(items, value) {
    return items.find((item) => item.value?.toString() === value?.toString());
  }

  languageNameFromCode(language) {
    let languageCode = language.replace('_', '-');
    return BrowserLocales[languageCode] || language?.toUpperCase();
  }

  get selectedMessageTemplate() {
    let templates = this.templatesByWaba.get(this.objectIntegration?.whatsappAccountId) || [];
    return templates.find(
      (template) =>
        template.name === this.args.whatsapp.templateName &&
        template.language === this.args.whatsapp.locale,
    );
  }

  @action updateWhatsappBlocks() {
    let blocksDoc = new BlocksDocument([]);
    if (this.selectedMessageTemplate) {
      blocksDoc = new BlocksDocument(this.selectedMessageTemplate.blocksWithParameters);
    }
    this.localizedWhatsappMessageContent.jsonBlocks = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.blocksDoc = blocksDoc;
    this.localizedWhatsappMessageContent.notifyPropertyChange('jsonBlocks');
  }

  @action onIntegrationSelected(integrationId) {
    this.args.whatsapp.whatsappIntegrationId = integrationId;
    if (this.selectedTemplate === null) {
      this.args.whatsapp.templateName = null;
    }
    if (this.selectedLanguage === null) {
      this.args.whatsapp.locale = null;
    }
    this.updateWhatsappBlocks();
  }

  @action onTemplateSelected(templateName) {
    this.args.whatsapp.templateName = templateName;
    if (this.selectedLanguage === null) {
      this.args.whatsapp.locale = null;
    }
    this.updateWhatsappBlocks();
  }

  @action onLanguageSelected(language) {
    this.args.whatsapp.locale = language;
    this.updateWhatsappBlocks();
  }

  get serializedBlocks() {
    if (isPresent(this.localizedWhatsappMessageContent.jsonBlocks.serialize())) {
      return this.localizedWhatsappMessageContent.jsonBlocks.serialize();
    }
    return [
      { type: 'paragraph', text: `<i>${this.intl.t('outbound.whatsapp.editor.empty-blocks')}</i>` },
    ];
  }

  get selectedEvents() {
    let selectedEvent = this.contentEditorService.selectedEvent;
    return selectedEvent ? [selectedEvent] : [];
  }

  get seriesTriggerAttributes() {
    return this.contentEditorService.seriesTriggerAttributes;
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorAndMetadataAttributes,
      includeAppAttributes: true,
      selectedEvents: this.selectedEvents,
      transientAttributes: this.seriesTriggerAttributes,
    });
  }

  get localizedWhatsappMessageContent() {
    return this.args.whatsapp.localizedWhatsappMessageContents.firstObject;
  }

  get objectType() {
    return objectTypes.whatsapp;
  }

  @action
  updateBlocks(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.localizedWhatsappMessageContent.jsonBlocks = blockFragments;
    this.whatsappContent = this.localizedWhatsappMessageContent;
    this.blocksDoc = blocksDoc;
  }

  @action
  onTemplateParametersChange(components) {
    if (this.selectedMessageTemplate) {
      this.selectedMessageTemplate.updateParameters(components);
      this.localizedWhatsappMessageContent.components = components;
      this.updateWhatsappBlocks();
      this.whatsappContent = this.localizedWhatsappMessageContent;
    }
  }

  notifyResponseError(error, defaultMessage) {
    this.notificationsService.notifyResponseError(
      error,
      {
        default: defaultMessage,
      },
      {
        responseProvidedErrors: [403, 422, 404],
      },
    );
  }
}
