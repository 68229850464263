/* import __COLOCATED_TEMPLATE__ from './popover-menu-item.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type InserterMenuItemType } from 'embercom/objects/visual-builder/configuration/inserter-menu-items-map';

interface Args {
  item: InserterMenuItemType;
  shouldShowStepPaywalls?: boolean;
  onSelectItem: (value: InserterMenuItemType['value']) => void;
}

export default class PopoverMenuItem extends Component<Args> {
  @service declare appService: any;

  get requiredBillingFeatureForItem() {
    if (typeof this.args.item.value !== 'string') {
      return this.args.item.value.ModelClass?.requiredBillingFeature;
    }

    return false;
  }

  get shouldShowPaywallIcon() {
    return (
      this.args.shouldShowStepPaywalls &&
      this.requiredBillingFeatureForItem &&
      !this.appService.app.canUseFeature(this.requiredBillingFeatureForItem)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::PopoverMenu::PopoverMenuItem': typeof PopoverMenuItem;
  }
}
