/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import $ from 'jquery';
import InboundActions from 'ember-component-inbound-actions/inbound-actions';
import FilterLinkBuilder from 'embercom/lib/users/user-company-list/filter-link-builder';

export default Component.extend(InboundActions, {
  classNames: ['tbl__fixed__portal'],

  click(evt) {
    let metaKeyPressed = evt.shiftKey || evt.ctrlKey || evt.metaKey;
    if ($(evt.target).hasClass('js__user-list-link')) {
      FilterLinkBuilder.redirectToUrl($(evt.target), metaKeyPressed);
    }
  },

  actions: {
    highlightedItemChanged() {
      let $selected = $('.kbd_item_selected', this.element);
      let topPosition = $selected.position().top;
      if ($(this.element).height() < topPosition) {
        $selected[0].scrollIntoView(false);
      } else if (topPosition < 0) {
        $selected[0].scrollIntoView(true);
      }
    },
  },
});
