/* import __COLOCATED_TEMPLATE__ from './callback.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import templateOnlyComponent from '@ember/component/template-only';
import type { default as CallbackType } from 'embercom/models/operator/visual-builder/step/callback';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

export interface CallbackArgs {
  step: CallbackType;
  editorState: EditorState;
  readOnly: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: CallbackArgs;
}

const Callback = templateOnlyComponent<Signature>();
export default Callback;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Callback': typeof Callback;
    'workflows/graph-editor/node-items/steps/callback': typeof Callback;
  }
}
