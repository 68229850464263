/* import __COLOCATED_TEMPLATE__ from './default-assignee-component.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'embercom/services/intl';
import type MessagingSettings from '../../../models/messaging-settings';
import type Store from '@ember-data/store';

interface Signature {
  Args: {};
}

export default class DefaultAssigneeComponent extends Component<Signature> {
  @service declare appService: any;
  @service declare permissionsService: any;
  @service declare notificationsService: any;
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked messagingSettings: MessagingSettings | null = null;
  @tracked defaultAssigneeId: number = this.app.defaultAssignee.id;

  constructor(owner: unknown, args: {}) {
    super(owner, args);
    taskFor(this.loadMessagingSettings).perform();
  }

  get canChangeDefaultAssignee() {
    return this.permissionsService.currentAdminCan('can_manage_messages_settings');
  }

  get app() {
    return this.appService.app;
  }

  @action
  async updateDefaultAssignee(assigneeId: string) {
    if (this.messagingSettings) {
      this.messagingSettings.set('defaultAssigneeId', assigneeId);
      try {
        await this.messagingSettings.save();
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.inbox-rules.index.default-assignee-success'),
        );
        this.app.set('defaultAssigneeId', parseInt(assigneeId, 10));
      } catch (error) {
        this.notificationsService.notifyError(
          this.intl.t('settings.inbox-rules.index.default-assignee-error'),
        );
      }
    }
  }

  @action
  showPermissionsRequestModal() {
    this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
      'can_manage_messages_settings',
    );
  }

  @restartableTask
  *loadMessagingSettings() {
    try {
      this.messagingSettings = yield this.store.findRecord('messaging-settings', this.app.id);
    } catch (error) {
      // This API has permissions, not having the permissions is fine
      // since we will show a permission dialog if you try to set default assignee
      // so catch the failure and do nothing with it
      return;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Index::DefaultAssigneeComponent': typeof DefaultAssigneeComponent;
    'workflows/index/default-assignee-component': typeof DefaultAssigneeComponent;
  }
}
