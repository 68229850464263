/* import __COLOCATED_TEMPLATE__ from './workflow-connector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { tracked } from '@glimmer/tracking';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';

export default class WorkflowConnector extends Component {
  @service store;
  @service router;
  @service appService;
  @service attributeService;
  @service intl;

  @tracked objectDescriptors;
  @tracked attributeNamesAndIcons = {};
  @tracked actionParams;

  constructor() {
    super(...arguments);
    this.loadObjectDescriptors();
    this.setDefaultWaitingMessage();
  }

  get workflowConnectorActionItems() {
    if (this.args.step.listTemplates || this.args.step.action?.isTemplate) {
      return this.customActionTemplates();
    } else {
      return this.customActions();
    }
  }

  customActionsWithTemplates() {
    return this.store.peekAll('workflow-connector/insertable-action');
  }

  customActions() {
    return this.customActionsWithTemplates().filter((action) => !action.isTemplate);
  }

  customActionTemplates() {
    return this.customActionsWithTemplates().filter((action) => action.isTemplate);
  }

  get hasSettingsSideDrawer() {
    return this.args.step.action?.isTemplate || !!this.args.step.action?.appPackageCode;
  }

  get selectItems() {
    return this.workflowConnectorActionItems
      .filter((action) => action.isTemplate || action.appPackageCode === null)
      .map((action) => ({
        text: action.name,
        value: action.id,
      }));
  }

  get hasActions() {
    return this.selectItems.length > 0;
  }

  get noActionsItems() {
    return [
      {
        value: null,
        component: 'operator-flows/editor/step-list/workflow-connector-editor/no-actions',
        componentShouldReplaceItem: true,
      },
    ];
  }

  get isAppPackageAction() {
    return this.args.step.action?.appPackageCode && !this.args.step.action?.isTemplate;
  }

  get fallbackBranch() {
    return this.args.step.outwardConnectionPoints.firstObject;
  }

  get customActionDetails() {
    return {
      repoName: this.repoName(),
    };
  }

  get isInEditMode() {
    return !this.args.readOnly;
  }

  @action
  selectCustomAction(actionId) {
    this.args.step.action = this.store.peekRecord('workflow-connector/insertable-action', actionId);
  }

  @action
  openNewCustomAction() {
    let uri = this.router.urlFor('apps.app.settings.app-settings.custom-actions.index');
    safeWindowOpen(uri);
  }

  @action
  maybeOpenSideDrawer() {
    if (this.isInEditMode && this.hasSettingsSideDrawer) {
      this.args.step.showDrawer = true;
    }
  }

  loadObjectDescriptors() {
    let objectTypes = this.store.peekAll('custom-objects/object-type');
    this.objectDescriptors = objectTypes
      .map((objectType) => {
        return objectType.attributeDescriptorsInclStandard;
      })
      .flat();
    this.getAttributeNamesAndIcons();
  }

  repoName() {
    if (this.args.step?.action && this.args.step.action.appPackageCode === 'github') {
      let url = this.args.step.action.url;
      let regex = /https:\/\/api\.github\.com\/repos\/([^\/]+)\/([^\/]+)/;
      let match = url.match(regex);
      if (match) {
        let owner = match[1];
        let repoName = match[2];

        return `${owner}/${repoName}`;
      } else {
        return '';
      }
    }
  }

  getAttributeNamesAndIcons() {
    this.allAttributes.forEach(
      (attribute) =>
        (this.attributeNamesAndIcons[attribute.identifier] = {
          name: attribute.name,
          icon: this.icon(attribute),
        }),
    );
    this.resolver.eventAttributes.forEach(
      (attribute) =>
        (this.attributeNamesAndIcons[attribute.templatable_identifier] = {
          name: attribute.name,
          icon: this.icon(attribute),
        }),
    );
    this.objectDescriptors.forEach(
      (descriptor) =>
        (this.attributeNamesAndIcons[`custom_object_attribute.${descriptor.id}`] = {
          name: descriptor.name,
          icon: this.icon(descriptor),
        }),
    );
  }

  setDefaultWaitingMessage() {
    if (this.args.step.serializedBlocks.length === 0) {
      this.args.step.blocks = [
        createFragmentFromBlock(this.store, {
          text: this.intl.t('operator.workflows.step-list.workflow-connector.wait-message-default'),
          type: 'paragraph',
        }),
      ];
    }
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.allAttributes,
      includeAppAttributes: true,
      selectedEvents: this.selectedEvents,
    });
  }

  get allAttributes() {
    return [...this.attributeService.allAttributes, ...this.resolver.manualAppAttributes];
  }

  get attributes() {
    return this.attributeService.userEditorMetadataAndConversationAttributes;
  }

  get requestAttributes() {
    let requestAttributes = this.args.step.action.requestAttributeIdentifiersArray
      .filter((identifier) => identifier in this.attributeNamesAndIcons)
      .map((identifier) => this.attributeNamesAndIcons[identifier]);

    if (this.args.step.action.usage === 'fin') {
      requestAttributes = this.args.step.action.requestParameters
        .map((param) => {
          return {
            name: param.name,
            icon: this.iconByType(param.type),
          };
        })
        .concat(requestAttributes);
    }

    return requestAttributes;
  }

  get responseAttributes() {
    if (this.args.step.action.usage === 'fin') {
      return this.args.step.action.responseFields.map((field) => {
        return {
          name: field.name,
          icon: this.iconByType(field.type),
        };
      });
    } else {
      return this.args.step.action.responseAttributeIdentifiersArray
        .filter((identifier) => identifier in this.attributeNamesAndIcons)
        .map((identifier) => this.attributeNamesAndIcons[identifier]);
    }
  }

  icon(attributeOrDescriptor) {
    let type = attributeOrDescriptor.dataType
      ? attributeOrDescriptor.dataType
      : attributeOrDescriptor.type;

    return this.iconByType(type);
  }

  iconByType(type) {
    switch (type) {
      case 'string':
        return 'recipient-data';
      case 'integer':
        return 'count';
      case 'decimal':
        return 'decimal';
      case 'boolean':
        return 'boolean';
      case 'datetime':
        return 'calendar';
      case 'list':
        return 'list';
      case 'relationship':
        return 'transfer';
      default:
        return 'insert-data';
    }
  }

  get filterName() {
    if (this.args.step.action) {
      return this.args.step.action.name;
    }
    return this.intl.t('operator.workflows.step-list.workflow-connector.filter-block-name');
  }

  get shouldShowActionError() {
    return (
      this.args.editorState.shouldShowValidations &&
      !this.args.step.validations.attrs.action.isValid
    );
  }
  get shouldShowOutputError() {
    return (
      this.args.editorState.shouldShowValidations &&
      !this.args.step.validations.attrs.outwardConnectionPoints.isValid
    );
  }
}
