/* import __COLOCATED_TEMPLATE__ from './local-time-and-location-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { and, equal, notEmpty, or, readOnly } from '@ember/object/computed';
import { pollTask, runTask } from 'ember-lifeline';
import { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import moment from 'moment-timezone';

export default Component.extend({
  classNames: ['kv__pair', 'u__cf', 'o__in-list'],

  intl: service(),

  init() {
    this._super(...arguments);
    this._pollToken = pollTask(this, 'updateLocalTime');
  },
  updateLocalTime(next) {
    if (!this.hasTime) {
      return;
    }

    let localTime = this.intl.formatTime(new Date(), {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: this.timezone,
    });

    this.set('localTime', localTime);
    runTask(this, next, ENV.APP._3000MS);
  },

  localTimeAndLocationString: computed('intl.locale', 'localTime', 'person', function () {
    if (this.hasTimeAndLocation) {
      return this.intl.t(
        'inbox.conversation-details-sidebar.local-time-and-location.time-in-location',
        { time: this.localTime, location: this.location },
      );
    } else if (this.hasTime) {
      return this.localTime;
    } else if (this.hasLocation) {
      return this.location;
    } else {
      return this.intl.t('inbox.conversation-details-sidebar.local-time-and-location.unknown');
    }
  }),

  hasTime: computed('timezone', function () {
    return moment.tz.names().includes(this.timezone);
  }),

  location: computed('person', function () {
    if (this.hasCityAndRegionAndCountryInUS) {
      return `${this.city}, ${this.regionCode}, ${this.country}`;
    } else if (this.hasCityAndCountry) {
      return `${this.city}, ${this.country}`;
    } else {
      return this.country;
    }
  }),

  hasTimeAndLocation: and('hasLocation', 'hasTime'),
  hasTimeOrLocation: or('hasLocation', 'hasTime'),

  hasCityAndRegionAndCountryInUS: and('hasCityAndRegionAndCountry', 'isUSAddress'),
  hasCityAndRegionAndCountry: and('city', 'regionCode', 'country'),
  hasCityAndCountry: and('city', 'country'),
  hasLocation: notEmpty('country'),
  isUSAddress: equal('person.geoip_data.country_code2', 'US'),

  timezone: readOnly('person.geoip_data.timezone'),
  city: readOnly('person.geoip_data.city_name'),
  regionCode: readOnly('person.geoip_data.region_code'),
  country: readOnly('person.geoip_data.country_name'),
});
