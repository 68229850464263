/* import __COLOCATED_TEMPLATE__ from './social-data-display-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed, set } from '@ember/object';
import Component from '@ember/component';
export default Component.extend({
  rawSocialAccounts: computed('accountKeys', function () {
    return this.accountKeys.map(this._createRawSocialAccount.bind(this));
  }),

  accountKeys: computed('socialData', function () {
    return Object.keys(this.socialData).without('gravatar');
  }),

  socialAccounts: computed('rawSocialAccounts', function () {
    return this.rawSocialAccounts.reduce(this._flattenRawSocialAccounts.bind(this), []);
  }),

  _createRawSocialAccount(accountKey) {
    let socialAccount = this.socialData[accountKey];
    return {
      name: accountKey,
      accounts: socialAccount.accounts,
    };
  },

  _flattenRawSocialAccounts(flattenedAccounts, rawAccount) {
    if (rawAccount.accounts.get('length') === 1) {
      flattenedAccounts.addObject(
        this._flattenedSocialAccount(rawAccount.name, rawAccount.accounts.get('firstObject')),
      );
    } else {
      rawAccount.accounts.forEach(
        function (account) {
          flattenedAccounts.addObject(this._flattenedSocialAccount(rawAccount.name, account));
        }.bind(this),
      );
    }
    return flattenedAccounts;
  },

  _flattenedSocialAccount(name, account) {
    set(account, 'name', name);
    return account;
  },
});
