/* import __COLOCATED_TEMPLATE__ from './list-item-component.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { pluralize } from 'ember-inflector';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Trigger from '../../../lib/workflows/constants/triggers';
import { getOwner } from '@ember/application';

const MAX_TRIGGERS_TO_SHOW = 1;
const MAX_ACTIONS_TO_SHOW = 3;

export default class ListItemComponent extends Component {
  @service appService;
  @service router;
  @service notificationsService;
  @service intl;
  @service modalService;
  @service intercomConfirmService;
  @tracked workflow = this.args.workflow;
  @tracked workflows = this.args.workflows;

  get triggers() {
    return new Trigger(getOwner(this)).items;
  }

  get app() {
    return this.appService.app;
  }

  get stateLabel() {
    if (this.args.isMigratedToWorkflows) {
      return this.intl.t('settings.inbox-rules.list.rule.migrated-to-workflows');
    }
    return this.args.workflow.isLive
      ? this.intl.t('settings.inbox-rules.list.rule.live')
      : this.intl.t('settings.inbox-rules.list.rule.rule-paused');
  }

  get stateColor() {
    if (this.args.isMigratedToWorkflows) {
      return 'gray';
    }
    return this.args.workflow.isLive ? 'green' : 'gray';
  }

  get displayActions() {
    return this.args.workflow.actions.sortBy('sortOrder').slice(0, MAX_ACTIONS_TO_SHOW);
  }

  get noActions() {
    return this.workflow.actions.length === 0;
  }

  get moreActions() {
    if (this.args.workflow.actions.length > MAX_ACTIONS_TO_SHOW) {
      let n = this.args.workflow.actions.length - MAX_ACTIONS_TO_SHOW;
      let action = pluralize(n, this.intl.t('settings.inbox-rules.index.workflow.action'), {
        withoutCount: true,
      });
      return this.intl.t('settings.inbox-rules.index.workflow.more-actions', { n, action });
    }
  }

  get triggerObject() {
    let triggerValue = this.args.workflow.triggerTypes[0];
    return this.triggers.find((trigger) => trigger.value === triggerValue);
  }

  get noTriggers() {
    return this.args.workflow.triggerTypes.length === 0;
  }

  get moreTriggers() {
    let numTriggers = this.args.workflow.triggerTypes.length;
    if (numTriggers > MAX_TRIGGERS_TO_SHOW) {
      let n = numTriggers - MAX_TRIGGERS_TO_SHOW;
      let event = pluralize(n, this.intl.t('settings.inbox-rules.index.workflow.event'), {
        withoutCount: true,
      });
      return this.intl.t('settings.inbox-rules.index.workflow.more-events', { n, event });
    }
  }

  get description() {
    let text = this.triggerObject.text.toLowerCase();
    return this.intl.t('settings.inbox-rules.index.workflow.description', { text });
  }

  get groupList() {
    let items = [
      {
        text: this.intl.t('settings.inbox-rules.list.move-to-top'),
        icon: 'thin-up-arrow',
        onSelectItem: this.moveWorkflowToTop,
        isDisabled: this.args.hasActiveFilters || this.workflow.order === 0,
      },
      {
        text: this.intl.t('settings.inbox-rules.list.move-to-bottom'),
        icon: 'thin-down-arrow',
        onSelectItem: this.moveWorkflowToBottom,
        isDisabled: this.args.hasActiveFilters || this.workflow.order === this.workflows.length - 1,
      },
    ];

    if (this.app.canUseInboxRules) {
      items.push(this.pauseLiveItem());
    }

    items.push({
      text: this.intl.t('settings.inbox-rules.list.delete'),
      icon: 'trash',
      onSelectItem: this.deleteWorkflow,
    });

    return [{ items }];
  }

  get editRoute() {
    if (this.app.canSeeNewIASettingsHub) {
      return 'apps.app.settings.helpdesk.rules.edit';
    }
    return 'apps.app.settings.inbox-rules.edit';
  }

  pauseLiveItem() {
    if (this.workflow.isLive) {
      return {
        text: this.intl.t('settings.inbox-rules.list.pause'),
        icon: 'pause',
        onSelectItem: this.pauseWorkflow,
      };
    } else {
      return {
        text: this.intl.t('settings.inbox-rules.list.set-live'),
        icon: 'put-live',
        onSelectItem: this.setLiveWorkflow,
        isDisabled: !this.workflow.canBeLive,
      };
    }
  }

  @action navigateToInboxRule() {
    this.router.transitionTo(this.editRoute, this.workflow.id);
  }

  @action openMigratedToWorkflowsModal() {
    this.modalService.openModal('settings/modals/migrated-to-workflows', {
      workflow: this.workflow,
    });
  }

  @action
  async pauseWorkflow() {
    this.workflow.set('status', 'paused');
    return this.workflow
      .save()
      .then(() => {
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.inbox-rules.list.rule.paused'),
        );
      })
      .catch(() => {
        this.workflow.set('status', 'live');
        this.notificationsService.notifyError(
          this.intl.t('settings.inbox-rules.list.rule.failed-to-pause'),
        );
      });
  }

  @action
  async setLiveWorkflow() {
    this.workflow.set('status', 'live');
    return this.workflow
      .save()
      .then(() => {
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.inbox-rules.list.rule.set-live'),
        );
      })
      .catch(() => {
        this.workflow.set('status', 'paused');
        this.notificationsService.notifyError(
          this.intl.t('settings.inbox-rules.list.rule.failed-to-set-live'),
        );
      });
  }

  @action
  async deleteWorkflow() {
    let confirmOptions = {
      title: this.intl.t('settings.inbox-rules.list.delete-popup.title'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t('settings.inbox-rules.list.delete-popup.delete'),
      cancelButtonText: this.intl.t('settings.inbox-rules.list.delete-popup.cancel'),
      body: this.intl.t('settings.inbox-rules.list.delete-popup.body'),
    };
    let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
    if (!isConfirmed) {
      return;
    }
    this.workflow
      .destroyRecord()
      .then(() => {
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.inbox-rules.list.delete-popup.success'),
        );
      })
      .catch(() =>
        this.notificationsService.notifyError(
          this.intl.t('settings.inbox-rules.list.delete-popup.error'),
        ),
      );
  }

  @action
  moveWorkflowToTop() {
    this.shiftWorkflows(0, 1);
  }

  @action
  moveWorkflowToBottom() {
    this.shiftWorkflows(this.workflows.length - 1, -1);
  }

  shiftWorkflows(newPosition, shiftBy) {
    this.workflow
      .reorder(newPosition)
      .then(() => {
        this.workflow.set('order', newPosition);
        this.workflows.forEach((workflow) => {
          if (workflow !== this.workflow) {
            workflow.set('order', workflow.get('order') + shiftBy);
          }
        });
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.inbox-rules.list.rule.moved'),
        );
      })
      .catch((error) =>
        this.notificationsService.notifyError(
          this.intl.t('settings.inbox-rules.list.rule.failed-to-move'),
        ),
      );
  }
}
