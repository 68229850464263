/* import __COLOCATED_TEMPLATE__ from './remove-tag-from-user.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  action: $TSFixMe;
  truncateText: boolean;
}

const RemoveTagFromUserAction = templateOnlyComponent<Signature>();
export default RemoveTagFromUserAction;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ApplyRules::Actions::RemoveTagFromUser': typeof RemoveTagFromUserAction;
    'workflows/graph-editor/node-items/steps/apply-rules/actions/remove-tag-from-user': typeof RemoveTagFromUserAction;
  }
}
