/* import __COLOCATED_TEMPLATE__ from './reference-mapping.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isBlank } from '@ember/utils';

export default class ReferenceMapping extends Component {
  @service store;
  @service intl;
  @service appService;
  @tracked referenceMapping = this.args.referenceMapping;

  get app() {
    return this.appService.app;
  }

  get selectedReferenceAttribute() {
    let { intercomObjectType, intercomAttributeIdentifier } = this.referenceMapping;
    if (isBlank(intercomObjectType) || isBlank(intercomAttributeIdentifier)) {
      return undefined;
    }

    let selectedAttribute = this._findAttributeOption(
      intercomObjectType,
      intercomAttributeIdentifier,
    );
    return selectedAttribute?.value || undefined;
  }

  _findAttributeOption(objectType, identifier) {
    return this.args.intercomReferenceMappingOptions.find(
      (option) =>
        option.value.objectTypeIdentifier === objectType && option.value.identifier === identifier,
    );
  }

  @action setReferenceAttribute(newReferenceAttribute) {
    this.referenceMapping.intercomObjectType = newReferenceAttribute.objectTypeIdentifier;
    this.referenceMapping.intercomAttributeIdentifier = newReferenceAttribute.identifier;
  }
}
