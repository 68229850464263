/* import __COLOCATED_TEMPLATE__ from './voicemail.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import type Voicemail from 'embercom/models/operator/visual-builder/step/voicemail';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

interface Arguments {
  step: Voicemail;
  editorState: EditorState;
  readOnly: boolean;
}

export default class VoicemailNodeItemStep extends Component<Arguments> {
  get shouldShowError() {
    return this.args.editorState.shouldShowValidations && !this.args.step.validations.isValid;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Voicemail': typeof VoicemailNodeItemStep;
    'workflows/graph-editor/node-items/steps/voicemail': typeof VoicemailNodeItemStep;
  }
}
