/* import __COLOCATED_TEMPLATE__ from './action-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ActionSelector extends Component {
  @service appService;

  @tracked app = this.appService.app;
  @tracked workflow = this.args.workflow;
  @tracked actionEditorContext = this.args.actionEditorContext;

  get allowedRuleActions() {
    let allowedActions = [
      'assign-conversation',
      'change-conversation-priority',
      'apply-conversation-sla',
      'add-tag-to-conversation',
      'close-conversation',
      'snooze-conversation',
      'workflow-connector-action',
      'set-ticket-state',
      'set-ticket-custom-state',
    ];

    if (this.app.canSeeReassignAction) {
      allowedActions.push('reassign-conversation');
    }

    if (this.app.hasSalesforceIntegration) {
      allowedActions.push('create-salesforce-case');
    }

    if (this.app.hasSlackNotificationsIntegration) {
      allowedActions.push('notify-slack-channel');
    }

    if (this.app.canNotifyPrivateSlackChannel && this.app.hasSlackNotificationsIntegration) {
      allowedActions.push('notify-private-slack-channel');
    }

    if (this.app.canUseSetUserDataAttribute) {
      allowedActions.push('set-user-data-attribute');
    }

    return allowedActions;
  }

  @action
  addWorkflowAction(value) {
    this.args.workflow.actions.createFragment({
      type: value,
    });
  }

  @action
  removeWorkflowAction(model) {
    this.args.workflow.actions.removeFragment(model);
  }
}
