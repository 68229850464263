/* import __COLOCATED_TEMPLATE__ from './summarize-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import type { default as SummarizeConversationStepType } from 'embercom/models/operator/visual-builder/step/summarize-conversation';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export interface Args {
  step: SummarizeConversationStepType;
  editorState: EditorState;
  onChange: () => void;
  onDelete: () => void;
  readOnly: boolean;
}

export default class SummarizeConversation extends Component<Args> {
  @service declare appService: {
    app: {
      hasSummarizationSettingEnabled: boolean;
      hasTextTransformationSettingEnabled: boolean;
    };
  };

  get isAutoSummarizeAvailable() {
    return (
      this.appService.app.hasSummarizationSettingEnabled ||
      this.appService.app.hasTextTransformationSettingEnabled
    );
  }

  get hasValidationErrors() {
    return this.args.editorState.shouldShowValidations && this.args.step.validations.isInvalid;
  }

  get shouldShowError() {
    return this.hasValidationErrors || !this.isAutoSummarizeAvailable;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::SummarizeConversation': typeof SummarizeConversation;
  }
}
