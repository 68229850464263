/* import __COLOCATED_TEMPLATE__ from './answer-bot.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type AnswerBot from 'embercom/models/operator/visual-builder/step/answer-bot';
import RulesEditor from 'embercom/components/operator-flows/editor/rules-editor';

interface Arguments {
  step: AnswerBot;
  readOnly: boolean;
  editorState: EditorState;
}

export default class AnswerBotNodeItem extends Component<Arguments> {
  RulesEditorComponent = RulesEditor;

  get firstBlockText() {
    return this.args.step.blocks.firstObject.text;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::AnswerBot': typeof AnswerBotNodeItem;
  }
}
