/* import __COLOCATED_TEMPLATE__ from './composer.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import ComposerConfig from 'embercom/objects/workflows/graph-editor/audio-snippet/composer-config';
import { ref } from 'ember-ref-bucket';
import type IntlService from 'ember-intl/services/intl';
import type { Block } from '@intercom/interblocks.ts';

interface Arguments {
  blocks: Array<Block>;
  readOnly: boolean;
  displayComposerEditor: boolean;
  hasAudioSnippetInserter?: boolean;
  updateComposerBlocks: (blocksDoc: Array<Block>) => void;
}

export default class Composer extends Component<Arguments> {
  blocksDoc;
  @service intl!: IntlService;
  @service appService: any;
  @service attributeService: any;
  @service store: any;
  @service intercomEventService: any;
  @service contentEditorService: any;

  @tracked composerApi: any;
  @tracked composerVisible: boolean;
  @ref('inputElement') inputElement!: HTMLInputElement;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
    this.blocksDoc = new BlocksDocument(this.args.blocks);
    this.composerVisible = this.blocksDoc.blocks.length !== 0 || this.args.displayComposerEditor;
  }

  get app() {
    return this.appService.app;
  }

  get composerConfig() {
    let config = new ComposerConfig({
      app: this.app,
      placeholder: this.intl.t('operator.workflows.visual-builder.chat-message-placeholder'),
    });

    config.upload.onUploadStart = this.contentEditorService.uploadFileStarted;
    config.upload.onUploadFinish = this.contentEditorService.uploadFileFinished;

    return config;
  }

  get controlsAPI() {
    return {
      app: this.app,
      actions: {
        uploadAudio: (files: Array<File>) => {
          let file = files && files.length > 0 && files[0];
          this.composerApi.composer.commands.insertAudioFile(file);
        },
      },
    };
  }

  @action
  handleChange(event: any) {
    this.composerVisible = true;
    let files: Array<File> = Array.from(event?.target?.files);
    if (files.length > 0) {
      this.inputElement.value = '';
      this.controlsAPI?.actions.uploadAudio(files);
    } else {
      this.composerVisible = false;
    }
  }

  @action
  updateBlocks(blocksDoc: BlocksDocument) {
    let store = this.store;
    let blockFragments = blocksDoc.blocks.map((block) => createFragmentFromBlock(store, block));
    // this is a known workaround to an issue with creating new fragments on a tracked model in a different component
    // without this `get serializedBlocks()` doesn't recompute and the chat message appears not to update
    this.args.updateComposerBlocks(blockFragments);
    if (blockFragments.length <= 1 && !this.args.displayComposerEditor) {
      this.composerVisible = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::AudioSnippetEditor::Composer': typeof Composer;
    'workflows/graph-editor/audio-snippet-editor/composer': typeof Composer;
  }
}
