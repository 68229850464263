/* import __COLOCATED_TEMPLATE__ from './delay-overlay.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import BaseFilterBlockPopover from 'predicates/components/filter-block/base-filter-block-popover';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class Delay extends BaseFilterBlockPopover {
  @service appService;
  @tracked selectedValue = this.componentAttrs.triggerConfig;
  @tracked delayValue = this.componentAttrs.delayValue;
  @tracked delayUnit = this.componentAttrs.delayUnit;

  @action
  setDelayUnit(unit) {
    this.delayUnit = unit;
    this.componentAttrs.saveDelay(this.delayValue, unit);
  }

  @action
  setDelayValue() {
    this.componentAttrs.saveDelay(this.delayValue, this.delayUnit);
  }
}
