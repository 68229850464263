/* import __COLOCATED_TEMPLATE__ from './phone-call-csat-request.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type PhoneCallCsatRequest from 'embercom/models/operator/visual-builder/step/phone-call-csat-request';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

interface Arguments {
  step: PhoneCallCsatRequest;
  editorState: EditorState;
  readOnly: boolean;
}

export default class PhoneCallCsatRequestNodeItemStep extends Component<Arguments> {
  get shouldShowError() {
    return this.args.editorState.shouldShowValidations && !this.args.step.validations.isValid;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::PhoneCallCsatRequest': typeof PhoneCallCsatRequestNodeItemStep;
    'workflows/graph-editor/node-items/steps/phone-call-csat-request': typeof PhoneCallCsatRequestNodeItemStep;
  }
}
