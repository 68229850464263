/* import __COLOCATED_TEMPLATE__ from './add-tag-to-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ListItemComponent extends Component {
  @service appService;
  @service intl;
  @tracked action = this.args.action;

  get description() {
    if (this.tag() === undefined) {
      return this.intl.t(
        'settings.inbox-rules.index.actions.add-tag-to-conversation.invalid-message',
      );
    } else {
      return this.intl.t('settings.inbox-rules.index.actions.add-tag-to-conversation.description', {
        tagName: this.tag().name,
      });
    }
  }

  tag() {
    let tagId = this.args.action.actionData.tag_id;
    return this.appService.app.tags.findBy('id', tagId);
  }
}
