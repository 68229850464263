/* import __COLOCATED_TEMPLATE__ from './filters-component.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Trigger from '../../../../lib/workflows/constants/triggers';
import { getOwner } from '@ember/application';

export default class FiltersComponent extends Component {
  @service appService;
  @service attributeService;
  @service intl;
  @service store;

  get events() {
    return new Trigger(getOwner(this)).items;
  }

  get app() {
    return this.appService.app;
  }

  get eventItems() {
    let items = [{ text: this.intl.t('settings.inbox-rules.index.filter.any'), value: 'any' }];
    items = items.concat(this.events.map((e) => ({ text: e.text, value: e.value })));
    return items;
  }

  get statusItems() {
    return [
      { text: this.intl.t('settings.inbox-rules.index.filter.any'), value: 'any' },
      { text: this.intl.t('settings.inbox-rules.index.filter.live'), value: 'live' },
      { text: this.intl.t('settings.inbox-rules.index.filter.paused'), value: 'paused' },
    ];
  }

  get eventFilterDisplayName() {
    let item = this.eventItems.find((e) => e.value === this.args.eventFilter);
    return item.text;
  }

  get eventFilterDetails() {
    return `${this.intl.t('settings.inbox-rules.index.filter.is')} ${this.eventFilterDisplayName}`;
  }

  get statusFilterDisplayName() {
    let item = this.statusItems.find((e) => e.value === this.args.statusFilter);
    return item.text;
  }

  get statusFilterDetails() {
    return `${this.intl.t('settings.inbox-rules.index.filter.is')} ${this.statusFilterDisplayName}`;
  }

  get workflowFilterAttributes() {
    return this.attributeService._workflowFilterAttributes.map((data) =>
      this.store.createRecord('attribute', data),
    );
  }

  get attributeGroupList() {
    return [
      {
        heading: this.intl.t('settings.inbox-rules.index.filter.attribute.title'),
        attributes: this.workflowFilterAttributes,
      },
    ];
  }

  get filtersAdded() {
    return this.args.filterPredicateGroup.predicates.map((p) => p.attributeIdentifier);
  }

  get disabledAttributeIdentifiers() {
    return this.workflowFilterAttributes
      .filter((attr) => attr.disabled || this.filtersAdded.includes(attr.identifier))
      .map((attr) => attr.identifier);
  }
}
