/* import __COLOCATED_TEMPLATE__ from './set-expectations.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    accordion: $TSFixMe;
    step: $TSFixMe;
    isReadOnly: boolean;
    botOnlyModeAvailable: boolean;
    toggleBotOnlyMode: () => void;
    configuration: any;
    thatHelpedButtonLabel: string;
    triageButtonLabel: string;
  };
}

const SetExpectations = templateOnlyComponent<Signature>();
export default SetExpectations;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Fin::Sections::SetExpectations': typeof SetExpectations;
  }
}
