/* import __COLOCATED_TEMPLATE__ from './remove-tag-from-user.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type RemoveTagFromUser from 'embercom/models/operator/visual-builder/step/remove-tag-from-user';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

interface RemoveTagFromUserArgs {
  step: RemoveTagFromUser;
  editorState: EditorState;
  readOnly: boolean;
  actionEditorContext: $TSFixMe;
  onChange: () => void;
  onDelete: () => void;
  rule: $TSFixMe;
}

interface Signature {
  Element: HTMLDivElement;
  Args: RemoveTagFromUserArgs;
}

export default class RemoveTagFromUserNodeItem extends Component<Signature> {
  get allRuleActions() {
    return this.steps.mapBy('firstSimpleAction').reject((action: $TSFixMe) => !action);
  }

  get steps() {
    return this.group.steps;
  }

  get group() {
    return this.args.step.group;
  }

  get shouldShowError() {
    return this.args.editorState.shouldShowValidations && !this.args.step.validations.isValid;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::RemoveTagFromUser': typeof RemoveTagFromUserNodeItem;
    'workflows/graph-editor/node-items/steps/remove-tag-from-user': typeof RemoveTagFromUserNodeItem;
  }
}
