/* import __COLOCATED_TEMPLATE__ from './message-templates-modal.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';
import { task } from 'ember-concurrency-decorators';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

import BrowserLocales from 'embercom/lib/browser-locales';
import SearchClient from 'embercom/lib/search/client';

let searchClientConfig = {
  fields: [
    { name: 'name', boost: 20 },
    { name: 'languageName', boost: 5 },
    { name: 'languageCode', boost: 2 },
    { name: 'header', boost: 1 },
    { name: 'body', boost: 1 },
    { name: 'footer', boost: 1 },
    { name: 'buttons', boost: 1 },
  ],
};

export default class MessageTemplatesModal extends Component {
  @service store;
  @service appService;
  @service conversationsService;
  @service notificationsService;
  @service intercomEventService;
  @service intl;

  @tracked allMessageTemplates = [];
  @tracked filteredTemplates = [];
  @tracked selectedTemplate;
  @tracked languagesGroupList = [
    {
      items: [
        {
          text: this.intl.t('components.whatsapp.message-templates-modal.any-language'),
          value: 'any',
        },
      ],
    },
  ];
  @tracked selectedLanguage = 'any';

  @tracked searchTerm;
  @tracked _searchClient;

  get app() {
    return this.appService.app;
  }

  get primaryDisabled() {
    return isEmpty(this.selectedTemplate);
  }

  get searchClient() {
    if (isEmpty(this._searchClient)) {
      // Nolaneo – this linter error seems genuine: why are we mutating state in this getter?
      // eslint-disable-next-line
      this._searchClient = new SearchClient(searchClientConfig);
    }
    return this._searchClient;
  }

  get searchPlaceholder() {
    let templates = this.allMessageTemplates || [];
    let numMessageTemplates = this.filterByLanguage(templates, this.selectedLanguage).length;
    return this.intl.t('components.whatsapp.message-templates-modal.search-placeholder', {
      numTemplates: numMessageTemplates,
    });
  }

  get displaySelectedLanguage() {
    if (this.isAnyLanguage(this.selectedLanguage)) {
      return this.intl.t('components.whatsapp.message-templates-modal.any-language');
    }
    return this.languageNameFromCode(this.selectedLanguage);
  }

  get searchClientInitialized() {
    return isPresent(this.searchClient.index);
  }

  getLanguagesGroupList(messageTemplates) {
    let languages = messageTemplates.map((template) => template.language);
    let uniqueLanguages = [...new Set(languages)];
    let items = uniqueLanguages.map((language) => {
      return { text: this.languageNameFromCode(language), value: language };
    });
    return [
      {
        items: [
          {
            text: this.intl.t('components.whatsapp.message-templates-modal.any-language'),
            value: 'any',
          },
          ...items,
        ],
      },
    ];
  }

  isAnyLanguage(language) {
    return language === 'any';
  }

  languageNameFromCode(language) {
    let languageCode = language.replace('_', '-');
    return BrowserLocales[languageCode] || language?.toUpperCase();
  }

  filterByLanguage(templates, language) {
    let filteredTemplates;
    if (this.isAnyLanguage(this.selectedLanguage)) {
      filteredTemplates = templates;
    } else {
      filteredTemplates = templates.filter((template) => template.language === language);
    }
    return filteredTemplates;
  }

  runFilterTask(searchTerm) {
    this.filterTask.perform(searchTerm);
  }

  @task({ drop: true })
  *fetchTemplatesTask() {
    try {
      this.allMessageTemplates = yield this.store.query('whatsapp/message-template', {
        conversation_id: this.args.conversation.id,
      });
    } catch (error) {
      this.notificationsService.notifyResponseError(
        error,
        {
          default: this.intl.t(
            'components.whatsapp.message-templates-modal.notification.templates-fetch-error',
          ),
        },
        {
          responseProvidedErrors: [403, 422, 404],
        },
      );
    }
    this.filteredTemplates = this.allMessageTemplates;
    this.selectTemplate(this.allMessageTemplates.firstObject);
    this.languagesGroupList = this.getLanguagesGroupList(this.allMessageTemplates);
    yield this.buildSearchClient.perform();
    this.filterTask.perform();
  }

  @task({ restartable: true })
  *filterTask(searchTerm) {
    if (!this.searchClientInitialized) {
      return;
    }
    searchTerm = searchTerm || this.searchTerm;
    let filteredBySearch;
    if (isEmpty(searchTerm)) {
      filteredBySearch = this.allMessageTemplates;
    } else {
      let fuzzyResults = yield this.searchClient.search(searchTerm);
      filteredBySearch = fuzzyResults.map((result) =>
        this.store.peekRecord('whatsapp/message-template', result.ref),
      );
    }
    this.filteredTemplates = this.filterByLanguage(filteredBySearch, this.selectedLanguage);
    this.filteredTemplates = this.filteredTemplates.filter((template) => template);
    if (isPresent(this.filteredTemplates)) {
      this.selectTemplate(this.filteredTemplates.firstObject);
    } else {
      this.selectedTemplate = undefined;
    }
  }

  @task({ drop: true })
  *buildSearchClient() {
    let templatesSearchDocuments = this.allMessageTemplates.map((template) => ({
      id: template.id,
      name: template.name,
      languageName: this.languageNameFromCode(template.language),
      languageCode: template.language,
      header: template.header_text,
      body: template.body_text,
      footer: template.footer_text,
      buttons: template.buttons_text,
    }));
    yield this.searchClient.build(templatesSearchDocuments);
  }

  @action
  resetState() {
    this.searchTerm = '';
    this.allMessageTemplates = [];
    this.filteredTemplates = [];
    this.selectedLanguage = 'any';
  }

  @action
  fetchMessageTemplates() {
    this.resetState();
    this.fetchTemplatesTask.perform();
  }

  @action
  searchTemplates() {
    debounce(this, this.runFilterTask, this.searchTerm, 200);
  }

  @action
  selectTemplate(template) {
    if (template?.isInvalid) {
      return;
    }
    this.selectedTemplate = template;
  }

  @action
  onSelectLanguage(language) {
    this.selectedLanguage = language;
    this.filterTask.perform();
  }

  @action
  resetFilteredTemplates() {
    this.filteredTemplates = this.allMessageTemplates;
  }

  @action
  onConfirm() {
    if (this.selectedTemplate) {
      let conversation = this.args.conversation;
      conversation.updateAdminIsTyping(this.app.currentAdmin, 'comment');

      let params = {
        app: this.app,
        conversation,
      };
      this.selectedTemplate
        .send(params)
        .catch(() => {
          this.notificationsService.notifyError(
            'components.whatsapp.message-templates-modal.notification.template-send-error',
          );
        })
        .finally(() => {
          conversation.clearAdminTypingState();
        });

      this.intercomEventService.trackAnalyticsEvent({
        action: 'sent',
        object: this.selectedTemplate,
        conversation_id: conversation.id,
      });
    }
    if (this.args.confirmAction) {
      this.args.confirmAction();
    }
  }

  @action
  onModalClose() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      object: 'message_templates_modal',
      conversation_id: this.args.conversation.id,
    });
    if (this.args.onModalClose) {
      this.args.onModalClose();
    }
  }

  @action
  openMessageTemplateHelpArticle() {
    let url = 'https://en-gb.facebook.com/business/help/2055875911147364?id=2129163877102343';
    safeWindowOpen(url, '_blank');
  }
}
