/* import __COLOCATED_TEMPLATE__ from './tag.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { captureException } from 'embercom/lib/sentry';

interface Arguments {
  tagTranslation: string;
  action: $TSFixMe;
  truncateText: boolean;
}

export default class Tag extends Component<Arguments> {
  @service declare appService: $TSFixMe;

  get actionTagName() {
    let tagId = this.args.action.actionData.tag_id;

    if (!tagId) {
      return '';
    }

    let tag = this.appService.app.tags.findBy('id', tagId);

    if (!tag) {
      captureException(new Error('Unable to find corresponding tag for tag conversation action'));
      return '';
    }
    return tag.name;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ApplyRules::Actions::Tag': typeof Tag;
    'workflows/graph-editor/node-items/steps/apply-rules/actions/Tag': typeof Tag;
  }
}
