/* import __COLOCATED_TEMPLATE__ from './app-packages-supporting-fin-actions.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';

const APP_PACKAGES_SUPPORTING_FIN_ACTIONS = ['stripe', 'shopify', 'statuspage'];

export default class AppPackagesSupportingFinActions extends Component {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare intercomEventService: $TSFixMe;

  @tracked appPackages: any[] = [];

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.loadAppPackages).perform();
  }

  get hasUninstalledAppPackages(): boolean {
    return this.appPackages.length > 0;
  }

  @dropTask *loadAppPackages(): TaskGenerator<any> {
    this.appPackages = (yield this.store.findAll('appstore/app-package'))
      .filter((appPackage: any) => {
        return (
          APP_PACKAGES_SUPPORTING_FIN_ACTIONS.includes(appPackage.id) && !appPackage.isInstalled
        );
      })
      .sort((a: any, b: any) => a.name.localeCompare(b.name));
  }

  @action openInAppStore(appPackageCode: string): void {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'open_in_app_store',
      app_package_code: appPackageCode,
    });
    safeWindowOpen(`/a/apps/${this.appService.app.id}/appstore?app_package_code=${appPackageCode}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Setup::AppPackagesSupportingFinActions': typeof AppPackagesSupportingFinActions;
    'workflow-connector/setup/app-packages-supporting-fin-actions': typeof AppPackagesSupportingFinActions;
  }
}
