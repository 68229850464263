/* import __COLOCATED_TEMPLATE__ from './set-conversation-data-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class SetConversationDataAttribute extends Component {
  @service appService;
  @service attributeService;
  @tracked action = this.args.action;

  get conversationDataAttribute() {
    return this.attributeService.conversationCustomAttributes.find(
      (attribute) => attribute.identifier === this.identifier,
    );
  }

  get identifier() {
    return this.action.get('actionData.attribute_identifier');
  }

  get value() {
    return this.args.action.actionData.value;
  }

  get description() {
    return `Set ${this.attributeName} to "${this.formatValue}"`;
  }

  get attributeName() {
    return this.conversationDataAttribute.name;
  }

  get formatValue() {
    switch (this.conversationDataAttribute.type) {
      case 'conversation_attribute_list':
        return this.conversationDataAttribute.options.find(
          (option) => option.identifier === this.value,
        ).value;
      case 'boolean':
        return this.value ? 'True' : 'False';
      default:
        return this.value;
    }
  }
}
