/* import __COLOCATED_TEMPLATE__ from './snooze.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Snooze from 'embercom/models/operator/visual-builder/step/snooze';

interface Arguments {
  step: Snooze;
  readOnly: boolean;
  editorState: any;
}

export default class SnoozeComponent extends Component<Arguments> {
  @service intl!: IntlService;

  get title() {
    if (this.args.step.durationType === 'custom') {
      let formatKey = this.determineCustomFormatKey();
      return this.intl.t(
        `operator.workflows.visual-builder.snooze.duration-formatting.formats.${formatKey}`,
        {
          days: this.args.step.days,
          hours: this.args.step.hours,
          minutes: this.args.step.minutes,
          htmlSafe: true,
        },
      );
    } else {
      return this.intl.t(
        `operator.workflows.visual-builder.snooze.duration-formatting.formats.${this.args.step.durationType}`,
        {
          htmlSafe: true,
        },
      );
    }
  }

  determineCustomFormatKey() {
    let formatKey = '';

    if (this.args.step.days > 0) {
      formatKey += 'd';
    }
    if (this.args.step.hours > 0) {
      formatKey += 'h';
    }
    if (this.args.step.minutes > 0) {
      formatKey += 'm';
    }

    if (formatKey === '') {
      formatKey = 'empty';
    }

    return formatKey;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Snooze': typeof SnoozeComponent;
    'workflows/graph-editor/node-items/steps/snooze': typeof SnoozeComponent;
  }
}
