/* import __COLOCATED_TEMPLATE__ from './rules-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import { action, set } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type ApplyRules from 'embercom/models/operator/visual-builder/step/apply-rules';

interface Arguments {
  step: ApplyRules;
  supportedPredicateAttributes: string[];
}

export default class RulesEditorComponent extends Component<Arguments> {
  @tracked rules = this.args.step.followUpRules;

  @action
  addRule() {
    this.args.step.addRule();
  }

  @action
  reorderRules(sortedRules: any) {
    set(this.args.step, 'followUpRules', sortedRules);
  }

  @action
  deleteRule(rule: any) {
    this.rules.removeObject(rule);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ApplyRules::RulesEditor': typeof RulesEditorComponent;
    'workflows/graph-editor/node-items/steps/apply-rules/rules-editor': typeof RulesEditorComponent;
  }
}
