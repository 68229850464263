/* import __COLOCATED_TEMPLATE__ from './audience-header.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';

export type AudienceHeaderArgs = {
  isDisabled: boolean;
  config: any;
  section: any;
  editorConfiguration: EditorConfig;
};

interface Signature {
  Element: HTMLDivElement;
  Args: AudienceHeaderArgs;
}

const AudienceHeader = templateOnlyComponent<Signature>();
export default AudienceHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::TriggerInfoNode::AudienceHeader': typeof AudienceHeader;
  }
}
