/* import __COLOCATED_TEMPLATE__ from './sidebar-sections.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { and, map } from '@ember/object/computed';

export default Component.extend({
  classNames: ['profile__sidebar', 'u__pad__0'],

  sectionNames: [
    'user-details',
    'notes',
    'qualification',
    'recent-page-views',
    'external-profiles',
    'latest-conversations',
    'tags',
    'series',
    'segments',
    'events',
    'subscription-types',
    'company-details',
  ],

  includeCustomerSupportSection: and('app.isIntercom', 'app.currentAdmin.hasEnabledCSComponent'),
  resultingSectionNames: computed('sectionNames.[]', 'includeCustomerSupportSection', function () {
    let sectionNames = this.sectionNames.slice();
    if (this.includeCustomerSupportSection) {
      sectionNames.unshift('customer-support');
    }
    return sectionNames;
  }),
  defaultSectionComponents: map('resultingSectionNames', function (section) {
    return `inbox/conversation-sidebar/cards/${section}`;
  }),
});
