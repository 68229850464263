/* import __COLOCATED_TEMPLATE__ from './trigger-workflow.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type TriggerWorkflow from 'embercom/models/operator/visual-builder/step/trigger-workflow';

interface Arguments {
  step: TriggerWorkflow;
  readOnly: boolean;
  editorState: $TSFixMe;
}

export default class TriggerWorkflowComponent extends Component<Arguments> {
  get isValid() {
    return this.args.step.validations.isValid;
  }

  @action
  setSelectedWorkflow(workflow: $TSFixMe) {
    this.args.step.triggerableWorkflowId = workflow?.value;
    this.args.step.compatibleChannels = workflow?.compatibleChannels;
    this.args.step.userVisible = workflow?.userVisible;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::TriggerWorkflow': typeof TriggerWorkflowComponent;
    'workflows/graph-editor/node-items/steps/TriggerWorkflow': typeof TriggerWorkflowComponent;
  }
}
