/* import __COLOCATED_TEMPLATE__ from './polygon-icon.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    icon: InterfaceIconName;
    size?: string;
    grayScale: boolean;
  };
}

const PolygonIcon = templateOnlyComponent<Signature>();
export default PolygonIcon;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::PolygonIcon': typeof PolygonIcon;
  }
}
