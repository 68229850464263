/* import __COLOCATED_TEMPLATE__ from './editor-component.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class EditorComponent extends Component {
  @tracked workflow = this.args.workflow;
  @tracked actionEditorContext = this.args.actionEditorContext;
  @service router;
  @service notificationsService;
  @service intercomEventService;
  @service intl;
  @service intercomConfirmService;
  @service appService;
  isCreateMode = this.args.mode === 'create';

  get saveLabel() {
    if (!this.workflow.isLive) {
      return this.intl.t('settings.inbox-rules.index.workflow.save-and-close');
    } else {
      return this.intl.t('settings.inbox-rules.index.workflow.save');
    }
  }

  get saveButtonType() {
    if (!this.workflow.isLive) {
      return 'secondary';
    } else {
      return 'primary';
    }
  }

  get moreControlsGroupsList() {
    return [
      {
        items: [
          {
            text: this.intl.t('settings.inbox-rules.index.workflow.delete'),
            icon: 'trash',
            onSelectItem: () => {
              this.deleteWorkflow();
            },
          },
        ],
      },
    ];
  }

  @action
  async saveWorkflow() {
    let shouldProceed = true;

    if (this.workflow.isLive) {
      shouldProceed = await this.saveWarning();
    }

    if (shouldProceed) {
      return this.workflow
        .save()
        .then(() => {
          this.intercomEventService.trackEvent('workflows', {
            action: 'save_close',
            object: 'inbox_rule',
            place: 'inbox_automation',
            section: 'respond',
            rule_id: this.workflow.id,
          });
          this.notifyAndRoute(this.intl.t('settings.inbox-rules.index.workflow.saved'));
        })
        .catch(this.genericError.bind(this));
    }
  }

  @action
  async deleteWorkflow() {
    let confirmOptions = {
      title: this.intl.t('settings.inbox-rules.list.delete-popup.title'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t('settings.inbox-rules.list.delete-popup.delete-workflow'),
      cancelButtonText: this.intl.t('settings.inbox-rules.list.delete-popup.cancel'),
      body: this.intl.t('settings.inbox-rules.list.delete-popup.body'),
    };
    let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
    if (!isConfirmed) {
      return;
    }
    this.workflow
      .destroyRecord()
      .then(() => {
        this.notifyAndRoute(this.intl.t('settings.inbox-rules.list.delete-popup.success'));
      })
      .catch(this.genericError.bind(this));
  }

  @action
  pause() {
    this.workflow.set('status', 'paused');
    this.workflow
      .save()
      .then(() => {
        this.notifyAndRoute(this.intl.t('settings.inbox-rules.index.workflow.paused'));
      })
      .catch(() => {
        this.workflow.set('status', 'live');
        this.genericError.bind(this);
      });
  }

  @action
  async setLive() {
    let shouldProceed = await this.saveWarning();

    if (shouldProceed) {
      this.workflow.set('status', 'live');
      return this.workflow
        .save()
        .then(() => {
          this.intercomEventService.trackEvent('workflows', {
            action: 'set_live',
            object: 'inbox_rule',
            place: 'inbox_automation',
            section: 'respond',
            rule_id: this.workflow.id,
          });
          this.notifyAndRoute(this.intl.t('settings.inbox-rules.index.workflow.live'));
        })
        .catch(() => {
          this.workflow.set('status', 'paused');
          this.genericError().bind(this);
        });
    }
  }

  async saveWarning() {
    if (!this.workflow.hasSaveWarning) {
      return true;
    }

    let confirmOptions = {
      title: this.intl.t('settings.inbox-rules.index.workflow.save-warning.title'),
      primaryButtonType: 'primary-live',
      confirmButtonText: this.intl.t('settings.inbox-rules.index.workflow.save-warning.turn-it-on'),
      cancelButtonText: this.intl.t('settings.inbox-rules.index.workflow.save-warning.cancel'),
      body: this.workflow.saveWarningText,
    };
    let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
    return isConfirmed;
  }

  @action
  cancel() {
    if (this.isCreateMode) {
      this.workflow
        .destroyRecord()
        .then(() => {
          this.notifyAndRoute();
        })
        .catch(() =>
          this.notificationsService.notifyError(
            this.intl.t('settings.inbox-rules.index.workflow.cancel-error'),
          ),
        );
    } else {
      this.notifyAndRoute();
    }
  }

  notifyAndRoute(message) {
    let routeName;
    if (this.appService.app.canSeeNewIASettingsHub) {
      routeName = 'apps.app.settings.helpdesk.rules';
    } else {
      routeName = 'apps.app.settings.inbox-rules.list';
    }
    if (message) {
      this.notificationsService.notifyConfirmation(message);
    }

    this.router.transitionTo(routeName);
  }

  genericError() {
    this.notificationsService.notifyError(
      this.intl.t('settings.inbox-rules.index.workflow.generic-error'),
    );
  }
}
