/* import __COLOCATED_TEMPLATE__ from './graph-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import Graph from 'graph-editor/models/graph-editor/graph';
import GraphLayout from 'embercom/objects/workflows/graph-editor/graph-layout';
import { tracked } from '@glimmer/tracking';
import { set, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import platform from 'embercom/lib/browser-platform';
import { isEmpty } from '@ember/utils';
import { modifier } from 'ember-modifier';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { taskFor } from 'ember-concurrency-ts';
import type Store from '@ember-data/store';
import type TracingService from 'embercom/services/tracing';
import type IntlService from 'embercom/services/intl';
import type LogService from 'embercom/services/log-service';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';
import type Node from 'graph-editor/models/graph-editor/node';
import type Edge from 'graph-editor/models/graph-editor/edge';

const UPDATE_GRAPH_EDITOR_DELAY = 50;
export const EXPAND_PATH_LIMIT = 25;
const SHORTCUTS_DISABLED_FOR_TARGETS = ['embercom-prosemirror-composer-editor'];

interface Signature {
  Args: {
    workflow: Workflow;
    editorConfiguration: EditorConfig;
    shouldShowValidations: boolean;
    isViewOnly: boolean;
    infoPanelTriggerData?: $TSFixMe;
    triggerInfoNodeSidesheetIsDismissed?: boolean;
    forceExpandAllGroups?: boolean;
    isTemplatePreview?: boolean;
    trackingMetadata?: $TSFixMe;
  };
  Element: HTMLDivElement;
}

export default class WorkflowsGraphEditor extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare intercomEventService: $TSFixMe;
  @service declare tracing: TracingService;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare logService: LogService;
  @tracked editorState: EditorState;

  graph: Graph;
  layout: GraphLayout;
  staticInfoPanelNode: Node | null = null;
  staticInfoPanelEdge: Edge | null = null;
  tracingSpans: { 'initial-render'?: $TSFixMe } = {};

  constructor(owner: unknown, args: ArgsOf<WorkflowsGraphEditor>) {
    super(owner, args);

    let workflow = this.args.workflow;
    workflow.editorConfig = this.args.editorConfiguration;
    workflow.trackingMetadata = this.args.trackingMetadata;
    let stepCount = workflow.groups.reduce<number>(
      (memo, group) => memo + Number(group.steps.length),
      0,
    );
    let edgeCount = workflow.groups.reduce<number>(
      (memo, group) => memo + Number(group.inwardEdges.length),
      0,
    );
    this.tracing.tagRootSpan({ visual_bot_builder_v2: true });
    this.tracingSpans['initial-render'] = this.tracing.startChildSpan(
      'componentRender',
      'workflows-graph-editor',
      {
        'operator_workflow.group_count': workflow.groups.length,
        'operator_workflow.step_count': stepCount,
        'operator_workflow.edge_count': edgeCount,
      },
    );

    this.graph = new Graph({
      canInsertEdge: this.canInsertEdge.bind(this),
      didInsertEdge: this.didInsertEdge.bind(this),
      didDeleteNode: this.didDeleteNode.bind(this),
      didDeleteEdge: this.didDeleteEdge.bind(this),
      canSelectEdge: this.canSelectEdge.bind(this),
    });
    this.layout = new GraphLayout({
      graph: this.graph,
      workflow: this.args.workflow,
      isViewOnly: this.args.isViewOnly,
      hasTriggerInfoPanel: this.canUseTriggerInfoNode,
    });

    this.editorState = new EditorState({
      store: this.store,
      workflow: this.args.workflow,
      layout: this.layout,
      shouldShowValidations: this.args.shouldShowValidations,
      logService: this.logService,
    });
    this.editorState.loadDataForEditingActions();

    this.layout.draw();

    if (this.args.forceExpandAllGroups || Number(this.graph.nodes.length) <= EXPAND_PATH_LIMIT) {
      this.graph.nodes.forEach((group) => group.dataObject.toggleExpand(true));
    }

    this.trackInteractionEvent();

    if (this.canUseTriggerInfoNode) {
      this.createStaticInfoPanel();
    }
  }

  get inScrollWheelZoomMode() {
    return this.graph.settings.scroll.inScrollWheelZoomMode;
  }

  get canUseTriggerInfoNode() {
    return this.args.infoPanelTriggerData;
  }

  get isAllPathsMinimized() {
    return !this.graph.nodes.any((node) => node.dataObject.isExpanded);
  }

  get isAllPathsInViewExpanded() {
    return this.graph.nodes
      .filter((node) => {
        return node.bounds.containedWithin(this.graph.state.viewportBounds);
      })
      .every((node) => node.dataObject.isExpanded);
  }

  get isViewOnly() {
    return this.args.isViewOnly ? this.args.isViewOnly : false;
  }

  createStaticInfoPanel() {
    let startingNode =
      this.graph.nodes.find((node) => node.dataObject.isStart) ?? this.graph.nodes.firstObject;
    if (startingNode) {
      let { staticInfoPanelNode, staticInfoPanelEdge } =
        this.layout.createStaticNodeAndEdge(startingNode);
      this.staticInfoPanelNode = staticInfoPanelNode;
      this.staticInfoPanelEdge = staticInfoPanelEdge;
    } else {
      throw new Error('Unable to find the starting Node');
    }
  }

  @task({ restartable: true })
  *updateConnectionPoints(node: Node) {
    yield timeout(UPDATE_GRAPH_EDITOR_DELAY);
    this.layout.updateConnectionPointsForNode(node);
  }

  @task({ restartable: true })
  *updateGridDimensions(node: Node) {
    yield timeout(UPDATE_GRAPH_EDITOR_DELAY);
    this.layout.updateGridDimensions([node]);
  }

  @task({ enqueue: true })
  *updateNodePositionsInColumn(node: Node) {
    this.layout.repositionNodesInColumn(node.dataObject.layoutColumnIndex);
    this.layout.updateConnectionPointsForNode(node);
    this.layout.updateGridDimensions([node]);
    yield;
  }

  @action
  didUpdateConnectionPoints(node: Node) {
    taskFor(this.updateConnectionPoints).perform(node);
  }

  @action
  didUpdateNodeHeight(node: Node) {
    taskFor(this.updateNodePositionsInColumn).perform(node);
  }

  @action
  deleteEdge(edge: Edge) {
    this.editorState.deleteEdge(edge.dataObject, { trigger: 'button' });
  }

  @action
  repositionNodes() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'tidy_up',
      object: 'visual_editor',
    });
    this.layout.repositionNodes();
  }

  @action
  recenterView() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'recenter_view',
      object: 'visual_editor',
    });

    // we always expect there to be a starting node, but we fallback to firstObject just in case
    let startingNode =
      this.graph.nodes.find((node) => node.dataObject.isStart) ?? this.graph.nodes.firstObject;
    if (startingNode) {
      this.layout.travelToNode(startingNode);
    } else {
      throw new Error('Unable to find the starting Node');
    }
  }

  endTracingSpanModifier = modifier(() => {
    this.tracing.endChildSpan({ span: this.tracingSpans['initial-render'] });
  });

  @action minimizeAllPaths() {
    this.graph.nodes.forEach((node) => node.dataObject.toggleExpand(false));
  }

  @action expandAllPathsInView() {
    this.graph.nodes
      .filter((node) => {
        return node.bounds.containedWithin(this.graph.state.viewportBounds);
      })
      .forEach((node) => node.dataObject.toggleExpand(true));
  }

  @action shortcutsHandler(event: KeyboardEvent) {
    if (!this.args.isViewOnly) {
      if (
        // This prevents keyboard shortcuts that behave differently when a particular element is in focus from being picked up by the graph
        // E.g. [CMD+A] while in the annotations composer should select all text in the composer, not all nodes on the graph
        SHORTCUTS_DISABLED_FOR_TARGETS.some((target) =>
          (event.target as HTMLElement).classList?.contains(target),
        )
      ) {
        return;
      }
      if (event.key === 'Delete' || event.key === 'Backspace') {
        this.onDeleteShortcut();
      }
    }
  }

  get modifierKey() {
    return platform.isMac ? '⌘' : 'ctrl';
  }

  onDeleteShortcut() {
    if (this.graph.state.selectedEdge && isEmpty(this.graph.state.selectedNodes)) {
      let edge = this.graph.state.selectedEdge;

      if (edge.dataObject.toGroup.isPlaceholder) {
        this.editorState.deleteGroup(edge.dataObject.toGroup, { trigger: 'keyboard' });
      }
      this.editorState.deleteEdge(edge.dataObject, { trigger: 'keyboard' });
    }
  }

  /*
   * GRAPH EDITOR HOOKS
   */
  canInsertEdge(predecessor: Node, successor: Node) {
    if (successor.dataObject.isPlaceholder) {
      return false;
    }

    if (predecessor === successor) {
      return false;
    }

    return !this.args.isViewOnly;
  }

  didInsertEdge(edge: Edge) {
    let placeholderEdge = edge.predecessor.edges.find(
      (predecessorEdge) =>
        predecessorEdge.dataObject.outwardConnectionPoint.id ===
          edge.dataObject.outwardConnectionPoint.id &&
        predecessorEdge.successor?.dataObject.isPlaceholder,
    );
    if (placeholderEdge && placeholderEdge.successor) {
      this.editorState.deleteGroup(placeholderEdge.successor.dataObject);
    }

    let edgeModel = this.editorState.createEdge({
      outwardConnectionPoint: edge.dataObject.outwardConnectionPoint,
      toGroup: edge.successor?.dataObject,
    });

    set(edge, 'dataObject', edgeModel);

    taskFor(this.updateConnectionPoints).perform(edge.predecessor);
  }

  didDeleteNode(node: Node) {
    // Skip analytics tracking for deletion events triggered by GraphEditor hooks
    this.editorState.deleteGroup(node.dataObject, { skipAnalyticsTracking: true });
  }

  didDeleteEdge(edge: Edge) {
    taskFor(this.updateConnectionPoints).perform(edge.predecessor);

    // Skip analytics tracking for deletion events triggered by GraphEditor hooks
    this.editorState.deleteEdge(edge.dataObject, { skipAnalyticsTracking: true });
  }

  canSelectEdge(edge: Edge) {
    return !edge.dataObject.isStatic;
  }

  trackInteractionEvent() {
    if (this.args.isViewOnly) {
      return;
    }

    let eventName =
      this.args.workflow.workflowInstanceEntityType === objectTypes.answer
        ? 'has_interacted_with_custom_answer_visual_builder'
        : 'has_interacted_with_visual_bot_builder';
    this.intercomEventService.trackEvent(eventName);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor': typeof WorkflowsGraphEditor;
    'workflows/graph-editor': typeof WorkflowsGraphEditor;
  }
}
