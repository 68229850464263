/* import __COLOCATED_TEMPLATE__ from './answer-terminal.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';

export default class AnswerTerminal extends Component {
  get icon() {
    switch (this.args.step.endingType) {
      case 'pass_to_team':
        return 'multiple-people';
      case 'show_standard_replies':
        return 'respond';
      case 'resolve':
        return 'rounded-check';
      case 'send_ticket':
        return 'ticket';
      case 'finish_answer':
        return 'stop';
      default:
        return 'respond';
    }
  }

  get shouldShowError() {
    return this.args.editorState.shouldShowValidations && !this.args.step.validations.isValid;
  }
}
