/* import __COLOCATED_TEMPLATE__ from './slack-channel-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';

interface Arguments {
  action: any;
  isDisabled: boolean;
  onChange: (slackChannelId: string, slackChannelName: string) => void;
}

type BotChannel = {
  name: string;
  id: string;
};

export default class SlackChannelSelector extends Component<Arguments> {
  @service declare appService: any;
  @tracked botChannels: BotChannel[] = [];

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
    taskFor(this.fetchingSlackChannels).perform();
  }

  @task({ drop: true })
  *fetchingSlackChannels(): TaskGenerator<void> {
    let { app } = this.appService;
    let params = {
      app_id: app.id,
    };
    let channels = yield get('/ember/slack_notifications_settings', params);
    this.botChannels = channels.bot_channels;
  }

  get slackChannels() {
    let channels = this.botChannels.map((channel) => ({
      text: `#${channel.name}`,
      value: channel.id,
    }));
    if (
      this.slackChannelId !== undefined &&
      channels.every((channel) => channel.value !== this.slackChannelId)
    ) {
      channels.push({
        text: this.args.action.actionData?.slack_channel_name || `${this.slackChannelId}`,
        value: this.slackChannelId,
      });
    }
    return channels;
  }
  get slackChannelId() {
    return this.args.action.actionData?.slack_channel_id;
  }

  get slackChannel() {
    return this.slackChannelName(this.slackChannelId);
  }

  slackChannelName(slackChannelId: string) {
    if (slackChannelId === undefined) {
      return '';
    }

    let matchedSlackChannel = this.slackChannels.find(
      (slackChannel) => slackChannel.value === slackChannelId,
    );
    return matchedSlackChannel?.text || slackChannelId;
  }

  @action
  setSlackChannelId(slackChannelId: string) {
    if (this.args.onChange) {
      this.args.onChange(slackChannelId, this.slackChannelName(slackChannelId));
    } else {
      this.args.action.actionData = {
        slack_channel_type: 'public',
        slack_channel_id: slackChannelId,
        slack_channel_name: this.slackChannelName(slackChannelId),
      };
    }
  }

  @action
  close() {
    this.args.action.justAdded = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::NotifySlackChannel::SlackChannelSelector': typeof SlackChannelSelector;
    'workflows/graph-editor/node-items/steps/notify-slack-channel/slack-channel-selector': typeof SlackChannelSelector;
  }
}
