/* import __COLOCATED_TEMPLATE__ from './predicates-editor-component.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { copy } from 'ember-copy';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class PredicatesEditorComponent extends Component {
  @service attributeService;
  @service store;

  @tracked predicateGroup = this.args.workflow.predicateGroup;

  constructor() {
    super(...arguments);

    this.store.findAll('inbox/ticket-type');
  }

  get attributeGroupList() {
    return this.attributeService.workflowConditionAttributes;
  }

  @action
  onPredicatesUpdate(predicates) {
    this.args.workflow.set('predicateGroup.predicates', copy(predicates));
  }
}
