/* import __COLOCATED_TEMPLATE__ from './ask-for-rating.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    accordion: $TSFixMe;
    step: $TSFixMe;
    isReadOnly: boolean;
    thatHelpedButtonLabel: string;
    triageButtonLabel: string;
    channelIncompatibleWithFinCSAT: boolean;
    showCsatBlockAfterUpdate: boolean;
    toggleCsatEnabled: () => void;
    toggleCsatEnabledForHardResolution: () => void;
    toggleCsatEnabledForSoftResolution: () => void;
    toggleRatingChange: () => void;
    setBlockUpdateAfter: (e: InputEvent) => void;
  };
}

const AskForRating = templateOnlyComponent<Signature>();
export default AskForRating;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Fin::Sections::AskForRating': typeof AskForRating;
  }
}
