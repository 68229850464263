/* import __COLOCATED_TEMPLATE__ from './apply-rules.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type ApplyRules from 'embercom/models/operator/visual-builder/step/apply-rules';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';

interface Arguments {
  step: ApplyRules;
  readOnly: boolean;
  editorState: any;
  editorConfiguration: EditorConfig;
}

export default class ApplyRulesNodeItem extends Component<Arguments> {
  @tracked rules = this.args.step.followUpRules;

  @action
  addRule() {
    this.args.step.addRule();
  }

  get shouldShowError() {
    return (
      this.args.editorState.shouldShowValidations &&
      (this.args.step.validations.attrs.supportedContexts.isInvalid ||
        this.args.step.validations.isInvalid)
    );
  }

  get supportedPredicateAttributes() {
    return this.args.editorConfiguration.runtimeMatchingTargetingAttributeIdentifiers;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ApplyRules': typeof ApplyRulesNodeItem;
    'workflows/graph-editor/node-items/steps/apply-rules': typeof ApplyRulesNodeItem;
  }
}
