/* import __COLOCATED_TEMPLATE__ from './user-event-summary-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';
import { applyFunction, ternary } from '@intercom/pulse/lib/computed-properties';
import numberFormatter from 'embercom/lib/number-formatter';
import moment from 'moment-timezone';

export default Component.extend({
  arrowIconSet: ternary('summary.expanded', 'small-thin-down-arrow', 'small-thin-right-arrow'),
  settingsService: service('settingsService'),
  appTimezone: reads('settingsService.timezone'),
  dateObjectFromTimeStamp(timestamp) {
    let parsedDate = moment(timestamp);
    return { day: parsedDate.date(), month: parsedDate.month() + 1, year: parsedDate.year() };
  },
  predicateObject(attribute, type, value) {
    return [{ attribute, comparison: 'eq', type, value }];
  },
  lastPredicateAttribute: computed('summary.name', function () {
    return `user_event_summaries.${this.summary.name}.last`;
  }),
  lastPredicate: computed('lastPredicateAttribute', 'summary.last', function () {
    return this.predicateObject(
      this.lastPredicateAttribute,
      'user_event_date',
      this.dateObjectFromTimeStamp(this.get('summary.last')),
    );
  }),
  lastFilterUrl: computed('user', 'lastPredicate', function () {
    return B64FilterParamEncoder.filterUrlFromPredicates(this.user, {
      predicates: this.lastPredicate,
    });
  }),

  firstPredicateAttribute: computed('summary.name', function () {
    return `user_event_summaries.${this.summary.name}.first`;
  }),
  firstPredicate: computed('firstPredicateAttribute', 'summary.first', function () {
    return this.predicateObject(
      this.firstPredicateAttribute,
      'user_event_date',
      this.dateObjectFromTimeStamp(this.get('summary.first')),
    );
  }),
  firstFilterUrl: computed('user', 'lastPredicate', function () {
    return B64FilterParamEncoder.filterUrlFromPredicates(this.user, {
      predicates: this.firstPredicate,
    });
  }),

  formattedCount: applyFunction(numberFormatter, 'summary.count'),
  countPredicateAttribute: computed('summary.name', function () {
    return `user_event_summaries.${this.summary.name}.count`;
  }),
  countPredicate: computed('countPredicateAttribute', 'summary.count', function () {
    return this.predicateObject(
      this.countPredicateAttribute,
      'user_event_integer',
      this.get('summary.count'),
    );
  }),
  countFilterUrl: computed('userOrCompany', 'attribute', 'rawValue', function () {
    return B64FilterParamEncoder.filterUrlFromPredicates(this.user, {
      predicates: this.countPredicate,
    });
  }),

  actions: {
    expandToggle() {
      this.toggleProperty('summary.expanded');
    },
  },
});
