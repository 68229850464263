/* import __COLOCATED_TEMPLATE__ from './schedule-header.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';

export type ScheduleHeaderArgs = {
  isDisabled: boolean;
  config: any;
  section: any;
};

interface Signature {
  Element: HTMLDivElement;
  Args: ScheduleHeaderArgs;
}

const ScheduleHeader = templateOnlyComponent<Signature>();
export default ScheduleHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::TriggerInfoNode::ScheduleHeader': typeof ScheduleHeader;
  }
}
