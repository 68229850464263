/* import __COLOCATED_TEMPLATE__ from './conditional-branches.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import AiAgentWorkflowEditorConfig from 'embercom/objects/visual-builder/configuration/editor/ai-agent-workflow';

export default class ConditionalBranchesNodeItem extends Component {
  @service intercomEventService;

  @tracked branches = this.args.step.outwardConnectionPoints;

  get attributeGroupList() {
    return this.args.editorConfiguration.runtimeMatchingTargetingAttributes;
  }

  get attributeListOverride() {
    if (this.args.editorConfiguration instanceof AiAgentWorkflowEditorConfig) {
      return this.attributeGroupList?.map((group) => group.attributes).flat();
    }

    return null;
  }

  @action
  reorderBranches(sortedBranches, branch) {
    let branches = sortedBranches.slice();
    let elseBranch = this.branches.lastObject;
    branches.push(elseBranch);

    set(this.args.step, 'outwardConnectionPoints', branches);

    this.intercomEventService.trackAnalyticsEvent({ object: branch, action: 'reordered' });
    this.args.editorState.markAsEdited();

    this.args.editorState.logInteraction(`Reordered conditional branches`);
  }

  @action
  addBranch() {
    this.args.step.addBranch();
    this.args.editorState.logInteraction('Added conditional branch');
  }
}
