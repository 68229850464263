/* import __COLOCATED_TEMPLATE__ from './path-menu.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type IntlService from 'embercom/services/intl';
import type Group from 'embercom/models/operator/visual-builder/group';

interface Args {
  editorState: EditorState;
  group: Group;
}

export default class PathMenu extends Component<Args> {
  @service declare intl: IntlService;

  @tracked showDeletePathModal?: boolean;

  get groupList(): { items: { text: string; icon: string; onSelectItem: () => void }[] }[] {
    return [
      {
        items: [
          {
            text: this.intl.t('operator.workflows.visual-builder.delete-path'),
            icon: 'trash',
            onSelectItem: () => this.triggerDeletePathModal(),
          },
        ],
      },
    ];
  }

  get layout() {
    return this.args.editorState.layout;
  }

  get isViewOnly() {
    return this.layout.isViewMode;
  }

  get graph() {
    return this.layout.graph;
  }

  get node() {
    return this.layout.findNodeForGroup(this.args.group);
  }

  @action
  onShowDropdown() {
    if (!this.node) {
      throw new Error('Node should be defined');
    }

    this.graph.state.addSelectedNode(this.node);
  }

  @action
  onHideDropdown() {
    if (!this.node) {
      throw new Error('Node should be defined');
    }

    this.graph.state.removeSelectedNode(this.node);
  }

  @action
  triggerDeletePathModal() {
    this.showDeletePathModal = true;
  }

  @action
  deleteGroup(group: Group) {
    this.args.editorState.deleteGroup(group);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::Node::PathMenu': typeof PathMenu;
    'workflows/graph-editor/node/path-menu': typeof PathMenu;
  }
}
