/* import __COLOCATED_TEMPLATE__ from './recent-actions.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type SetupAction from 'embercom/models/workflow-connector/setup-action';

export interface Args {
  actions: SetupAction[];
  totalActionsCount: number;
  totalLiveActionsCount: number;
}

export default class RecentActions extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;

  constructor(owner: unknown, args: any) {
    super(owner, args);
  }

  get customActionsRoute() {
    return `${this.actionsRoutePrefix}.custom-action`;
  }

  get customActionsIndexRoute() {
    return `${this.actionsRoutePrefix}.index`;
  }

  @action notifyRecentActionClicked(actionId: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'recent_action_link',
      action_id: actionId,
    });
  }

  @action goToActionsIndex() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'manage_all_link',
    });
    this.router.transitionTo(this.customActionsIndexRoute);
  }

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) => `workflow-connector.setup.recent-actions.${path}`;

  private get actionsRoutePrefix() {
    return 'apps.app.settings.app-settings.custom-actions';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Setup::RecentActions': typeof RecentActions;
    'workflow-connector/setup/recent-actions': typeof RecentActions;
  }
}
