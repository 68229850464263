/* import __COLOCATED_TEMPLATE__ from './end.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
}

const EndComponent = templateOnlyComponent<Signature>();
export default EndComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::Markers::End': typeof EndComponent;
  }
}
