/* import __COLOCATED_TEMPLATE__ from './user-hash-checker-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import HmacSHA256 from 'crypto-js/hmac-sha256';

export default Component.extend({
  secret: null,
  hashData: null,

  actions: {
    computeUserHash() {
      let key = this.secret;
      let data = this.hashData;
      let userHash = '';
      if (key && data) {
        userHash = HmacSHA256(data, key).toString();
      }
      this.set('userHash', userHash);
    },
  },
});
