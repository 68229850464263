/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import type FinCustom from 'embercom/models/operator/visual-builder/step/fin-custom';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import ComposerConfig from 'embercom/objects/workflows/graph-editor/chat-message/composer-config';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import type { Block } from 'embercom/models/common/blocks/block';

interface Signature {
  Args: Arguments;
  Element: HTMLDivElement;
}
interface Arguments {
  step: FinCustom;
  isReadOnly: boolean;
}
export default class Settings extends Component<Signature> {
  blocksDoc: BlocksDocument = new BlocksDocument([]);
  @service intl: any;
  @service appService: any;
  @service attributeService: any;
  @service store: any;
  @service contentEditorService: any;

  @tracked composerApi: any;
  @tracked openInserters: any;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
    this._populateOptions();
  }

  get app() {
    return this.appService.app;
  }
  _populateOptions() {
    this.blocksDoc = new BlocksDocument(this.args.step.blocks);
  }

  get composerConfig() {
    let config = new ComposerConfig({
      app: this.app,
      resolver: this.attributeInfoResolver,
      placeholder: this.intl.t('operator.workflows.visual-builder.fin-custom-placeholder'),
      allowMentions: true,
    });

    return config;
  }

  get attributeInfoResolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorMetadataAndConversationAttributes,
      customObjectAttributes: this.attributeService.customObjectsAttributeDescriptors,
      includeAppAttributes: true,
      includeCustomObjectAttributes: true,
      localAttributes: this.args.step.editorConfig?.localAttributes,
    });
  }

  @action
  updateBlocks(blocksDoc: BlocksDocument) {
    let store = this.store;
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(store, block),
    );
    this.args.step.blocks = blockFragments;
    // this is a known workaround to an issue with creating new fragments on a tracked model in a different component
    // without this `get serializedBlocks()` doesn't recompute and the chat message appears not to update
    this.args.step.notifyPropertyChange('blocks');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::Settings': typeof Settings;
    'workflows/graph-editor/node-items/steps/fin-custom/settings': typeof Settings;
  }
}
