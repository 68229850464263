/* import __COLOCATED_TEMPLATE__ from './control-answer-type.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    accordion: $TSFixMe;
    step: $TSFixMe;
    isReadOnly: boolean;
    customAnswerCount: number;
    canUseAiAnswers: boolean;
    answerTypeSummary: string;
    oneTimeModeAvailable: boolean;
    targetingEmail: boolean;
    toggleAiAnswers: () => void;
    toggleCustomAnswers: () => void;
    toggleOneTimeMode: () => void;
  };
}

const ControlAnswerType = templateOnlyComponent<Signature>();
export default ControlAnswerType;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Fin::Sections::ControlAnswerType': typeof ControlAnswerType;
  }
}
