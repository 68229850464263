/* import __COLOCATED_TEMPLATE__ from './value-picker.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import type SetUserDataAttribute from 'embercom/models/operator/visual-builder/step/set-user-data-attribute';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import templateOnlyComponent from '@ember/component/template-only';

interface Arguments {
  step: SetUserDataAttribute;
  dataAttributeName: string;
  dataAttributeValue: any;
  editorState: EditorState;
}

const ValuePicker = templateOnlyComponent<Arguments>();
export default ValuePicker;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::SetUserDataAttribute::ValuePicker': typeof ValuePicker;
    'workflows/graph-editor/node-items/steps/set-user-data-attribute/value-picker': typeof ValuePicker;
  }
}
