/* import __COLOCATED_TEMPLATE__ from './custom-action-editable-name.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  workflowAction: $TSFixMe;
}

interface Signature {
  Args: Args;
  Element: Element;
}

const CustomActionEditableName = templateOnlyComponent<Signature>();
export default CustomActionEditableName;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::CustomActionEditableName': typeof CustomActionEditableName;
    'workflow-connector/custom-action-editable-name': typeof CustomActionEditableName;
  }
}
