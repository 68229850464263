/* import __COLOCATED_TEMPLATE__ from './create-settings.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import type GitHubCreateIssue from 'embercom/models/operator/visual-builder/step/github-create-issue';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { get } from 'embercom/lib/ajax';

interface Arguments {
  step: GitHubCreateIssue;
}

export default class CreateSettings extends Component<Arguments> {
  @service store: any;
  @service declare appService: any;
  @service intl: any;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
    taskFor(this.fetchRepositories).perform();
  }

  @tracked declare repoList: [{ items: any[] }];
  @tracked declare templateList: [{ items: any[] }];
  @tracked declare mainRepository: string;

  @action
  updateRepo(repo: string) {
    this.args.step.repositoryName = repo;
    taskFor(this.fetchTemplates).perform();
  }

  @action
  updateTemplate(template: string) {
    this.args.step.templatePath = template;
  }

  @action
  updateUseMainRepository() {
    this.args.step.toggleProperty('useMainRepository');
    this.args.step.notifyPropertyChange('useMainRepository');
    if (this.args.step.useMainRepository) {
      this.args.step.repositoryName = this.mainRepository;
      taskFor(this.fetchTemplates).perform();
    }
  }

  @task
  *fetchRepositories(): TaskGenerator<void> {
    let { app } = this.appService;
    let params = {
      app_id: app.id,
    };
    let repositories = yield get('/ember/github_webhooks', params);
    this.repoList = [
      {
        items: repositories.map((repo: any) => ({
          text: repo.name,
          value: repo.name,
        })),
      },
    ];
    this.args.step.repositoryName = this.args.step.repositoryName || repositories[0]?.name;

    let integration = yield get('/ember/github_integrations', params);
    this.mainRepository = repositories.find(
      (repo: any) => repo.id === integration.default_github_webhook_config_id,
    )?.name;

    if (this.args.step.useMainRepository) {
      this.args.step.repositoryName = this.mainRepository;
    }

    taskFor(this.fetchTemplates).perform();
  }

  @task
  *fetchTemplates(): TaskGenerator<void> {
    let availableTemplates = yield get('/ember/github_integrations/issue_templates', {
      id: this.appService.app.id,
      repository_full_name: this.args.step.repositoryName,
    });
    this.templateList = [
      {
        items: [
          {
            text: this.intl.t(
              'operator.workflows.visual-builder.github-create-issue.settings.none-text',
            ),
            value: '_none_',
          },
          ...availableTemplates.map((template: any) => ({ text: template, value: template })),
        ],
      },
    ];

    if (!this.isTemplateValid()) {
      this.args.step.templatePath = this.templateList[0].items[0].value;
    }
  }

  isTemplateValid() {
    return (
      this.templateList[0].items.find(
        (template: any) => template.value === this.args.step.templatePath,
      ) !== undefined
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::GithubIntegration::CreateSettings': typeof CreateSettings;
    'workflows/graph-editor/node-items/steps/github-integration/create-settings': typeof CreateSettings;
  }
}
