/* import __COLOCATED_TEMPLATE__ from './send-ticket.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import intermoji from '@intercom/intermoji';
import { action } from '@ember/object';
import { TicketCategory } from 'embercom/objects/inbox/ticket';

const DEFAULT_COMPONENT_TEMPLATE = 'workflows/graph-editor/popover-menu/popover-menu-item';
const CATEGORY_CONFIG = {
  action: {
    iconBGClass: 'workflows__graph-editor__node-items__steps__action-icon-bg hover:text-white',
    iconColour: 'white',
  },
  message: {
    iconBGClass: 'workflows__graph-editor__node-items__steps__message-icon-bg',
    iconColour: 'black',
  },
};

export default class SendTicketEditor extends Component {
  @service appService;
  @service store;
  @service intl;
  @tracked ticketTypes;

  @task({ drop: true })
  *fetchTicketTypes() {
    let ticketTypes = yield this.store.findAll('inbox/ticket-type');

    ticketTypes = ticketTypes
      .reject(({ archived, internal, isTracker }) => archived || internal || isTracker)
      .sortBy('name');

    this.ticketTypes = ticketTypes;
  }

  categoryName(category) {
    switch (category) {
      case TicketCategory.Request:
        return this.intl.t('operator.workflows.visual-builder.send-ticket-menu.category.request');
      case TicketCategory.Task:
        return this.intl.t('operator.workflows.visual-builder.send-ticket-menu.category.task');
    }
  }

  get selectListItemsByCategory() {
    if (!this.ticketTypes) {
      return [];
    }

    return this.ticketTypes.map((ticketType) => ({
      text: ticketType.name,
      value: ticketType.id,
      emojiUri: ticketType.emojiUri,
      category: this.categoryName(ticketType.category),
      component: 'workflows/graph-editor/node-items/steps/send-ticket-item',
    }));
  }

  get loading() {
    return this.fetchTicketTypes.isRunning || !this.ticketTypes;
  }

  get selectedTicketType() {
    if (!this.ticketTypes) {
      return null;
    }

    return this.ticketTypes.find((item) => item.id === `${this.args.step.ticketTypeId}`);
  }

  get emojiUri() {
    if (this.selectedTicketType) {
      return intermoji.twemojiSVGUri(this.selectedTicketType.emoji);
    }
  }

  get stepInsertionOptions() {
    let items = this.args.step.disableComposer
      ? []
      : [
          {
            text: this.intl.t(
              'operator.workflows.visual-builder.send-ticket-menu.disable-composer',
            ),
            icon: 'remove',
            value: 'disable-composer',
            onSelectItem: this.disableComposer,
            component: DEFAULT_COMPONENT_TEMPLATE,
            groupConfig: CATEGORY_CONFIG.action,
          },
        ];
    return [{ items }];
  }

  get hasError() {
    return !this.loading && !this.args.step.ticketTypeId;
  }

  get shouldShowError() {
    return (
      this.args.editorState?.shouldShowValidations &&
      (this.args.step.validations.attrs.supportedChannels.isInvalid ||
        this.args.step.validations.attrs.supportedContexts.isInvalid)
    );
  }

  @action
  disableComposer() {
    this.args.step.disableComposer = true;
  }
}
