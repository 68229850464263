/* import __COLOCATED_TEMPLATE__ from './user-sort-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
// eslint-disable-next-line @intercom/intercom/no-legacy-dropdown
import ComponentDropdownToggler from 'embercom/components/mixins/component-dropdown-toggler';
import { rejectByProperty } from '@intercom/pulse/lib/computed-properties';

export default Component.extend(ComponentDropdownToggler, {
  selectableAttributes: rejectByProperty(
    'profileDisplayedUserAttributes',
    'identifier',
    'selectedSortAttribute.identifier',
  ),
  actions: {
    select(attribute) {
      this.set('selectedSortAttribute', attribute);
    },
  },
});
