/* import __COLOCATED_TEMPLATE__ from './assign-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ListItemComponent extends Component {
  @service appService;
  @tracked action = this.args.action;

  get assigneeName() {
    return this.assignee.displayAs;
  }

  get assignee() {
    // temporary string conversion to handle migrated rules that have integer ids
    let assigneeId = `${this.args.action.actionData.assignee_id}`;

    let assignee = this.appService.app.teams.findBy('id', assigneeId);
    if (assignee === undefined) {
      assignee = this.appService.app.admins.findBy('id', assigneeId);
    }

    return assignee;
  }
}
