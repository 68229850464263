/* import __COLOCATED_TEMPLATE__ from './hard-resolution-workflow.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import type Fin from 'embercom/models/operator/visual-builder/step/fin';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    accordion: $TSFixMe;
    editorState: EditorState;
    step: Fin;
    isReadOnly: boolean;
  };
}

class HardResolutionWorkflow extends Component<Signature> {
  @service store: $TSFixMe;

  get step(): Fin {
    return this.args.step;
  }

  get isHardResolutionWorkflowEnabled() {
    return !!this.step.hardResolutionWorkflow;
  }

  get triggerableWorkflowId() {
    return this.step.hardResolutionWorkflow?.triggerableWorkflowId;
  }

  get isValid() {
    return this.args.step.validations.attrs.hardResolutionWorkflow.isValid;
  }

  @action
  toggleContinueWithFin() {
    if (!this.args.step.hardResolutionWorkflow) {
      return;
    }
    this.args.step.hardResolutionWorkflow.continueWithFin =
      !this.args.step?.hardResolutionWorkflow.continueWithFin;
  }

  @action
  toggleHardResolutionWorkflow() {
    if (this.isHardResolutionWorkflowEnabled) {
      this.step.hardResolutionWorkflow = null;
    } else {
      this.step.hardResolutionWorkflow = this.store.createFragment(
        'operator/fin/hard-resolution-workflow',
      );
    }
  }

  @action
  setSelectedWorkflow(workflow: $TSFixMe) {
    if (this.step.hardResolutionWorkflow) {
      this.step.hardResolutionWorkflow.triggerableWorkflowId = workflow?.value;
      this.step.hardResolutionWorkflow.compatibleChannels = workflow?.compatibleChannels;
    } else {
      console.error('hardResolutionWorkflow is not set');
    }
  }
}

export default HardResolutionWorkflow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Fin::Sections::HardResolutionWorkflow': typeof HardResolutionWorkflow;
    'workflows/graph-editor/node-items/steps/fin/sections/hard-resolution-workflow': typeof HardResolutionWorkflow;
  }
}
