/* import __COLOCATED_TEMPLATE__ from './user-list-column-header-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
export default Component.extend({
  tagName: 'th',
  classNames: ['tbl__cell', 't__label', 'o__fixed-header'],
  classNameBindings: ['classForHeader'],
});
