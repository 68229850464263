/* import __COLOCATED_TEMPLATE__ from './attribute-collector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class AttributeCollector extends Component {
  @service attributeService;

  get attributes() {
    let attributes = this.attributeService.botAutoMessageCollectableAttributes;

    if (this.args.step.editorConfig?.localAttributes) {
      attributes = attributes.concat(
        this.args.step.editorConfig.localAttributes.filter((attribute) => !attribute.referenceOnly),
      );
    }

    return attributes;
  }

  get attribute() {
    return this.attributes.find(
      (attribute) => attribute.identifier === this.args.step.attributeIdentifier,
    );
  }
}
