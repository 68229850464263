/* import __COLOCATED_TEMPLATE__ from './tag-user.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Tag from 'embercom/models/tag';

interface Arguments {
  action: any;
  actionEditorContext: any;
}

export default class TagUserAction extends Component<Arguments> {
  @service declare appService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  get tags() {
    return this.app.tags
      .filter((tag: Tag) => !tag.archived)
      .map((tag: Tag) => {
        return {
          name: tag.name,
          id: tag.id,
        };
      });
  }

  get tagName() {
    let { tag_id: tagId, tag: tagName } = this.args.action.actionData;

    return (
      (tagId
        ? this.tags.find((tag: Tag) => tag.id === tagId)?.name
        : this.tags.find((tag: Tag) => tag.name === tagName)?.name) || ''
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ApplyRules::Actions::TagUser': typeof TagUserAction;
    'workflows/graph-editor/node-items/steps/apply-rules/actions/tag-user': typeof TagUserAction;
  }
}
