/* import __COLOCATED_TEMPLATE__ from './response-mapping.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';

export default class ResponseMapping extends Component {
  @service store;
  @service intl;
  @service intercomEventService;
  @service customObjectsService;
  @service appService;
  @tracked workflowAction = this.args.workflowAction;

  @action nextSection() {
    this.args.nextSection('general_section');
  }

  @action addObjectMapping() {
    let objectMapping = this.store.createFragment('workflow-connector/action-object-mapping', {
      workflowAction: this.workflowAction,
    });
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'new_object_mapping',
      action_id: this.workflowAction.id,
    });

    this.workflowAction.objectMappings.pushObject(objectMapping);
  }

  @action deleteObjectMapping(objectMapping) {
    this.workflowAction.objectMappings.removeObject(objectMapping);
  }

  get intercomObjectMappingOptions() {
    let mappedIntercomObjectTypes = this.workflowAction.objectMappings.map(
      (objectMapping) => objectMapping.intercomObjectType,
    );

    return [
      {
        heading: 'Standard objects',
        items: this._standardObjectTypes(mappedIntercomObjectTypes),
      },
      {
        heading: 'Custom Objects',
        items: this._customObjectTypes(mappedIntercomObjectTypes),
      },
    ];
  }

  _standardObjectTypes(mappedIntercomObjectTypes) {
    let intercomObjectTypes = [
      {
        name: 'People',
        identifier: 'user',
      },
      {
        name: 'Conversation',
        identifier: 'conversation',
      },
    ];

    return intercomObjectTypes.map((objectType) =>
      this._objectDisplayDetails(objectType, mappedIntercomObjectTypes),
    );
  }

  _customObjectTypes(mappedIntercomObjectTypes) {
    let customObjectTypes = this.customObjectsService.customObjectTypes
      .map((objectType) => {
        return {
          name: objectType.name,
          identifier: objectType.identifier,
          archived: objectType.archived,
        };
      })
      .rejectBy('archived');

    if (isEmpty(customObjectTypes)) {
      return [
        {
          text: 'no-custom-objects-select-item',
          value: 'no-custom-objects-select-item',
          isDisabled: true,
          component:
            'workflow-connector/builder/body/sections/response/no-custom-objects-select-item',
        },
      ];
    } else {
      return customObjectTypes.map((objectType) =>
        this._objectDisplayDetails(objectType, mappedIntercomObjectTypes),
      );
    }
  }

  _objectDisplayDetails(objectType, mappedIntercomObjectTypes) {
    let isAlreadyUsed = mappedIntercomObjectTypes.includes(objectType.identifier);

    let tooltipText = null;
    if (isAlreadyUsed) {
      tooltipText = this.intl.t(
        'workflow-connector.builder.body.response.mapping.duplicate-object-mapping',
      );
    }

    return {
      text: objectType.name,
      value: objectType.identifier,
      tooltipText,
      isDisabled: isAlreadyUsed,
    };
  }
}
