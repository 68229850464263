/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';
import type Session from 'embercom/services/session';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    appId?: number | undefined;
    previewableWorkflow?: any;
    locale?: string;
  };
}

export default class WorkflowPreview extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare session: Session;

  get appId() {
    return this.args.appId || this.appService.app.id;
  }

  get previewWorkflowInstanceId() {
    return this.args.previewableWorkflow.latestWorkflowInstanceId;
  }

  get currentLocale() {
    return this.args.locale || this.session.locale;
  }

  bindIFrame = modifier((el) => {
    let iframe = document.createElement('iframe');
    // Add `&local_client=true` to the end of the URL to see changes to intercom-js code in the preview.
    let iframeUrl = `${window.location.origin}/hosted_messenger/${this.appId}/workflow_preview?preview_only=true`;
    iframeUrl += `&preview_workflow_instance_id=${this.previewWorkflowInstanceId}`;
    iframeUrl += `&language_override=${this.currentLocale}`;

    iframe.src = iframeUrl;
    iframe.setAttribute('id', 'hosted-messenger-fin-preview');

    el.replaceChildren(iframe);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Preview': typeof WorkflowPreview;
    'workflows/preview': typeof WorkflowPreview;
  }
}
