/* import __COLOCATED_TEMPLATE__ from './show-standard-replies.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import type AiAgentQuickReplies from 'embercom/models/operator-settings/ai-agent-quick-replies';
import { QUICK_REPLY_ID } from 'embercom/lib/operator/resolution-bot/constants';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type Store from '@ember-data/store';
import type AiAgentQuickRepliesService from 'embercom/services/ai-agent-quick-replies-service';

export default class ShowStandardReplies extends Component {
  @service declare AiAgentQuickRepliesService: AiAgentQuickRepliesService;
  @service declare intl: IntlService;
  @tracked aiAgentQuickReplies?: AiAgentQuickReplies;
  @service declare store: Store;
  @service declare appService: {
    app: {
      id: string;
    };
  };

  constructor(owner: unknown, args: {}) {
    super(owner, args);
    this.intl.maybeLoadMessengerTranslations();
    taskFor(this.loadAiAgentQuickReplies).perform();
  }

  get replyButtonPreviews() {
    return [{ width: 'w-24' }, { width: 'w-40' }, { width: 'w-32' }];
  }

  get triageButtonLabel() {
    let labelKey = this.aiAgentQuickReplies?.waitForTheTeamLabel || 'talk_to_person';
    return this.AiAgentQuickRepliesService.getLabel(
      QUICK_REPLY_ID.WAIT_FOR_THE_TEAM,
      labelKey,
      this.intl.locale,
    );
  }

  get thatHelpedButtonLabel() {
    let labelKey = this.aiAgentQuickReplies?.allDoneLabel || 'all_done';
    return this.AiAgentQuickRepliesService.getLabel(
      QUICK_REPLY_ID.ALL_DONE,
      labelKey,
      this.intl.locale,
    );
  }

  @task({ restartable: true })
  *loadAiAgentQuickReplies(): TaskGenerator<void> {
    this.aiAgentQuickReplies = yield this.store.findRecord(
      'operator-settings/ai-agent-quick-replies',
      this.appService.app.id,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::AnswerTerminal::ShowStandardReplies': typeof ShowStandardReplies;
  }
}
