/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import type IntlService from 'embercom/services/intl';
import type { default as SetUserDataAttributeStepType } from 'embercom/models/operator/visual-builder/step/set-user-data-attribute';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type Attribute from 'embercom/models/attribute';
import type AttributeOption from 'embercom/models/people/attribute-option';

export interface SetUserDataAttributeArgs {
  step: SetUserDataAttributeStepType;
  editorState: EditorState;
  onChange: () => void;
  readOnly: boolean;
}

export default class SetUserDataAttributeEditor extends Component<SetUserDataAttributeArgs> {
  @service declare attributeService: $TSFixMe;
  @service declare intl: IntlService;

  get userDataAttribute(): Attribute {
    return this.attributeService.visualBotBuilderSetUserDataAttributes.find(
      (attribute: Attribute) => attribute.identifier === this.identifier,
    );
  }

  get userDataAttributeType(): string {
    return this.userDataAttribute.inferredType;
  }

  get identifier(): string {
    return this.args.step.action.get('actionData.attribute_identifier');
  }

  get value(): any {
    return this.args.step.action.get('actionData.value');
  }

  get overlayComponent(): string {
    return 'rules/pick-conversation-data-attribute-value-overlay';
  }

  get overlayComponentHash(): object {
    let selectedValue = this.value;

    if (isBlank(this.value) && this.userDataAttributeType === 'boolean') {
      selectedValue = false;
    }

    return {
      onUpdate: this.updateValue,
      selectedValue,
      conversationDataAttribute: this.userDataAttribute,
    };
  }

  get formatValue(): any {
    if (this.userDataAttributeType === 'string' && this.userDataAttribute.hasOptions) {
      return this.userDataAttribute.options.find((option: any) => option.identifier === this.value)
        ?.value;
    }

    if (this.userDataAttributeType === 'boolean') {
      return this.value
        ? this.intl.t('components.rules.pick-conversation-data-attribute-value-overlay.true')
        : this.intl.t('components.rules.pick-conversation-data-attribute-value-overlay.false');
    }

    return this.value;
  }

  get listOptions(): { items: { value: string; text: string }[] }[] {
    return [
      {
        items: this.userDataAttribute.options.map((option: AttributeOption) => {
          return { value: option.value, text: option.value };
        }),
      },
    ];
  }

  @action
  updateValue(value: any) {
    let normalizedValue = this.normalizeValue(value);
    this.args.step.action.set('actionData.value', normalizedValue);
    this.args.onChange?.();
  }

  normalizeValue(value: any) {
    let type = this.userDataAttribute.type;
    if (type === 'integer' || type === 'float') {
      let casted = Number(value);
      if (value === '' || isNaN(casted)) {
        value = null;
      } else {
        value = type === 'integer' ? Math.round(casted) : casted;
      }
    }
    if (type === 'string') {
      value = value.trim();
      if (value === '') {
        value = null;
      }
    }
    return value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::SetUserDataAttribute::Editor': typeof SetUserDataAttributeEditor;
    'workflows/graph-editor/node-items/steps/set-user-data-attribute/editor': typeof SetUserDataAttributeEditor;
  }
}
