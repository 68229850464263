/* import __COLOCATED_TEMPLATE__ from './guidances.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import type Fin from 'embercom/models/operator/visual-builder/step/fin';
import templateOnlyComponent from '@ember/component/template-only';
interface Signature {
  Element: HTMLDivElement;
  Args: {
    accordion: $TSFixMe;
    step: Fin;
    isReadOnly: boolean;
    addGuidance: () => void;
    removeGuidance: (part: any) => void;
  };
}

const Guidances = templateOnlyComponent<Signature>();
export default Guidances;
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Fin::Sections::Guidances': typeof Guidances;
  }
}
