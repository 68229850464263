/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import { empty, or } from '@ember/object/computed';
import { typeOf } from '@ember/utils';
import EmberObject, { get, computed } from '@ember/object';
import Em from 'ember';
import DateFormatter from 'embercom/vendor/intercom/date-formatter';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import moment from 'moment-timezone';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
import Admin from 'embercom/models/admin';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

let emberEscape = Em.Handlebars.Utils.escapeExpression;

let TagsParser = {
  userTagsToString(user, attribute) {
    let tags;
    if (attribute.get('isCompany')) {
      tags = get(user, 'mostRecentCompany.existingTags');
    } else {
      tags = get(user, 'existingTags');
    }
    return this.tagsToString(tags);
  },
  companyTagsToString(company) {
    return this.tagsToString(get(company, 'existingTags'));
  },
  companySegmentsToString(company) {
    return this.tagsToString(get(company, 'segments'));
  },
  tagsToString(tags) {
    let value;
    if (tags) {
      //TODO: GJ: extract this logic
      value = tags
        .slice(0, 2)
        .map(function (tag) {
          return tag.get('name');
        })
        .join(', ');
      let count = parseInt(tags.length, 10);
      if (count > 2) {
        value = `${value} and ${count - 2} more`;
      }
    }
    return value;
  },
};

let TableDataFormatter = EmberObject.extend({
  identifier: computed('attribute', 'isCompany', function () {
    let attributeIdentifierKey = this.isCompany ? 'attribute.companyPath' : 'attribute.userPath';
    return this.get(attributeIdentifierKey);
  }),

  rawValue: computed('userOrCompany', 'identifier', 'userOrCompany.plan.name', function () {
    if (this.identifier === 'plan_id') {
      return this.userOrCompany.get('plan');
    } else {
      return this.userOrCompany.get(this.identifier);
    }
  }),

  parsedValue: computed('intl.locale', 'rawValue', 'attribute', function () {
    let type = this.attribute.get('type');
    let parser = this.parsers[type];
    let parsedValue;
    if (parser !== undefined) {
      parsedValue = emberEscape(parser.apply(this));
    } else {
      parsedValue = emberEscape(this.rawValue);
    }
    if (!parsedValue) {
      parsedValue = this.intl.t('components.users.table.table-data-formatter.unknown');
    }
    return sanitizeHtml(parsedValue);
  }),

  isUrl: computed('rawValue', function () {
    if (typeOf(this.rawValue) === 'string' && this.attribute.get('type') === 'string') {
      return this.rawValue.match(/^\s*https?:\/\/\S*/);
    }
    return false;
  }),

  isEmpty: empty('parsedValue.string'),
  isUnknown: computed('intl.locale', 'parsedValue.string', function () {
    return (
      this.parsedValue.string === this.intl.t('components.users.table.table-data-formatter.unknown')
    );
  }),
  isEmptyOrUnknown: or('isEmpty', 'isUnknown'),
  cssClasses: computed('rawValue', function () {
    let classes;
    if (this.isEmptyOrUnknown) {
      classes = 'text-gray u__disable-pointer-events';
    } else if (this.isCompany && this.identifier === 'last_request_at') {
      let minutesSinceLastRequest = DateFormatter.forcedPastMinuteDiff(this.rawValue);
      if (minutesSinceLastRequest < 5) {
        classes = 'text-green-dark';
      }
    }
    return classes;
  }),
  isUnixTimestamp: computed('rawValue', function () {
    return this.rawValue && !isNaN(this.rawValue);
  }),
  unixTimestampToTimeString: computed('rawValue', function () {
    return moment.unix(this.rawValue).toString();
  }),
  cleanedDateValue: ternaryToProperty('isUnixTimestamp', 'unixTimestampToTimeString', 'rawValue'),
  parsers: {
    browser_locale() {
      return this.get('userOrCompany.locale.name');
    },
    company_tag() {
      return TagsParser.companySegmentsToString(this.userOrCompany, this.attribute);
    },
    owner_id() {
      let rawValue = this.rawValue;
      if (!rawValue) {
        return;
      }
      return Admin.peekAndMaybeLoad(getEmberDataStore(), rawValue).get('name');
    },
    manual_tag() {
      let tagsParser = this.isCompany
        ? TagsParser.companyTagsToString
        : TagsParser.userTagsToString;
      return tagsParser.call(TagsParser, this.userOrCompany, this.attribute);
    },
    plan() {
      let planAttr = this.isCompany ? 'plan.name' : 'mostRecentCompany.plan.name';
      return get(this.userOrCompany, planAttr);
    },
    date() {
      if (this.cleanedDateValue) {
        return DateFormatter.attributeFromNow(
          this.cleanedDateValue,
          this.get('attribute.forcedPastFromNow'),
        );
      } else {
        return this.intl.t('components.users.table.table-data-formatter.unknown');
      }
    },
    anonymous() {
      let value;
      let rawValue = get(this.userOrCompany, 'is_anonymous');
      if (rawValue) {
        value = this.intl.t('components.users.table.table-data-formatter.lead');
      } else {
        value = this.intl.t('components.users.table.table-data-formatter.user');
      }
      return value;
    },
    boolean() {
      let value;
      let rawValue = this.rawValue;
      if (rawValue === undefined || rawValue === null) {
        value = this.intl.t('components.users.table.table-data-formatter.unknown');
      }
      if (rawValue === false) {
        value = 'false';
      }
      if (rawValue) {
        value = 'true';
      }

      return value;
    },
    last_conversation_rating_given() {
      let rawValue = this.rawValue;

      let hash = {
        5: this.intl.t('components.users.table.table-data-formatter.conversation-rating.amazing'),
        4: this.intl.t('components.users.table.table-data-formatter.conversation-rating.great'),
        3: this.intl.t('components.users.table.table-data-formatter.conversation-rating.ok'),
        2: this.intl.t('components.users.table.table-data-formatter.conversation-rating.bad'),
        1: this.intl.t('components.users.table.table-data-formatter.conversation-rating.terrible'),
      };
      return hash[rawValue];
    },
  },
});

export default TableDataFormatter;
