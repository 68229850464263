/* import __COLOCATED_TEMPLATE__ from './rule-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
// @ts-ignore
import { copy } from 'ember-copy';
import GraphEditorHepler from 'embercom/lib/workflows/helpers/graph-editor';

interface Arguments {
  rule: any;
  isReadOnly: boolean;
  predicateContext: any;
  actionEditorContext: any;
  supportedPredicateAttributes: string[];
}

export default class RuleEditor extends Component<Arguments> {
  @service declare appService: any;
  @service attributeService: any;
  @tracked actionEditorContext = this.args.actionEditorContext;

  get allowedRuleActions() {
    let allowedActions = [
      'assign-conversation',
      'change-conversation-priority',
      'apply-conversation-sla',
      'add-tag-to-conversation',
      'tag-user',
      'summarize-conversation',
      'remove-tag-from-user',
      'remove-tag-from-conversation',
      'set-language-override',
    ];

    return GraphEditorHepler.guardAllowedActions(this.appService.app, allowedActions);
  }
  get attributeGroupList() {
    return this.attributeService.visualBotBuilderConditionalAttributesGroupList;
  }

  @action
  onPredicatesUpdate(predicates: any) {
    set(this.args.rule, 'predicates', copy(predicates));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ApplyRules::RuleEditor': typeof RuleEditor;
    'workflows/graph-editor/node-items/steps/apply-rules/rule-editor': typeof RuleEditor;
  }
}
