/* import __COLOCATED_TEMPLATE__ from './graph-side-sheet.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { SideSheetKeys } from 'embercom/objects/workflows/graph-editor/editor-state';

interface Args {
  editorState: EditorState;
  readOnly: boolean;
}

interface Signature {
  Args: Args;
}

export default class GraphSideSheet extends Component<Signature> {
  @service declare appService: any;

  get sideSheetKeys() {
    return SideSheetKeys;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphSideSheet': typeof GraphSideSheet;
    'workflows/graph-side-sheet': typeof GraphSideSheet;
  }
}
