/* import __COLOCATED_TEMPLATE__ from './segment-title.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-side-effects */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { assign } from '@ember/polyfills';
// eslint-disable-next-line @intercom/intercom/no-legacy-modal
import ModalActions from 'embercom/controllers/mixins/users-and-companies/modal-actions';

export default Component.extend(ModalActions, {
  intercomEventService: service(),
  appService: service(),
  intl: service(),
  permissionsService: service(),

  groupList: computed('intl.locale', 'importPeopleItem', function () {
    let items = [
      {
        text: this.intl.t(
          'components.modal.opt-in-to-subscription-type.user-list.segment-title-dropdown.create-new-user',
        ),
        icon: 'new',
        value: 'create_user',
        onSelectItem: () => this.send('showCreateUserModal'),
      },
      {
        text: this.intl.t(
          'components.modal.opt-in-to-subscription-type.user-list.segment-title-dropdown.create-new-lead',
        ),
        icon: 'new',
        value: 'create_lead',
        onSelectItem: () => this.send('showCreateLeadModal'),
      },
      this.importPeopleItem,
    ];

    return [{ items }];
  }),

  importPeopleItem: computed('intl.locale', function () {
    let importPeopleItem = {
      text: this.intl.t(
        'components.modal.opt-in-to-subscription-type.user-list.segment-title-dropdown.import-people',
      ),
      icon: 'filled-cloud-upload',
      value: 'import',
      onSelectItem: () => this.send('startImport'),
    };

    if (!this.permissionsService.currentAdminCan('can_bulk_import_contacts')) {
      assign(importPeopleItem, {
        isDisabled: true,
        tooltipText: this.intl.t(
          'components.modal.opt-in-to-subscription-type.user-list.segment-title-dropdown.tooltip-text',
        ),
      });
    }

    return importPeopleItem;
  }),

  trackEvent(objectType) {
    this.intercomEventService.trackAnalyticsEvent({
      object: objectType,
      place: 'user_list',
      action: 'selected',
    });
  },

  actions: {
    showCreateUserModal() {
      this.trackEvent('user');
      this.set('showUserCreateModal', true);
    },
    showCreateLeadModal() {
      this.trackEvent('lead');
      this.set('showLeadCreateModal', true);
    },
    startImport() {
      this.trackEvent('import');
      this.modalService.openModal('modal/import-modal');
    },
    dropdownClicked() {
      this.trackEvent('add-people-dropdown');
    },
  },
});
