/* import __COLOCATED_TEMPLATE__ from './set-language-override.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type SetLanguageOverride from 'embercom/models/operator/visual-builder/step/set-language-override';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

interface SetLanguageOverrideArgs {
  step: SetLanguageOverride;
  editorState: EditorState;
  readOnly: boolean;
  actionEditorContext: $TSFixMe;
  onChange: () => void;
  onDelete: () => void;
  rule: $TSFixMe;
}

interface Signature {
  Element: HTMLDivElement;
  Args: SetLanguageOverrideArgs;
}

export default class SetLanguageOverrideNodeItem extends Component<Signature> {
  get shouldShowError() {
    return this.args.editorState.shouldShowValidations && !this.args.step.validations.isValid;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::SetLanguageOverride': typeof SetLanguageOverrideNodeItem;
    'workflows/graph-editor/node-items/steps/set-language-override': typeof SetLanguageOverrideNodeItem;
  }
}
