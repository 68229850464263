/* import __COLOCATED_TEMPLATE__ from './apply-conversation-sla.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';

export default class ApplyConversationSla extends Component {
  get isLoaded() {
    let { conversationSlas, officeHoursSchedules } = this.args.editorState;
    return conversationSlas?.isLoaded && officeHoursSchedules?.isLoaded;
  }

  get shouldShowError() {
    return this.args.editorState.shouldShowValidations && !this.args.step.validations.isValid;
  }
}
