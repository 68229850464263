/* import __COLOCATED_TEMPLATE__ from './workflow-connector-action.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class WorkflowConnectorAction extends Component {
  @service appService;
  @service store;
  @tracked action = this.args.action;
  @tracked actionContext = this.args.actionContext;

  get description() {
    return `Custom action ${this.workflowConnectorAction()}`;
  }

  workflowConnectorAction() {
    let workflowConnectorActionId = this.args.action.actionData.workflow_connector_action_id;
    let workflowConnectorAction;
    if (workflowConnectorActionId) {
      workflowConnectorAction = this.args.actionContext.workflowConnectorActions.findBy(
        'id',
        workflowConnectorActionId,
      );
    }
    if (workflowConnectorAction) {
      return workflowConnectorAction.name;
    }
  }
}
