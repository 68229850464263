/* import __COLOCATED_TEMPLATE__ from './phone-message.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type ChatMessage from 'embercom/models/operator/visual-builder/step/chat-message';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type StepConfig from 'embercom/objects/visual-builder/configuration/step';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';

export interface PhoneMessageArgs {
  step: ChatMessage;
  readOnly: boolean;
  editorState: EditorState;
  stepConfig: StepConfig;
  onFocus?: any;
  hasWarning?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: PhoneMessageArgs;
}

export default class PhoneMessagesNodeItem extends Component<Signature> {
  @service intl!: IntlService;

  get enabledComposerTools() {
    return [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  }

  get enabledInserterNames() {
    return ['audio'];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::PhoneMessage': typeof PhoneMessagesNodeItem;
  }
}
