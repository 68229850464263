/* import __COLOCATED_TEMPLATE__ from './trigger-select-awaiting-first-reply-item-component.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Args: {
    hideDropdownOverlay: () => void;
    item: {
      icon?: InterfaceIconName;
      text: string;
      description: string;
      isPaywalled: boolean;
      paywallOpenUpgradeModal: () => void;
      paywallAnalyticsEventData: $TSFixMe; // comes from paywall objects, can type this when paywall is typesafe
    };
  };
}

export default class SelectItem extends Component<Signature> {
  @dropTask
  *openUpgradeModal() {
    yield this.args.hideDropdownOverlay();
    yield this.args.item.paywallOpenUpgradeModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::Editor::TriggerSelectAwaitingFirstReplyItemComponent': typeof SelectItem;
    'workflows/editor/trigger-select-awaiting-first-reply-item-component': typeof SelectItem;
  }
}
