/* import __COLOCATED_TEMPLATE__ from './trigger-marketo-campaign.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class TriggerMarketoCampaign extends Component {
  @service intl;

  @tracked campaignName = this.args.step.action.actionData?.marketo_campaign_name
    ? `"${this.args.step.action.actionData?.marketo_campaign_name}"`
    : this.intl.t('operator.workflows.visual-builder.marketo-campaign-is-missing');
}
