/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class EmptyState extends Component {
  @service intl;

  get isLiveState() {
    return this.args.state === 'live';
  }

  get currentTitle() {
    if (this.args.state === 'any') {
      return this.intl.t(
        'workflow-connector.builder.side-drawer.empty-state.empty-message-any-state',
      );
    }
    return this.intl.t('workflow-connector.builder.side-drawer.empty-state.empty-message', {
      state: this.args.state,
    });
  }

  @action
  switchToDraftActionTab() {
    this.args.changeActionStateTab('draft');
  }
}
