/* import __COLOCATED_TEMPLATE__ from './connected-edge-menu.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { schedule } from '@ember/runloop';

import type Edge from 'embercom/models/operator/visual-builder/edge';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type IntlService from 'embercom/services/intl';

type EdgeDirection = 'inward' | 'outward';

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

interface Args {
  editorState: EditorState;
  canDeleteEdge: boolean | undefined;
  edges: Edge[];
  edgeDirection: EdgeDirection;
  heading?: string;
}

export default class ConnectedEdgeMenu extends Component<Signature> {
  @service declare intl: IntlService;

  @tracked shouldShowButton?: boolean;

  get layout() {
    return this.args.editorState.layout;
  }

  get isViewOnly() {
    return this.layout.isViewMode;
  }

  get graph() {
    return this.layout.graph;
  }

  get groupKey() {
    return this.args.edgeDirection === 'inward' ? 'fromGroup' : 'toGroup';
  }

  get nonDeletedEdges() {
    return this.args.edges.rejectBy('isDeleted');
  }

  get edges() {
    return this.nonDeletedEdges.uniqBy(this.groupKey);
  }

  get hasEdges() {
    return !!this.nonDeletedEdges.length;
  }

  @action
  showPopover() {
    this.shouldShowButton = true;
    if (this.edges.length === 1) {
      if (!this.edges.firstObject) {
        throw new Error('First edge should be defined');
      }
      this.selectEdge(this.edges.firstObject);
    }
  }

  @action
  hidePopover() {
    this.shouldShowButton = false;
  }

  @action
  deselectEdge() {
    this.graph.deselectEdge();
  }

  @action
  selectEdge(edge: Edge) {
    let graphEdge = this.graph.edgeForDataObject(edge);
    if (!graphEdge) {
      throw new Error('Graph edge should be defined');
    }

    this.graph.bringEdgeToForeground(graphEdge);
    this.graph.setSelectedEdge(graphEdge);
  }

  @action
  deleteEdge(edge: Edge) {
    this.args.editorState.deleteEdge(edge, { trigger: 'button' });
  }

  @action
  goToPath(edge: Edge) {
    let path = edge[this.groupKey];
    path.toggleExpand(true);
    let node = this.graph.nodeForDataObject(path);

    schedule('afterRender', () => {
      if (node) {
        this.args.edgeDirection === 'inward'
          ? this.layout.travelToNodeOrConnectionPoint(node, edge.outwardConnectionPoint)
          : this.layout.travelToNodeOrConnectionPoint(node);
      } else {
        throw new Error('Node not found for path');
      }
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::Node::ConnectedEdgeMenu': typeof ConnectedEdgeMenu;
  }
}
