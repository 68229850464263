/* import __COLOCATED_TEMPLATE__ from './set-ticket-state.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';
import { TICKET_STATES, TicketState } from 'embercom/components/rules/actions/set-ticket-state';
import type { default as SetTicketStateStepType } from 'embercom/models/operator/visual-builder/step/set-ticket-state';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

export interface SetTicketStateArgs {
  step: SetTicketStateStepType;
  editorState: EditorState;
  onChange: () => void;
  onDelete: () => void;
  readOnly: boolean;
}

export default class SetTicketState extends Component<SetTicketStateArgs> {
  @service declare intl: IntlService;
  @service declare appService: any;

  get humanReadableTicketState() {
    let ticketStateId = this.args.step.action.actionData.ticket_state_id;

    if (!Object.values(TicketState).includes(ticketStateId)) {
      return this.intl.t('operator.workflows.visual-builder.set-ticket-state-invalid-message');
    }

    return this.intl.t(
      TICKET_STATES.find((ticketState) => ticketState.value === ticketStateId)?.textKey,
    );
  }

  get setTicketStateTooltip() {
    return this.intl.t('operator.workflows.visual-builder.set-ticket-state-category-tooltip', {
      ticketState: this.humanReadableTicketState,
      htmlSafe: true,
    });
  }

  get setTicketStateLabel() {
    return this.intl.t('operator.workflows.visual-builder.set-ticket-state-category', {
      ticketState: this.humanReadableTicketState,
      htmlSafe: true,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::SetTicketState': typeof SetTicketState;
  }
}
