/* import __COLOCATED_TEMPLATE__ from './custom-object-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
// eslint-disable-next-line @intercom/intercom/max-file-length
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty, isEqual, isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { STANDARD_OBJECT_TYPE_IDENTIFIERS } from 'embercom/models/custom-objects/constants/object-types';
import {
  MANY_TO_MANY,
  MANY_TO_ONE,
  ONE_TO_MANY,
} from 'embercom/models/custom-objects/constants/relationship-cardinalities';

export default class CustomObjectSelector extends Component {
  @service customObjectsService;
  @service attributeService;
  @service appService;
  @service router;
  @service store;
  @service intl;

  get selectedCustomObjectTypeIdentifier() {
    return this.args.step.objectTypeForButtonGeneration
      ? this.args.step.objectTypeForButtonGeneration
      : undefined;
  }

  get selectedCustomObject() {
    if (isPresent(this.selectedCustomObjectTypeIdentifier)) {
      return this.customObjectsService.findCustomObjectTypeByIdentifier(
        this.selectedCustomObjectTypeIdentifier,
      );
    }
  }

  get fallbackOutwardConnectionPoint() {
    return this.args.step.outwardConnectionPoints.findBy('type', 'fallback');
  }

  get followUpOutwardConnectionPoint() {
    return this.args.step.outwardConnectionPoints.findBy('type', 'follow_up');
  }

  @action
  redirectToConversationData() {
    safeWindowOpen(this.router.urlFor('apps.app.settings.data.conversation-tickets'));
  }

  @action
  redirectToPeopleData() {
    safeWindowOpen(this.router.urlFor('apps.app.settings.data.people'));
  }

  @action
  redirectToCustomObjectData() {
    safeWindowOpen(this.router.urlFor('apps.app.settings.data.custom-objects'));
  }

  icon(attributeOrDescriptor) {
    let type = attributeOrDescriptor.dataType
      ? attributeOrDescriptor.dataType
      : attributeOrDescriptor.type;

    switch (type) {
      case 'string':
        return 'recipient-data';
      case 'integer':
        return 'count';
      case 'decimal':
        return 'decimal';
      case 'boolean':
        return 'boolean';
      case 'datetime':
        return 'calendar';
      case 'list':
        return 'list';
      case 'relationship':
        return 'transfer';
      default:
        return 'insert-data';
    }
  }

  /*

  Summary: This step displays a set of quick reply buttons that are conditionally generated by the user facing attributes saved on a Custom Object - set in data settings.
  The Custom Object must have either a 'user' or 'conversation' relationship (explicitly mapped in the Custom Object panel) to be able to have a tenable relationship back to the user or conversation.

  This component has been structured in 3 stages:

  1. List all live conversation or user relationships attached to Custom Objects
  2. From the selected conversation or user relationship list all user facing non-relationship attributes. These are used to generate the text for the buttons on the selector step
  3. List all Conversation data relationship attributes that map to the selected Custom Object relationship where we can save the selection to

  Each subsection lives under a comment to help the reader distinguish

  Note: The component relies on compound ids made up of the relationship type ('user' or 'conversation') and the id of the relationship
*/

  //Custom Object relationships
  get customObjectRelationshipLabelIsEmptyOrNonExistant() {
    return (
      isEmpty(this.selectedCustomObjectRelationshipLabel) ||
      this.selectedCustomObjectRelationshipLabel === undefined
    );
  }

  get listCustomObjectTypesForReferenceAttributes() {
    let dropdownItems = this.customObjectsService.customObjectTypes
      .rejectBy('archived')
      .map((objectType) => {
        return {
          text: objectType.name,
          value: objectType.identifier,
        };
      });

    if (isBlank(dropdownItems)) {
      dropdownItems = [
        {
          value: null,
          isDisabled: true,
          component:
            'operator-flows/editor/custom-object-selector/empty-states/custom-object-type-item',
          componentShouldReplaceItem: true,
        },
      ];
    }

    return dropdownItems.uniqBy('text');
  }

  get listCustomObjectConversationAndUserRelationships() {
    let userAndConversationRelationshipAttributes =
      this.attributeService.userAndConversationRelationshipAttributes
        .rejectBy('archived')
        .filter((attribute) => {
          if (attribute.isSourceRelationshipAttribute) {
            return [ONE_TO_MANY, MANY_TO_MANY].includes(attribute.relationship.cardinality);
          } else if (attribute.isDestinationRelationshipAttribute) {
            return [MANY_TO_ONE, MANY_TO_MANY].includes(attribute.relationship.cardinality);
          } else {
            return false;
          }
        });
    let dropdownItems = userAndConversationRelationshipAttributes.map((attribute) => {
      let value = `${attribute.objectTypeIdentifier}_${attribute.cdaId}`;
      if (
        isPresent(this.args.step.attributeObjectTypeForButtonGeneration) &&
        !STANDARD_OBJECT_TYPE_IDENTIFIERS.includes(
          this.args.step.attributeObjectTypeForButtonGeneration,
        ) &&
        isEqual(
          attribute.relatedObjectTypeIdentifier,
          this.args.step.attributeObjectTypeForButtonGeneration,
        ) &&
        isEqual(
          attribute.relatedAttribute.id,
          this.args.step.relationshipAttributeForButtonGenerationId,
        )
      ) {
        value = `${attribute.relatedObjectTypeIdentifier}_${attribute.relatedAttribute.id}`;
      }
      return {
        text: attribute.name,
        value,
        selectedAttribute: attribute,
      };
    });

    if (isEmpty(dropdownItems)) {
      let emptyStateComponentName;

      if (this.customObjectsService.customObjectTypes.rejectBy('archived').length > 0) {
        emptyStateComponentName = 'empty-custom-object-relationship-item';
      } else {
        emptyStateComponentName = 'empty-custom-object-item';
      }

      dropdownItems = [
        {
          value: null,
          isDisabled: true,
          component: `operator-flows/editor/custom-object-selector/${emptyStateComponentName}`,
          componentShouldReplaceItem: true,
        },
      ];
    }
    return dropdownItems;
  }

  @action
  setSelectedCustomObjectRelationship(selectedValue) {
    let selectedDropdownItem = this.listCustomObjectConversationAndUserRelationships.findBy(
      'value',
      selectedValue,
    );
    let selectedRelationshipAttribute = selectedDropdownItem.selectedAttribute;

    this.args.step.relationshipAttributeForButtonGenerationId = selectedRelationshipAttribute.cdaId;
    this.args.step.objectTypeForButtonGeneration =
      selectedRelationshipAttribute.relatedObjectTypeIdentifier;
    this.args.step.attributeObjectTypeForButtonGeneration =
      selectedRelationshipAttribute.objectTypeIdentifier;
  }

  @action
  setSelectedCustomObjectType(selectedValue) {
    this.args.step.objectTypeForButtonGeneration = selectedValue;
    this.args.step.objectTypeDisplayAttributeId = undefined;
    this.args.step.relationshipAttributeForButtonGenerationId = undefined;
    this.args.step.attributeObjectTypeForButtonGeneration = undefined;
  }

  get listConversationReferenceAttributes() {
    return this.attributeService.conversationReferenceAttributes
      .rejectBy('archived')
      .filter(
        (attribute) =>
          attribute.referencedObjectTypeIdentifier === this.selectedCustomObject.identifier &&
          attribute.reference?.referencesOne,
      );
  }

  get selectedCustomObjectRelationshipIdentifier() {
    let concatenatedId = `${this.args.step.attributeObjectTypeForButtonGeneration}_${this.args.step.relationshipAttributeForButtonGenerationId}`;
    if (
      this.listCustomObjectConversationAndUserRelationships.length > 0 &&
      this.selectedRelationshipsExistsInDropdown
    ) {
      return concatenatedId;
    }
  }

  get selectedRelationshipsExistsInDropdown() {
    return this.listCustomObjectConversationAndUserRelationships
      .map((relationship) => relationship.value)
      .includes(
        `${this.args.step.attributeObjectTypeForButtonGeneration}_${this.args.step.relationshipAttributeForButtonGenerationId}`,
      );
  }

  get selectedCustomObjectRelationshipLabel() {
    if (this.args.step.relationshipAttributeForButtonGenerationId) {
      let targetRelationship = this.listCustomObjectConversationAndUserRelationships.findBy(
        'value',
        `${this.args.step.attributeObjectTypeForButtonGeneration}_${this.args.step.relationshipAttributeForButtonGenerationId}`,
      );
      return targetRelationship ? targetRelationship.text : undefined;
    }
  }

  // Attributes to display as buttons
  get listCustomObjectAttributeToDisplayAsButton() {
    let selectedButtonGenerationObjectType;
    if (this.selectedButtonGenerationAttribute) {
      selectedButtonGenerationObjectType =
        this.selectedButtonGenerationAttribute.relatedAttribute.objectType;
    } else {
      return [];
    }
    let dropdownItems = selectedButtonGenerationObjectType.filteredAttributeDescriptors
      .reject(
        (attributeDescriptor) =>
          attributeDescriptor.isRelationshipDataType || attributeDescriptor.archived,
      )
      .map((attributeDescriptor) => {
        return {
          text: attributeDescriptor.name,
          value: attributeDescriptor.id,
          count:
            this.appService.app.canUseCustomObjectsRequiredAttributes &&
            attributeDescriptor.required
              ? `(${this.intl.t('operator.workflows.custom-object-selector.editor.required')})`
              : null,
          icon: this.icon(attributeDescriptor),
          isDisabled: this.attributeSelectorDisabled(attributeDescriptor),
        };
      });
    if (isEmpty(dropdownItems) && isPresent(this.selectedButtonGenerationAttribute)) {
      dropdownItems = [
        {
          value: null,
          isDisabled: true,
          customObjectIdentifier:
            this.selectedButtonGenerationAttribute.relatedObjectTypeIdentifier,
          component:
            'operator-flows/editor/custom-object-selector/empty-custom-object-attribute-item',
          componentShouldReplaceItem: true,
        },
      ];
    }

    return dropdownItems;
  }

  attributeSelectorDisabled(attributeDescriptor) {
    if (this.appService.app.canUseCustomObjectsRequiredAttributes) {
      return !attributeDescriptor.required;
    } else {
      return false;
    }
  }

  @action
  setCustomObjectAttributeToDisplayProps(selectedValue) {
    let currentAttributeObjectTypeForButtonGeneration =
      this.selectedButtonGenerationAttribute.objectTypeIdentifier;
    let currentRelationshipAttributeForButtonGenerationId =
      this.selectedButtonGenerationAttribute.isUser ||
      this.selectedButtonGenerationAttribute.isConversation
        ? this.selectedButtonGenerationAttribute.cdaId
        : this.selectedButtonGenerationAttribute.id;

    this.args.step.attributeObjectTypeForButtonGeneration =
      currentAttributeObjectTypeForButtonGeneration;
    this.args.step.relationshipAttributeForButtonGenerationId =
      currentRelationshipAttributeForButtonGenerationId;
    this.args.step.objectTypeDisplayAttributeId = selectedValue;
  }

  get selectedCustomObjectTypeDisplayAttributeId() {
    if (this.selectedCustomObjectAttributeExistsInDropdown) {
      return this.args.step.selectedObjectTypeDisplayAttributeId;
    }
  }

  get selectedCustomObjectAttributeExistsInDropdown() {
    return this.listCustomObjectAttributeToDisplayAsButton
      .map((attribute) => attribute.value)
      .includes(this.args.step.objectTypeDisplayAttributeId);
  }

  get selectedCustomObjectAttributeToDisplayLabel() {
    if (
      this.args.step.validations.attrs.objectTypeDisplayAttributeId.isValid &&
      this.selectedCustomObjectAttributeExistsInDropdown
    ) {
      return this.listCustomObjectAttributeToDisplayAsButton.find((el) => {
        if (isEqual(el.value, this.args.step.objectTypeDisplayAttributeId)) {
          return el.text;
        }
      }).text;
    }
  }

  get selectedButtonGenerationAttribute() {
    if (this.selectedCustomObjectRelationshipIdentifier) {
      return this.listCustomObjectConversationAndUserRelationships.findBy(
        'value',
        this.selectedCustomObjectRelationshipIdentifier,
      )?.selectedAttribute;
    }
  }

  get emptyStateForUserSelectionDropdown() {
    return {
      value: null,
      isDisabled: true,
      componentShouldReplaceItem: true,
      component:
        'operator-flows/editor/custom-object-selector/conversation-relationship-attribute-empty-item',
    };
  }

  get selectedConversationRelationshipAttributeIdentifier() {
    let concatenatedId = `${this.args.step.attributeObjectTypeForUserSelection}_${this.args.step.relationshipAttributeForUserSelectionId}`;
    let idAndObjectTypeExistOnStep =
      this.args.step.relationshipAttributeForUserSelectionId &&
      this.args.step.attributeObjectTypeForUserSelection;
    if (
      idAndObjectTypeExistOnStep &&
      this.selectedConversationRelationshipAttributeExistsInDropdown
    ) {
      return concatenatedId;
    }
  }

  get selectedConversationRelationshipAttributeExistsInDropdown() {
    return this.listConversationAttributes
      .map((relationship) => relationship.value)
      .includes(
        `${this.args.step.attributeObjectTypeForUserSelection}_${this.args.step.relationshipAttributeForUserSelectionId}`,
      );
  }

  get selectedCustomObjectRelationshipAttributeLabel() {
    if (
      this.args.step.validations.attrs.attributeObjectTypeForUserSelection.isValid &&
      this.args.step.validations.attrs.relationshipAttributeForUserSelectionId.isValid
    ) {
      let targetRelationshipAttribute = this.listConversationAttributes.findBy(
        'value',
        this.selectedConversationRelationshipAttributeIdentifier,
      );
      return targetRelationshipAttribute ? targetRelationshipAttribute.text : undefined;
    }
  }

  get listConversationAttributes() {
    let dropdownItems = [];

    if (isPresent(this.selectedCustomObject)) {
      let conversationAttributes = this.listConversationReferenceAttributes;

      dropdownItems = conversationAttributes.map((attribute) => {
        let value;

        // Handles legacy case where the selected attribute was a relationship attribute on the custom object.
        if (
          isPresent(this.args.step.attributeObjectTypeForUserSelection) &&
          !STANDARD_OBJECT_TYPE_IDENTIFIERS.includes(
            this.args.step.attributeObjectTypeForUserSelection,
          ) &&
          isEqual(
            attribute.relatedObjectTypeIdentifier,
            this.args.step.attributeObjectTypeForUserSelection,
          ) &&
          isEqual(
            attribute.relatedAttribute.id,
            this.args.step.relationshipAttributeForUserSelectionId,
          )
        ) {
          value = `${attribute.relatedObjectTypeIdentifier}_${attribute.relatedAttribute.id}`;
        } else {
          value = `${attribute.objectTypeIdentifier}_${attribute.cdaId}`;
        }

        return {
          text: attribute.name,
          value,
          selectedAttribute: attribute,
        };
      });
    }

    if (isEmpty(dropdownItems)) {
      dropdownItems = [this.emptyStateForUserSelectionDropdown];
    }

    return dropdownItems;
  }

  @action
  setConversationRelationshipAttributeToSaveTo(selectedValue) {
    let selectedDropdownItem = this.listConversationAttributes.findBy('value', selectedValue);

    this.args.step.relationshipAttributeForUserSelectionId =
      selectedDropdownItem.selectedAttribute.cdaId;
    this.args.step.attributeObjectTypeForUserSelection =
      selectedDropdownItem.selectedAttribute.objectTypeIdentifier;
  }

  get shouldShowError() {
    return (
      this.args.editorState.shouldShowValidations &&
      (this.args.step.validations.attrs.supportedChannels.isInvalid ||
        this.args.step.validations.attrs.supportedContexts.isInvalid)
    );
  }
}
