/* import __COLOCATED_TEMPLATE__ from './trigger-info-node.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'embercom/services/intl';
import { customBotObjectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { SideSheetKeys } from 'embercom/objects/workflows/graph-editor/editor-state';
import {
  type PredicateOption,
  type TriggerPredicates,
} from 'embercom/objects/operator/configuration/custom-bot';
import {
  CHANNEL_METADATA_MAPPING,
  CHANNEL_GROUP_MAPPING,
  WEBHOOK_RECEIVED_TARGET,
  AI_AGENT_WORKFLOW_TARGET,
} from 'embercom/lib/operator/custom-bots/constants';
import { type AttributePredicate } from '@intercom/interblocks.ts';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type Workflow from 'embercom/models/operator/visual-builder/workflow';
import type Store from '@ember-data/store';

import { states } from 'embercom/models/data/matching-system/matching-constants';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';
import type ContentDependency from 'embercom/models/matching-system/content-dependency';

interface Args {
  editorConfiguration: EditorConfig;
  editorState: EditorState;
  infoPanelTriggerData: $TSFixMe;
  workflow: Workflow;
  readOnly: boolean;
  isTemplatePreview?: boolean;
  sideSheetIsDismissed?: boolean;
}

export default class TriggerInfoNode extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare contentEditorService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked linkedWorkflows: ContentDependency[] = [];

  liveState = states.live;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    if (!this.args.isTemplatePreview && !this.args.workflow.isFinPreview) {
      taskFor(this.fetchLinkedWorkflows).perform();
    }
  }

  @restartableTask
  *fetchLinkedWorkflows() {
    let obj = this.appService.app.canUseReusableWorkflowContentDependency
      ? this.ruleset
      : this.customBot;
    this.linkedWorkflows = yield obj.loadDependentObjects();
  }

  get sideSheetKeys() {
    return SideSheetKeys;
  }

  get isAvailableFromInbox() {
    return this.allowManualTrigger && this.isWorkflowLive;
  }

  get isWorkflowLive() {
    return this.ruleset.state === states.live;
  }

  get allowManualTrigger() {
    return this.customBot?.botData?.allowManualTrigger;
  }

  get customBot() {
    return this.contentEditorService.activeObject;
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get rulesetHasPredicates() {
    return this.ruleset.predicateGroup.hasPredicates;
  }

  get rulesetPredicatesCount() {
    return (
      this.ruleset.predicateGroup.predicates?.firstObject?.predicates?.length ||
      this.ruleset.predicateGroup.predicates.length
    );
  }

  get rulesetHasUserPredicates() {
    return this.ruleset.rolePredicateGroup.userPredicate;
  }

  get showAdditionalInfo() {
    if (this.isSeriesModeOrTemplatePreview) {
      return false;
    }

    return (
      this.isAvailableFromInbox ||
      this.hasLinkedWorkflows ||
      (this.shouldRenderChannels && !this.isTriggerBot) ||
      (this.rulesetHasUserPredicates && !this.isTriggerBot)
    );
  }

  get infoTooltip() {
    // Should show tooltip only if more than 1 predicate was selected
    return this.args.infoPanelTriggerData.triggerPredicatesDescriptionTranslationKey;
  }

  get hasMultiplePredicatesSelected() {
    return this.selectedTriggerPredicates?.length > 1;
  }

  get userTypes() {
    return this.ruleset.rolePredicateGroup.userPredicate?.userTypes || [];
  }

  get allUserRoles() {
    return ['user', 'lead', 'visitor'];
  }

  get triggerPredicates(): TriggerPredicates | null {
    return this.args.infoPanelTriggerData.triggerPredicates;
  }

  get hasLinkedWorkflows() {
    return this.linkedWorkflows.length > 0;
  }

  get linkedWorkflowsSummary() {
    return {
      items: this.linkedWorkflows.slice(0, 2),
      remainingCount: Math.max(this.linkedWorkflows.length - 2, 0),
    };
  }

  get channels() {
    let selectedChannels = this.customBot.targetChannels as
      | (keyof typeof CHANNEL_METADATA_MAPPING)[]
      | undefined;

    if (!selectedChannels || !selectedChannels.length) {
      return undefined;
    }

    let channelMapping = CHANNEL_GROUP_MAPPING;

    return Object.values(channelMapping)
      .flat()
      .filter((channel) => selectedChannels?.includes(channel))
      .map((channel) => CHANNEL_METADATA_MAPPING[channel]);
  }

  get localizedList() {
    if (!this.customBot.targetChannels) {
      return [];
    }

    let selectedChannels = this.customBot.targetChannels as
      | (keyof typeof CHANNEL_METADATA_MAPPING)[]
      | undefined;

    return (selectedChannels || []).map((channel) => {
      let metadata = CHANNEL_METADATA_MAPPING[channel];
      return this.intl.t('operator.workflows.channels.type', { channel: metadata.value });
    });
  }

  get selectedChannelsLabel() {
    if (this.localizedList.length === 0) {
      return '';
    }

    if (this.localizedList.length === 1) {
      return this.intl.t(
        'operator.workflows.visual-builder.workflow-trigger-node.selected-channel.single-channel',
        {
          channel: this.localizedList[0],
        },
      );
    } else {
      return this.intl.t(
        'operator.workflows.visual-builder.workflow-trigger-node.selected-channel.double-channel',
        {
          channelOne: this.localizedList[0],
          channelTwo: this.localizedList[1],
        },
      );
    }
  }

  get remainingSelectedChannelsCount() {
    if (this.localizedList.length - 2 <= 0) {
      return 0;
    }

    return this.localizedList.length - 2;
  }

  get selectedTriggerPredicates(): PredicateOption[] {
    if (this.args.isTemplatePreview) {
      return [];
    }

    if (!this.triggerPredicates || !this.isLogicalTriggerPredicate(this.triggerPredicates.type)) {
      return [];
    }

    let selectedPredicates =
      this.customBot.triggerPredicateGroup?.predicates.firstObject?.predicates.map(
        (o: AttributePredicate) => o.value,
      );

    if (!selectedPredicates) {
      return [];
    }

    return this.triggerPredicates.options.filter((option) =>
      selectedPredicates.includes(option.value),
    );
  }

  isLogicalTriggerPredicate(type: any) {
    return ['or', 'and'].includes(type);
  }

  get analyticsData() {
    return {
      ...this.args.workflow.analyticsData,
      object: 'visual_builder_trigger_info_node',
      node: 'trigger_info_node',
      overwriteObject: true,
    };
  }

  get isTriggerBot() {
    if (this.args.isTemplatePreview) {
      return false;
    }

    return this.customBot.isTriggerable && this.customBot.isNoTrigger;
  }

  get isSeriesMode() {
    return this.contentEditorService.isSeriesMode;
  }

  get isSeriesModeOrTemplatePreview() {
    return this.isSeriesMode || this.args.isTemplatePreview;
  }

  get sideSheetEnabled() {
    return !this.isSeriesModeOrTemplatePreview && this.args.editorConfiguration.supportsSideSheet;
  }

  get hasErrors() {
    if (this.isSeriesModeOrTemplatePreview) {
      return false;
    }
    // We only count errors that are not errors in the workflow
    return (
      this.ruleset.validations.errors.length -
        this.ruleset.rulesetLinks.reduce((previous: number, current: any) => {
          if (customBotObjectTypes.includes(current.objectType)) {
            return (
              previous +
              (current.object.validations.attrs.visualBuilderObject.errors.length as number)
            );
          } else {
            return previous;
          }
        }, 0) >
        0 && this.args.editorState.shouldShowValidations
    );
  }

  get isWebhookReceived() {
    return this.args.infoPanelTriggerData.target === WEBHOOK_RECEIVED_TARGET;
  }

  get isAiAgentWorkflow() {
    return this.args.infoPanelTriggerData.target === AI_AGENT_WORKFLOW_TARGET;
  }

  get shouldRenderChannels(): boolean {
    let result = this.localizedList.length > 0 && !this.isTriggerBot && !this.isWebhookReceived;

    if (this.appService.app.canUseWorkflowsTicketsV3) {
      return result && this.args.editorConfiguration.supportsConversationChannelTargeting;
    } else {
      return result;
    }
  }

  get shouldRenderAudienceSummary(): boolean {
    if (this.appService.app.canUseWorkflowsTicketsV3) {
      return this.args.editorConfiguration.supportsUserTargeting && this.rulesetHasUserPredicates;
    } else {
      return !this.isTriggerBot && !this.isWebhookReceived && this.rulesetHasUserPredicates;
    }
  }

  get inboundTriggerDescription() {
    let inboundTriggerUUID =
      this.customBot?.triggerPredicateGroup?.predicates?.firstObject?.predicates?.firstObject
        ?.value;
    let trigger = this.store
      .peekAll('workflow-connector/inbound-trigger')
      .findBy('uuid', inboundTriggerUUID);

    let translationKey = null;
    if (trigger?.name === 'Jira Issue Updated') {
      translationKey =
        'operator.custom-bot.trigger.webhook-received.jira-issue-updated-description';
    } else if (trigger?.name === 'Jira Comment Created') {
      translationKey =
        'operator.custom-bot.trigger.webhook-received.jira-issue-status-updated-description';
    }

    return translationKey;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::TriggerInfoNode': typeof TriggerInfoNode;
    'workflows/graph-editor/trigger-info-node': typeof TriggerInfoNode;
  }
}
