/* import __COLOCATED_TEMPLATE__ from './fallback-editor.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';

//@ts-ignore
import { ref } from 'ember-ref-bucket';
import { type AttributeInfoResolver } from '@intercom/embercom-prosemirror-composer';

interface Args {
  attributeInfoResolver: AttributeInfoResolver;
  attributeIdentifier: any;
  fallbackValue: any;
  onEnterKey: (event: any) => void;
  onUpdate: (fallbackValue: string) => void;
  onReset: () => void;
}

export default class FallbackEditor extends Component<Args> {
  @ref('fallbackInput') fallbackInput?: HTMLInputElement;

  get requiresFallback() {
    return this.resolver?.isFallbackAllowed(this.args.attributeIdentifier);
  }

  get fallbackPlaceholderText() {
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    return `If we can't find ${this.resolver
      ?.humanReadable(this.args.attributeIdentifier)
      ?.toLowerCase()}`;
  }

  get fallbackInfoText() {
    return this.resolver?.fallbackInfoText(this.args.attributeIdentifier);
  }

  get resolver() {
    return this.args.attributeInfoResolver;
  }

  @action
  onKeyUp(e: KeyboardEvent): void {
    this.update();
    if (e.key === 'Enter') {
      this.args.onEnterKey(e);
    }
  }

  @action
  update() {
    if (this.fallbackInput && this.fallbackInput.value !== this.args.fallbackValue) {
      this.args.onUpdate(this.fallbackInput.value);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Whatsapp::FallbackEditor': typeof FallbackEditor;
    'whatsapp/fallback-editor': typeof FallbackEditor;
  }
}
