/* import __COLOCATED_TEMPLATE__ from './set-conversation-data-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Arguments {
  action: any;
}

export default class SetConversationDataAtrributeAction extends Component<Arguments> {
  @service attributeService: any;
  @service declare intl: IntlService;

  @computed(
    'args.action.actionData.attribute_identifier',
    'attributeService.conversationCustomAttributes',
  )
  get attribute() {
    return this.attributeService.conversationCustomAttributes.find(
      (item: any) => item.identifier === this.args.action.actionData.attribute_identifier,
    );
  }

  get attributeName() {
    return this.attribute.name;
  }

  @computed('args.action.actionData.value', 'attribute.{options,type}')
  get attributeValue() {
    switch (this.attribute.type) {
      case 'conversation_attribute_list':
        return this.attribute.options.find(
          (option: any) => option.identifier === this.args.action.actionData.value,
        )?.value;
      case 'boolean':
        return this.args.action.actionData.value
          ? this.intl.t(
              'operator.workflows.visual-builder.apply-rules.set-conversation-data-attribute.t',
            )
          : this.intl.t(
              'operator.workflows.visual-builder.apply-rules.set-conversation-data-attribute.f',
            );
      default:
        return this.args.action.actionData.value;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ApplyRules::Actions::SetConversationDataAttribute': typeof SetConversationDataAtrributeAction;
    'workflows/graph-editor/node-items/steps/apply-rules/actions/set-conversation-data-attribute': typeof SetConversationDataAtrributeAction;
  }
}
