/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type NotifySlackChannel from 'embercom/models/operator/visual-builder/step/notify-slack-channel';

interface Arguments {
  step: NotifySlackChannel;
  isReadOnly: boolean;
}

export default class Settings extends Component<Arguments> {
  tempPublicChannelData = {};
  tempPrivateChannelData = {};
  constructor(owner: unknown, args: Arguments) {
    super(owner, args);
  }

  get isPublicSelected() {
    return this.args.step.action.actionData?.slack_channel_type === 'public';
  }

  @action
  onSlackChannelChange(slackChannelId: string, slackChannelName: string) {
    this.args.step.action.actionData = {
      slack_channel_type: this.args.step.action.actionData?.slack_channel_type,
      slack_channel_id: slackChannelId,
      slack_channel_name: slackChannelName,
    };
  }

  @action
  selectPrivateChannel() {
    this.saveTempPublicChannelData();
    this.args.step.action.actionData = {
      slack_channel_type: 'private',
      ...this.tempPrivateChannelData,
    };
  }

  @action
  selectPublicChannel() {
    this.saveTempPrivateChannelData();
    this.args.step.action.actionData = {
      slack_channel_type: 'public',
      ...this.tempPublicChannelData,
    };
  }

  @action
  updatePrivateChannelId(event: Event) {
    let target = event.target as HTMLInputElement;
    this.args.step.action.actionData = {
      slack_channel_type: 'private',
      slack_channel_id: target.value,
    };
  }

  saveTempPublicChannelData() {
    this.tempPublicChannelData = {
      slack_channel_id: this.args.step.action.actionData?.slack_channel_id,
      slack_channel_name: this.args.step.action.actionData?.slack_channel_name,
    };
  }

  saveTempPrivateChannelData() {
    this.tempPrivateChannelData = {
      slack_channel_id: this.args.step.action.actionData?.slack_channel_id,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::NotifySlackChannel::Settings': typeof Settings;
    'workflows/graph-editor/node-items/steps/notify-slack-channel/settings': typeof Settings;
  }
}
