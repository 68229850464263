/* import __COLOCATED_TEMPLATE__ from './pass-to-team.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';
import type AnswerTerminal from 'embercom/models/operator/visual-builder/step/answer-terminal';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

export interface Args {
  step: AnswerTerminal;
  editorState: EditorState;
  readOnly: boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const PassToTeam = templateOnlyComponent<Signature>();
export default PassToTeam;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::AnswerTerminal::PassToTeam': typeof PassToTeam;
  }
}
