/* import __COLOCATED_TEMPLATE__ from './inbound-bot-prompt.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { action } from '@ember/object';

class PromptComposerConfig extends BaseConfig {
  constructor(placeholder) {
    super();
    this.placeholder = placeholder;
  }
  autoFocus = true;
  tools = [];
  allowedBlocks = ['paragraph'];
  allowedInline = [];
  inputRules = [];
  singleBlockMode = true;
}

export default class InboundBotPrompt extends Component {
  @service store;
  @service intl;

  composerConfig = new PromptComposerConfig(
    this.intl.t('operator.workflows.visual-builder.inbound-bot-prompt.placeholder'),
  );
  promptBlocksDocument = new BlocksDocument([this.firstParagraphBlock]);

  get firstParagraphBlock() {
    let firstParagraphBlock = this.args.step.blocks.find((block) => block.type === 'paragraph');
    if (!firstParagraphBlock || !firstParagraphBlock.text) {
      return this.store.createFragment('common/blocks/paragraph', {
        type: 'paragraph',
        text: this.intl.t('operator.workflows.visual-builder.inbound-bot-prompt.default-text'),
      });
    }
    return firstParagraphBlock;
  }

  get firstParagraphBlockSerialized() {
    return this.firstParagraphBlock.serialize();
  }

  @action
  updatePrompt(newBlocksDocument) {
    this.args.step.blocks = newBlocksDocument.blocks;
  }
}
