/* import __COLOCATED_TEMPLATE__ from './list-of-paths.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { action } from '@ember/object';
import type Group from 'embercom/models/operator/visual-builder/group';
import { type SyncHasMany } from '@ember-data/model';

interface Args {
  groups: SyncHasMany<Group>;
  editorState: EditorState;
}

export default class ListOfPaths extends Component<Args> {
  @action travelToGroup(group: Group) {
    group.toggleExpand(true);
    this.args.editorState.travelToGroup(group);
  }

  get isAnyGroupInvalid(): boolean {
    return this.args.groups.any((group) => group.validations.isInvalid);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::ListOfPaths': typeof ListOfPaths;
    'workflows/graph-editor/list-of-paths': typeof ListOfPaths;
  }
}
