/* import __COLOCATED_TEMPLATE__ from './created-by.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed, default as EmberObject } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  intl: service(),
  store: service(),
  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  unknownTeammate: computed('intl.locale', 'app', function () {
    return EmberObject.create({
      nameOrEmail: this.intl.t(
        'components.visibility.segments.table-cells.created-by.unknown-teammate',
        { appName: this.get('app.name') },
      ),
      avatarData: {
        isTeammate: true,
      },
    });
  }),
  teammate: computed('app', 'admin', 'unknownTeammate', function () {
    return this.store.peekRecord('admin', this.get('row.created_by_id')) || this.unknownTeammate;
  }),
  teammateName: computed('intl.locale', 'teammate.id', 'admin.id', function () {
    return this.get('teammate.id') === this.get('admin.id')
      ? this.intl.t('components.visibility.segments.table-cells.created-by.you')
      : this.get('teammate.nameOrEmail');
  }),
});
