/* import __COLOCATED_TEMPLATE__ from './hand-off-in-zendesk.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
}

const HandOffInZendeskComponent = templateOnlyComponent<Signature>();
export default HandOffInZendeskComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::Markers::HandOffInZendesk': typeof HandOffInZendeskComponent;
  }
}
