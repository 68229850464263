/* import __COLOCATED_TEMPLATE__ from './attribute-visibility-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { ternary, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import ProfileAttribute from 'embercom/components/mixins/profile-attribute';
import ENV from 'embercom/config/environment';

export default Component.extend(ProfileAttribute, {
  classNames: ['kv__pair', 'u__cf'],
  classNameBindings: ['inEventList:o__in-list-editable-events:o__in-list'],

  notificationsService: service(),
  appService: service(),
  intercomEventService: service(),
  intl: service(),

  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),

  inEventList: false,

  save() {
    this.admin.save().catch(this._handleSaveError.bind(this));
  },
  toggleAttributeVisibility() {
    let newAttributeVisibility = !this.attributeVisibleNow;
    if (this.attribute.isUserEvent) {
      this._toggleEventAttributeVisibility(newAttributeVisibility);
    } else if (this.attribute.isCompany) {
      this._toggleCompanyAttributeVisibility(newAttributeVisibility);
    } else {
      this._toggleUserAttributeVisibility(newAttributeVisibility);
    }
    this.save();
  },
  tooltipText: ternaryToProperty('inEventList', 'eventTooltipText', 'attributeTooltipText'),
  eventTooltipText: computed('intl.locale', 'attributeVisibleNow', function () {
    return this.attributeVisibleNow
      ? this.intl.t('inbox.conversation-details-sidebar.attribute-visibility-component.hide-event')
      : this.intl.t('inbox.conversation-details-sidebar.attribute-visibility-component.show-event');
  }),
  attributeTooltipText: computed('intl.locale', 'attributeVisibleNow', function () {
    return this.attributeVisibleNow
      ? this.intl.t('inbox.conversation-details-sidebar.attribute-visibility-component.hide-detail')
      : this.intl.t(
          'inbox.conversation-details-sidebar.attribute-visibility-component.show-detail',
        );
  }),

  visibilityIconType: ternary('attributeVisibleNow', 'visible', 'hidden'),
  visibilityIconTypeOnHover: ternary('attributeVisibleNow', 'hidden', 'visible'),
  isEventAttributeDisabled: computed(
    'attribute.isUserEvent',
    'admin.visible_user_event_ids.length',
    function () {
      return (
        this.attribute.isUserEvent &&
        this.attributeVisibleNow &&
        this.admin.visible_user_event_ids &&
        this.admin.visible_user_event_ids.length <= 1
      );
    },
  ),
  _toggleCompanyAttributeVisibility(newAttributeVisiblity) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'toggle_visibility',
      object: `inbox_sidebar_company_details`,
      context: newAttributeVisiblity ? 'show_attribute' : 'hide_attribute',
      attribute_identifier: this.attribute.identifier,
    });

    if (newAttributeVisiblity) {
      this.admin.addAttributeIdToAdminsVisibleCompanyAttributeIds(this.attribute.identifier);
    } else {
      this.admin.removeAttributeIdFromAdminsVisibleCompanyAttributeIds(this.attribute.identifier);
    }
  },
  _toggleUserAttributeVisibility(newAttributeVisiblity) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'toggle_visibility',
      object: `inbox_sidebar_user_details`,
      context: newAttributeVisiblity ? 'show_attribute' : 'hide_attribute',
      attribute_identifier: this.attribute.identifier,
    });

    if (newAttributeVisiblity) {
      this.admin.addAttributeIdToAdminsVisibleAttributeIds(this.attribute.identifier);
    } else {
      this.admin.removeAttributeIdFromAdminsVisibleAttributeIds(this.attribute.identifier);
    }
  },
  _toggleEventAttributeVisibility(newAttributeVisiblity) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'toggle_visibility',
      object: `inbox_sidebar_event_details`,
      context: newAttributeVisiblity ? 'show_attribute' : 'hide_attribute',
      attribute_identifier: this.attribute.identifier,
    });

    if (newAttributeVisiblity) {
      this.admin.addEventIdToAdminsVisibleUserEventIds(this.attribute.identifier);
    } else {
      this.admin.removeEventIdFromAdminsVisibleUserEventIds(this.attribute.identifier);
    }
  },
  _handleSaveError() {
    this.notificationsService.notifyError(
      this.intl.t(
        'inbox.conversation-details-sidebar.attribute-visibility-component.unexpected-error',
      ),
    );
  },
  actions: {
    toggleAttributeVisibility() {
      debounce(this, this.toggleAttributeVisibility, ENV.APP._250MS);
    },
  },
});
