/* import __COLOCATED_TEMPLATE__ from './impersonate.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */

import ajax from 'embercom/lib/ajax';
import { dropTask } from 'ember-concurrency-decorators';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class Impersonate extends Component {
  @service notificationsService;
  @service modalService;

  @tracked tagName = '';
  @tracked impersonationUrl = '';
  @tracked restrictDataAccess = true;
  @tracked readOnly = true;
  @tracked reason = this.args.options.defaultReason;

  get userId() {
    return this.args.model.user_id;
  }

  get impersonatedAdminEmail() {
    return this.args.model.impersonatedAdminEmail;
  }

  get impersonationConsentExists() {
    return this.args.options.impersonationConsentExists;
  }

  get readOnlyOptional() {
    return this.args.options.impersonationConsentExists;
  }

  get restrictDataAccessOptional() {
    return this.args.options.impersonationConsentExists;
  }

  get showWarningBanner() {
    return !this.readOnly || !this.restrictDataAccess;
  }

  reasonWithAppendedUrl() {
    let reason = this.reason;
    if (!reason.match(/conversations\/\d+/gi)) {
      return `${reason} ${this.getLocation()}`;
    } else {
      return reason;
    }
  }

  getLocation() {
    return document.referrer || document.location.href;
  }

  @dropTask *impersonate() {
    let restrictDataAccess = this.restrictDataAccess ? 'on' : 'off';
    let readOnly = this.readOnly ? 'on' : 'off';

    try {
      let response = yield ajax({
        url: '/admin/impersonations',
        method: 'POST',
        data: JSON.stringify({
          impersonation: {
            impersonated_admin_id: this.userId,
            impersonated_email: this.impersonatedAdminEmail,
            restrict_data_access: restrictDataAccess,
            read_only: readOnly,
            reason: this.reasonWithAppendedUrl(),
            url: this.impersonationUrl,
          },
        }),
      });

      if (response.redirect_url) {
        safeWindowOpen(response.redirect_url, '_blank');
      }
    } catch (e) {
      let label = 'ok';
      this.notificationsService.notifyErrorWithButton(
        e.jqXHR.responseJSON.errors,
        { label, action: () => {} },
        0,
      );
    }
  }

  @action closeModal() {
    this.modalService.closeModal();
  }
}
