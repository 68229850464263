/* import __COLOCATED_TEMPLATE__ from './convert-to-ticket.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { TicketCategory } from 'embercom/objects/inbox/ticket';

export default class ConvertToTicket extends Component {
  @service appService;
  @service store;
  @service intl;
  @tracked ticketTypes;

  constructor() {
    super(...arguments);
    this.fetchTicketTypes.perform();
  }

  @task({ drop: true })
  *fetchTicketTypes() {
    let fetchedTicketTypes = yield this.store.findAll('inbox/ticket-type');
    fetchedTicketTypes = fetchedTicketTypes.filter(
      ({ archived, category }) => !archived && category === TicketCategory.Request,
    );
    this.ticketTypes = fetchedTicketTypes;
  }

  get selectListItemsByCategory() {
    if (!this.ticketTypes) {
      return [];
    }

    return this.ticketTypes.map((ticketType) => {
      let isDisabled = ticketType.userVisibleDescriptors.any(
        (obj) => obj.requiredToCreate || obj.requiredToCreateForUsers,
      );
      return {
        text: `${ticketType.emoji} ${ticketType.name}`,
        value: ticketType.id,
        category: this.intl.t(
          'operator.workflows.visual-builder.send-ticket-menu.category.request',
        ),
        component: 'workflows/graph-editor/node-items/steps/convert-to-ticket-item',
        isDisabled,
      };
    });
  }

  get hasError() {
    return !this.loading && !this.args.step.ticketTypeId;
  }

  get shouldShowError() {
    return (
      this.args.editorState?.shouldShowValidations &&
      this.args.step.validations.attrs.supportedContexts.isInvalid
    );
  }

  get loading() {
    return this.fetchTicketTypes.isRunning || !this.ticketTypes;
  }

  get selectedTicketType() {
    if (!this.ticketTypes) {
      return null;
    }

    return this.ticketTypes.find((item) => item.id === `${this.args.step.ticketTypeId}`);
  }
}
