/* import __COLOCATED_TEMPLATE__ from './assign-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type AssignConversationConfiguration from 'embercom/objects/visual-builder/configuration/step/assign-conversation';
import type AssignConversation from 'embercom/models/operator/visual-builder/step/assign-conversation';
import { type TaskGenerator } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  step: AssignConversation;
  readOnly: boolean;
  editorState: EditorState;
  stepConfig: AssignConversationConfiguration;
  onChange?: any;
  onDelete?: any;
}

export default class AssignConversationNodeItem extends Component<Args> {
  @service store: any;
  @service appService: any;
  @tracked operatorSettings: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadOperatorBotInboxSettings).perform();
  }

  get hasBotInboxEnabled() {
    return this.operatorSettings?.assignConversationsToBots;
  }

  @task({ restartable: true }) *loadOperatorBotInboxSettings(): TaskGenerator<void> {
    this.operatorSettings = yield this.store.findRecord(
      'operator-bot-inbox-settings',
      this.appService.app.id,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::AssignConversation': typeof AssignConversationNodeItem;
  }
}
