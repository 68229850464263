/* import __COLOCATED_TEMPLATE__ from './if-branch.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { type default as GraphEditorNode } from 'graph-editor/models/graph-editor/node';

import type ConnectionPoint from 'embercom/models/operator/visual-builder/connection-point';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type Group from 'embercom/models/operator/visual-builder/group';
import type ConditionalBranchesConfiguration from 'embercom/objects/visual-builder/configuration/step/conditional-branches';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';

type IfBranchArgs = {
  branch: ConnectionPoint;
  editorState: EditorState;
  readOnly: boolean;
  index: number;
  updateConnectionPoints: (node: GraphEditorNode<Group>) => void;
  connectionPointComponent: Component;
  stepConfig: ConditionalBranchesConfiguration;
  sortableItem: any;
  editorConfiguration: EditorConfig;
  attributeGroupList: any;
  attributeListOverride: any;
};

export default class IfBranch extends Component<IfBranchArgs> {
  constructor(owner: unknown, args: IfBranchArgs) {
    super(owner, args);

    this.args.branch.predicateGroup.set('attributeListOverride', this.args.attributeListOverride);
  }

  get graph() {
    return this.args.editorState.layout.graph;
  }
  get supportedPredicateAttributes() {
    return this.args.editorConfiguration.runtimeMatchingTargetingAttributeIdentifiers;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ConditionalBranches::IfBranch': typeof IfBranch;
    'workflows/graph-editor/node-items/steps/conditional-branches/if-branch': typeof IfBranch;
  }
}
