/* import __COLOCATED_TEMPLATE__ from './goal-header.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';

export type GoalHeaderArgs = {
  isDisabled: boolean;
  config: any;
  section: any;
};

interface Signature {
  Element: HTMLDivElement;
  Args: GoalHeaderArgs;
}

const GoalHeader = templateOnlyComponent<Signature>();
export default GoalHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::TriggerInfoNode::GoalHeader': typeof GoalHeader;
  }
}
