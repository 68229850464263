/* import __COLOCATED_TEMPLATE__ from './chat-message.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ChatMessagesNodeItem extends Component {
  @service intl;
  @service attributeService;
  @service appService;

  constructor() {
    super(...arguments);

    if (this.args.editorState.focusableObject === this.args.step) {
      this.startEditing();
    }
  }

  get app() {
    return this.appService.app;
  }

  get inserters() {
    let inserters = [
      {
        name: 'emoji',
        icon: 'lwr-happy',
        descriptor: this.intl.t(
          'operator.workflows.visual-builder.chat-message-inserter.add-emoji',
        ),
        hasPopoverContentComponent: true,
      },
      {
        name: 'article',
        icon: 'article',
        descriptor: this.intl.t(
          'operator.workflows.visual-builder.chat-message-inserter.add-article',
        ),
        hasPopoverContentComponent: true,
      },
    ];
    if (this.app.teammateConversationGifsEnabled) {
      inserters.insertAt(1, {
        name: 'gif',
        icon: 'gif',
        descriptor: this.intl.t('operator.workflows.visual-builder.chat-message-inserter.add-gif'),
        hasPopoverContentComponent: true,
      });
    }
    if (this.app.teammateConversationAttachmentsEnabled) {
      inserters.push({
        name: 'image',
        icon: 'picture',
        descriptor: this.intl.t(
          'operator.workflows.visual-builder.chat-message-inserter.add-image',
        ),
        hasPopoverContentComponent: false,
      });
      inserters.push({
        name: 'attachment',
        icon: 'attachment',
        descriptor: this.intl.t(
          'operator.workflows.visual-builder.chat-message-inserter.add-attachment',
        ),
        hasPopoverContentComponent: false,
      });
    }
    if (this.canShowVideoInserter) {
      inserters.push({
        name: 'video',
        icon: 'video-camera',
        descriptor: this.intl.t(
          'operator.workflows.visual-builder.chat-message-inserter.add-video',
        ),
        hasPopoverContentComponent: false,
      });
    }
    if (this.canShowAudioInserter) {
      inserters.push({
        name: 'audio',
        icon: 'microphone',
        descriptor: this.intl.t(
          'operator.workflows.visual-builder.chat-message-inserter.add-audio',
        ),
        hasPopoverContentComponent: false,
      });
    }

    inserters.push({
      name: 'product-tours',
      icon: 'product-tours',
      descriptor: this.intl.t('operator.workflows.visual-builder.chat-message-inserter.add-tour'),
      hasPopoverContentComponent: false,
    });

    if (this.args.enabledInserterNames) {
      inserters = inserters.filter((inserter) =>
        this.args.enabledInserterNames.includes(inserter.name),
      );
    }

    return inserters;
  }

  get canClickToEdit() {
    return !this.args.readOnly && !this.isEditing;
  }

  get isEditing() {
    return this.args.editorState.activeComposerStep === this.args.step;
  }

  get shouldShowError() {
    return (
      this.args.editorState.shouldShowValidations &&
      // Chat message validations live under the 'blocks' key. If a step uses a chat message editor in its template,
      // they must import the chat message blocks validations and add them to their model validations.
      (!this.args.step.validations.attrs.blocks.isValid ||
        this.args.step.validations.attrs.supportedContexts.isInvalid)
    );
  }

  get shouldShowWarning() {
    return this.args.editorState.shouldShowValidations && this.args.hasWarning;
  }

  get isPlaceholderChatMessage() {
    return (
      // in edit mode, show the placeholder whenever the first block is an empty paragraph
      (!this.args.readOnly && this._startsWithEmptyParagraph()) ||
      // in read only mode, only show the placeholder when a step has one single empty text block
      (this.args.readOnly &&
        this.args.step.serializedBlocks.length === 1 &&
        this._startsWithEmptyParagraph())
    );
  }

  get placeholderBlock() {
    let placeholderText = this.args.placeholder
      ? this.args.placeholder
      : this.intl.t('operator.workflows.visual-builder.chat-message-placeholder');

    return { text: placeholderText, type: 'paragraph' };
  }

  get canShowVideoInserter() {
    return this.args.step.group.isStart && this.args.stepIndex === 0;
  }

  get canShowAudioInserter() {
    return this.args.shouldShowAudioInserter;
  }

  get supportedTools() {
    return (
      this.args.supportedTools || [
        { name: 'text-formatter' },
        { name: 'template-inserter' },
        { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
        { name: 'fallback-editor' },
        { name: 'image-editor', options: { supportAltAttributeEditing: false } },
        { name: 'video-editor' },
      ]
    );
  }

  @action
  startEditing() {
    this.args.editorState.activeComposerStep = this.args.step;
  }

  _startsWithEmptyParagraph() {
    return (
      this.args.step.serializedBlocks.length &&
      this.args.step.serializedBlocks.firstObject.type === 'paragraph' &&
      !this.args.step.serializedBlocks.firstObject.text
    );
  }
}
