/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { DEFAULT_ACTION_COLUMNS } from 'embercom/models/workflow-connector/constants/available-action-columns';

interface Signature {
  Args: Args;
}

interface Args {
  workflowActions: Array<any>;
  selectedColumns?: Array<any>;
}

export default class List extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: IntlService;

  get selectedColumns() {
    let selectedColumns;

    if (this.args.selectedColumns) {
      selectedColumns = this.args.selectedColumns;
    } else {
      selectedColumns = DEFAULT_ACTION_COLUMNS;
    }

    return selectedColumns?.map((column: any) => {
      column.label = this.intl.t(`workflow-connector.list.columns.${column.valuePath}`);

      if (column.hasTooltip) {
        column.tooltip = this.intl.t(`workflow-connector.list.columns.${column.valuePath}-tooltip`);
      }

      return column;
    });
  }

  get customActionsRoute() {
    return 'apps.app.settings.app-settings.custom-actions.custom-action';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::List': typeof List;
    'workflow-connector/list': typeof List;
  }
}
