/* import __COLOCATED_TEMPLATE__ from './facetile-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly, gte } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import {
  rejectBy,
  shuffle,
  take,
  ternaryToProperty,
} from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  usersWithAvatars: rejectBy('users', 'avatarData.url', null),
  firstTwelveOfUsersWithAvatars: take('usersWithAvatars', 12),
  firstTwelveOfAllUsers: take('users', 12),
  shuffledUsersWithAvatars: shuffle('firstTwelveOfUsersWithAvatars'),
  shouldSelectFromUsersWithAvatars: gte('usersWithAvatars.length', 12),
  usersToDisplay: ternaryToProperty(
    'shouldSelectFromUsersWithAvatars',
    'shuffledUsersWithAvatars',
    'firstTwelveOfAllUsers',
  ),
});
