/* import __COLOCATED_TEMPLATE__ from './element-selector-header.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';

export type ElementSelectorHeaderArgs = {
  isDisabled: boolean;
  config: any;
  section: any;
};

interface Signature {
  Element: HTMLDivElement;
  Args: ElementSelectorHeaderArgs;
}

const ElementSelectorHeader = templateOnlyComponent<Signature>();
export default ElementSelectorHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::TriggerInfoNode::ElementSelectorHeader': typeof ElementSelectorHeader;
  }
}
