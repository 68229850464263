/* import __COLOCATED_TEMPLATE__ from './composer.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import ComposerConfig from 'embercom/objects/workflows/graph-editor/chat-message/composer-config';

export default class NoteComposer extends Component {
  blocksDoc;
  @service intl;
  @service appService;
  @service attributeService;
  @service store;
  @service intercomEventService;
  @service contentEditorService;

  @tracked composerApi;
  @tracked openInserters;

  constructor() {
    super(...arguments);
    this.blocksDoc = new BlocksDocument(this.args.step.blocks);
    this.openInserters = [];
  }

  get app() {
    return this.appService.app;
  }

  get composerConfig() {
    let config = new ComposerConfig({
      app: this.app,
      eventService: this.intercomEventService,
      resolver: this.args.attributeInfoResolver,
      placeholder: this.placeholder,
      allowMentions: true,
    });

    config.upload.onUploadStart = this.contentEditorService.uploadFileStarted;
    config.upload.onUploadFinish = this.contentEditorService.uploadFileFinished;

    return config;
  }

  get placeholder() {
    return this.args.placeholder
      ? this.args.placeholder
      : this.intl.t('operator.workflows.visual-builder.note-placeholder');
  }

  get controlsAPI() {
    return {
      app: this.app,
      actions: {
        registerOpenInserter: (inserterName) => {
          this.openInserters.pushObject(inserterName);
        },
        clearOpenInserter: (inserterName) => {
          if (!this.isDestroying) {
            this.openInserters.removeObject(inserterName);
          }
        },
        paste: (text) => {
          this.composerApi.composer.commands.insertText(text);
        },
        insertBlock: (type, block) => {
          block['type'] = type;
          this.composerApi.composer.commands.insertBlock(block);
        },
        uploadImage: (files) => {
          files.reverse().forEach((file) => {
            this.composerApi.composer.commands.insertImageFile(file);
            this.composerApi.composer.commands.collapseSelection();
          });
        },
        uploadAttachment: (files) => {
          files.forEach((file) => {
            this.composerApi.composer.commands.addAttachment(file);
          });
        },
      },
    };
  }

  @action
  updateBlocks(blocksDoc) {
    let store = this.store;
    let blockFragments = blocksDoc.blocks.map((block) => createFragmentFromBlock(store, block));
    this.args.step.blocks = blockFragments;
    // this is a known workaround to an issue with creating new fragments on a tracked model in a different component
    // without this `get serializedBlocks()` doesn't recompute and the chat message appears not to update
    this.args.step.notifyPropertyChange('blocks');
  }
}
