/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import type Voicemail from 'embercom/models/operator/visual-builder/step/voicemail';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import templateOnlyComponent from '@ember/component/template-only';

interface Arguments {
  step: Voicemail;
  isReadOnly: boolean;
  editorState: EditorState;
}

const VoicemailEditor = templateOnlyComponent<Arguments>();
export default VoicemailEditor;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::Voicemail::Editor': typeof VoicemailEditor;
    'workflows/graph-editor/node-items/steps/voicemail/editor': typeof VoicemailEditor;
  }
}
