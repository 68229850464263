/* import __COLOCATED_TEMPLATE__ from './preview-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

export default class PreviewWrapper extends Component {
  @service store;

  @tracked customBot;

  get customBotLoaded() {
    return this.customBot && !this.fetchCustomBot.isRunning;
  }

  get path() {
    return this.isLegacyPreview
      ? this.customBot.paths.firstObject
      : this.customBot.visualBuilderObject.groups.firstObject;
  }

  get isLegacyPreview() {
    return this.customBot.paths.length > 0;
  }

  @task({ restartable: true })
  *fetchCustomBot(id) {
    let bot = yield this.store.findRecord('operator/custom-bot', id);
    this.customBot = bot;
  }
}
