/* import __COLOCATED_TEMPLATE__ from './conversation-ratings.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import templateOnlyComponent from '@ember/component/template-only';
import { type StepArguments } from '.';
import type ConversationRatings from 'embercom/models/operator/visual-builder/step/conversation-ratings';

interface Signature {
  Args: StepArguments<ConversationRatings>;
}

const ConversationRatingsNodeItem = templateOnlyComponent<Signature>();
export default ConversationRatingsNodeItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::ConversationRatings': typeof ConversationRatingsNodeItem;
    'workflows/graph-editor/node-items/steps/conversation-ratings': typeof ConversationRatingsNodeItem;
  }
}
