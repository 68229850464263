/* import __COLOCATED_TEMPLATE__ from './trial-days-left.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import Component from '@glimmer/component';

interface Args {
  trialPlanName: string;
  daysLeftOfTrial: number;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class TrialDaysLeft extends Component<Args> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    TrialDaysLeft: typeof TrialDaysLeft;
  }
}
