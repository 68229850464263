/* import __COLOCATED_TEMPLATE__ from './set-ticket-custom-state.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';
import type { default as SetTicketStateStepType } from 'embercom/models/operator/visual-builder/step/set-ticket-state';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type TicketStateService from 'embercom/services/ticket-state-service';

export interface SetTicketCustomStateArgs {
  step: SetTicketStateStepType;
  editorState: EditorState;
  onChange: () => void;
  onDelete: () => void;
  readOnly: boolean;
}

export default class SetTicketCustomState extends Component<SetTicketCustomStateArgs> {
  @service declare intl: IntlService;
  @service declare ticketStateService: TicketStateService;

  get humanReadableTicketState() {
    let ticketCustomStateId = this.args.step.action.actionData.ticket_custom_state_id;

    if (!ticketCustomStateId) {
      return this.intl.t('operator.workflows.visual-builder.set-ticket-state-invalid-message');
    }

    let currentState = this.ticketStateService.getTicketCustomStateById(ticketCustomStateId);
    return currentState.adminLabel;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::SetTicketCustomState': typeof SetTicketCustomState;
  }
}
